import economicApiService from "../../services/economicApiService";
import { CHANGE_VIABILITY_STATE, SHOW_CARDS } from '../constants/constants';

export const setViabilityState = (state) => {
    console.log('state',state)
  return async dispatch => {
    dispatch({type:CHANGE_VIABILITY_STATE,payload:state})
  }
}

export const showCards = (value) => {
  console.log('state','doneee')
return async dispatch => {
  dispatch({type:SHOW_CARDS,payload:value})
}
}

export const getEconomicViability = (conn_initial, conn_increment, gas_price, gas_increment,
                                     heat_price, heat_increment, co2_price, co2_increment, segment_ids=[], simulation_id=null)=> {
  return async (dispatch, getState)=> {
    const { map } = getState()
    const selectedCluster = map.selectedCluster || map.selectedClusterInGeoJSON
    if (selectedCluster) {
      return await economicApiService.getEconomicViability(selectedCluster, map.simulationCase, conn_initial,
          conn_increment, gas_price, gas_increment, heat_price, heat_increment, co2_price, co2_increment, segment_ids, simulation_id
      )
    }
    else {
      return null
    }
  }
}


