export const BASE_URL = "https://backend.ww.production.vvsgkube.agifly.cloud"
export const GEOSERVER_URL = "https://geo.production.ikrb.agifly.cloud"
export const GEOSERVER_STORE = "AgiflyProduction"        // WarmteWerktStaging for staging env and WarmteWerkt for development

/**
 * Map
 */
export const searchAreas = `${BASE_URL}/api/v1/map/search-area`
export const municipalityMapDetails = `${BASE_URL}/api/v1/map/municipality-details`
export const allFlandersMapDetails = `${BASE_URL}/api/v1/map/complete-map`
export const allFlandersMapDetailsPrivate = `${BASE_URL}/api/v1/map/complete-map/private`
export const houseLevelMapDetails = `${BASE_URL}/api/v1/map/house-level`
export const mapDetailsOfRequestedArea = `${GEOSERVER_URL}/ows?service=WFS&version=1.0.0&request=GetFeature`
export const mapDetailsOfClickedArea = `${GEOSERVER_URL}/wms?request=GetFeatureInfo&service=WMS&version=1.1.1&srs=urn:x-ogc:def:crs:EPSG:4326&info_format=application/json`

/**
 * Simulation
 */
export const addNewSimulation = `${BASE_URL}/api/v1/add-simulation`
export const updateSimulation = `${BASE_URL}/api/v1//update-simulation`
export const deleteSimulation = `${BASE_URL}/api/v1/delete-simulation`
export const deleteHeatDemand = `${BASE_URL}/api/v1/delete-heat-demand`
export const getSimulations = `${BASE_URL}/api/v1/get-user-simulations`
export const getSimulationPageCardInfo = `${BASE_URL}/api/v1/get-simulation-page-info`
export const getSimulationDetails = `${BASE_URL}/api/v1/get-simulation-details`
export const getSimulationCaseAgainstParams = `${BASE_URL}/api/v1/get-simulation-case`
export const deletedAllSimulationsForCalculation = `${BASE_URL}/api/v1/delete-simulations-for-calculation`



/**
 * User Auth
 */
export const userRegister = `/api/v1/user/auth/register`;
export const login = `/api/v1/user/auth/login`;
export const refresh = `/api/v1/user/auth/token/refresh`;
export const forgotPass = `/api/v1/user/email/forget-password`;
export const changePass = `/api/v1/user/auth/reset-password`;
export const logout = `/api/v1/user/auth/logout`;
export const checkEmail =`api/v1/user/email/exist`;


/**
 * Zoning
 */
export const zoningMunicipalities = `/api/v1/zoning-plans/get_all_municipalities`;
export const uploadZoningPlan = `/api/v1/zoning-plans/add_zoning_plan`;
export const municipalitiesWithZoningFiles = `/api/v1/zoning-plans/municipalities_with_zoning_files`;
export const municipalityZoningInfo = `/api/v1/zoning-plans/municipality`;


/**
 * Economic Viability
 */
export const economicViabilityCurrent = `${BASE_URL}/api/v1/economic-viability/current`
