import {
    COST_MAPPING,
    COST_REVERSE_MAPPING, DEFAULT_SELECTED_SIMULATION,
    DEFAULT_SIMULATION_STATE, RENOVATION_LEVEL_TO_YEAR,
    RENOVATION_LEVEL_TO_YEAR_REVERSE,
    SIMULATION_STATUS,
    HEAT_STATES
} from "../../constants/map";
import {
    SELECTED_SIMULATION_DETAILS, SIMULATION_CASE,
    SIMULATION_HEAT_DEMAND,
    SIMULATION_PARAMS,
    SIMULATION_STATUS_TOGGLE
} from "../constants/constants";
import {prepareHeatDemandsForStore} from "../../helper/mapHelpers";
import simulationService from "../../services/simulationService";

export const setSimulationParams = (value) => {
    return {
        type: SIMULATION_PARAMS,
        payload: {
            ...value
        }
    }
}
export const setSimulationStatus = (simStatus) => {
    return {
        type: SIMULATION_STATUS_TOGGLE,
        payload: simStatus
    }
}
export const setSimulationHeatDemand = (value) => {
    console.log('demand to be added ', value)
    return {
        type: SIMULATION_HEAT_DEMAND,
        payload: value
    }
}
export const setSelectedSimulationDetails = (value) => {
    return {
        type: SELECTED_SIMULATION_DETAILS,
        payload: value
    }
}

export const updateSimDetailsInStore = (sim_id, simDetailsRes, refreshGeoJSON=true) => {
    return async (dispatch, getState) => {
        try {
            const { map } = getState()
            if (!simDetailsRes.errors || !simDetailsRes.errors.length) {
                if (refreshGeoJSON) {
                    dispatch(setSelectedSimulationDetails({
                            simulation_id: sim_id,
                            statistical_sectors: simDetailsRes.data.simulated_statistical_sectors,
                            municipalityNames: simDetailsRes.data.simulated_municipality_names,
                            simulationData: simDetailsRes.data.simulated_municipality_data
                        }))
                }
                // issue now: all demands sent when updating
                else {
                    dispatch(setSelectedSimulationDetails({
                        simulation_id: sim_id,
                        statistical_sectors: map.selectedSimulationDetails.statistical_sectors,
                        municipalityNames: map.selectedSimulationDetails.municipalityNames,
                        simulationData: map.selectedSimulationDetails.simulationData
                    }))
                }
                const newSimParams = {
                    map_view: simDetailsRes.data.simulation_details.map_view,
                    price: COST_REVERSE_MAPPING[simDetailsRes.data.simulation_details.price],
                    connection_cost: COST_REVERSE_MAPPING[simDetailsRes.data.simulation_details.connection_cost],
                    renovation_level: {
                        "res": RENOVATION_LEVEL_TO_YEAR_REVERSE[simDetailsRes.data.simulation_details.renovation_level_res],
                        "nres": RENOVATION_LEVEL_TO_YEAR_REVERSE[simDetailsRes.data.simulation_details.renovation_level_nres]
                    }
                }
                if (simDetailsRes.data.simulation_details.sim_state === "saved")
                    newSimParams["simulation_name"] = simDetailsRes.data.simulation_details.name

                dispatch(setSimulationParams({...newSimParams}))
                dispatch(setSimulationHeatDemand([
                        ...prepareHeatDemandsForStore(simDetailsRes.data.heat_demands_details)
                    ]))
                if (simDetailsRes.data.simulation_details.sim_state === "saved")
                    dispatch(setSimulationStatus(SIMULATION_STATUS.SAVED))
                else
                    dispatch(setSimulationStatus(SIMULATION_STATUS.CALCULATED))
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const removeHeatDemand = (demandIndexInStore)=> {
    return async (dispatch, getState) => {
        const { map } = getState()
        const simHeatDemands = map.simulationHeatDemands
        simHeatDemands.splice(demandIndexInStore, 1)
        dispatch(setSimulationStatus(SIMULATION_STATUS.UPDATED))
        dispatch(setSimulationHeatDemand([...simHeatDemands]))
    }
}

export const updateSimulationCase = (simulationParams)=> {
    return async (dispatch) => {
        const simulationCaseRes = await simulationService.getSimulationCaseAgainstParams({
            "ren_res": RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.res],
            "ren_nres": RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.nres],
            "connection_cost": COST_MAPPING[simulationParams.connection_cost],
            "piping_cost": COST_MAPPING[simulationParams.price]
        })
        if (simulationCaseRes.data && (!simulationCaseRes.errors || !simulationCaseRes.errors.length)) {
            dispatch({
                type: SIMULATION_CASE,
                payload: simulationCaseRes.data.id
            })
            return true
        }
        return false
    }
}

export const cleanUpSimulation = ()=> {
    return async (dispatch, getState) => {
        const { map } = getState()
        if (map.simulationParams.simulation_state !== "saved")
            await simulationService.deletedAllSimsForCalculation()
        dispatch(setSimulationStatus(SIMULATION_STATUS.IDLE))
        dispatch(setSimulationParams({...DEFAULT_SIMULATION_STATE}))
        dispatch(setSimulationHeatDemand([]))
        dispatch(setSelectedSimulationDetails({...DEFAULT_SELECTED_SIMULATION}))
    }
}