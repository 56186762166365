import {getCookieItem} from "../helper/requestHelpers";
import axios from "axios";


export class ApiService {
        async apiWrapper(errorMessage, api, apiType="get", includeAccessToken=false, requestData= {}, geoserverReq= false) {
        const headers = {}
        if (Object.keys(requestData).length)
            headers['Content-Type'] = 'application/json'
        try {
            if (includeAccessToken)
                headers["Authorization"] = "Bearer " + getCookieItem("access_token")
            if (!geoserverReq) {
                if (apiType === "get") {
                    const {data} = await axios.get(api, {headers: headers})
                    return {
                        data: data.result.data || {},
                        errors: data.errors
                    }
                }
                if (apiType === "post") {
                    const {data} = await axios.post(api, {...requestData}, {headers: headers})
                    return {
                        data: data.result.data || {},
                        errors: data.errors
                    }
                }
                if (apiType === "delete") {
                    const {data} = await axios.delete(api, {headers: headers, data: {...requestData}})
                    return {
                        data: data.result.data || {},
                        errors: data.errors
                    }
                }
            }
            else {
                return await axios.get(api)
            }
        }
        catch (err) {
            return {data: {}, errors: [err.response ? err.response.data.errors : err.toString() || "error", errorMessage]}
        }
    }
}

export default new ApiService()