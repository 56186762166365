import Tooltip from "@material-ui/core/Tooltip";


export const FillButton =({
    name,
    onClickHandler=()=> {},
    loading=false,
    tooltipTitle="",
    tooltipPlace="right-start",
    classes="sidepanel-ful-btn ml-2",
    loadingMessage="loading",
    extra_classes="",
    disabled=false
                          })=>
{
    const buttonHtml = (
        <button
            className={classes + " " + extra_classes}
            disabled={disabled}
            onClick={onClickHandler}
        >
            {
                loading ?
                    <>
                        {loadingMessage}
                        <i className="ml-2 fa fa-spinner fa-spin"></i>
                    </>
                    :
                    name
            }
        </button>
    )
    return (
        <>
            {tooltipTitle.length > 0 ?
                <Tooltip title={tooltipTitle} placement={tooltipPlace}>
                    {buttonHtml}
                </Tooltip>
                :
                (buttonHtml)
            }
        </>
    )
}

export const EmptyButton =({name,handle})=>
{
    if(typeof handle === "function") 
    {
        return <button className='sidepanel-button' onClick={()=>handle()}>{name}</button>
    }
    return <button className='sidepanel-button'>{name}</button>
}