import axios from "axios";
import {
    addNewSimulation, deletedAllSimulationsForCalculation,
    deleteHeatDemand,
    deleteSimulation,
    getSimulationCaseAgainstParams,
    getSimulationDetails,
    getSimulationPageCardInfo,
    getSimulations,
    updateSimulation
} from "../redux/constants/ApiEndPoints";
import {getCookieItem} from "../helper/requestHelpers";
import {ApiService} from "./ApiService";


class SimulationAPIService extends ApiService{
    async getUserSimulations() {
        return await this.apiWrapper("Could not get simulations", getSimulations, "get",
            true)
    }

    async deleteUserSimulation(sim_id, calculate_mode=false) {
        return await this.apiWrapper("Could not delete simulation", deleteSimulation, "delete",
            true, { id: sim_id, calculate_mode: calculate_mode})
    }

    async addNewSimulation(newSimulationData) {
        return await this.apiWrapper("Simulation couldn't be added/calculated successfully", addNewSimulation,
            "post", true, newSimulationData)
    }

    async updateSimulation(simulationInfo) {
        return await this.apiWrapper("Simulation couldn't be recalculated/updated successfully", updateSimulation,
            "post", true, simulationInfo)
    }

    async getSimulationPageCardInfo(municipalityId, municipalityName="", simulationCase) {
        return await this.apiWrapper("Municipality Info couldn't be fetched successfully", getSimulationPageCardInfo,
            "post", true, {municipalityId: municipalityId, municipalityName: municipalityName, selectedSimulationCase: simulationCase})
    }

    async getSimulationDetails(sim_id, run_decision_algo=true, saved=false) {
        return await this.apiWrapper("Simulation Info couldn't be fetched successfully", getSimulationDetails,
            "post", true, {id: sim_id, run_decision_algo: run_decision_algo, saved: saved})
    }

    async deleteHeatDemand(demandId, saved=true) {
        return await this.apiWrapper("Heat Demand couldn't be deleted successfully from db", deleteHeatDemand,
            "delete", true, {id: demandId, saved: saved})
    }

    async getSimulationCaseAgainstParams(simParams) {
        return await this.apiWrapper("Simulation Case couldn't be fetched successfully from db", getSimulationCaseAgainstParams,
            "post", true, {...simParams})
    }

    async deletedAllSimsForCalculation() {
        return await this.apiWrapper("All simulations deleted succesfully from db", deletedAllSimulationsForCalculation,
            "delete", true)
    }
}

export default new SimulationAPIService()