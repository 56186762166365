import React,{useState,useEffect} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {Divider} from '@material-ui/core';
import {FillButton, EmptyButton} from '../../helper/buttons';
import questionMark from '../../assets/question.svg';
import greyQuestion from '../../assets/greyQuestion.svg';
import CheckboxesList from './checkboxesList';
import {MultiColorBar} from "../sharedComponents/multiColorBar";
import {
    RENOVATION_DEGREE,
    MAP_LAYERS
} from "../../constants/map";
import parse from 'html-react-parser';
import {setSelectedMapLayer} from '../../redux/actions/map';
import {setSimulationParams} from "../../redux/actions/simulation";

function BottomSection(props) {

    if (props.bottomSectionContent){
        var {
            titleRenovatiegraad,
            headingRenovatiegraadResidentieel,
            headingRenovatiegraadNietResidentieel,
            helpTextRenovatiegraad,
            buttonDownloadText,
            buttonBottomBerekenenText,
            buttonBottomOpslagenText,
            toggleWFSToWMSText,
            toggleWMSToWFSText,
            helpTextPlaatsingkostenWarmtenet,
            headingMLeidingPlaatsingkostenWarmtenet,
            titlePlaatsingkostenWarmtenet,
            headingAansluitingPlaatsingkostenWarmtenet
        } = props.bottomSectionContent;
    }

    const [showFirst, setShowFirst] = useState(false)
    const [showSecond, setShowSecond] = useState(false)
    const [residential, setResidential] = useState(true)
    const [firstInfo, setFirstInfo] = useState(false)
    const [secondInfo, setSecondInfo] = useState(false)
    const [layer, setLayer] = useState(props.map.selectedMapLayer)
    let renovation = layer == MAP_LAYERS.RESIDENTIAL_RENOVATION || layer == MAP_LAYERS.NON_RESIDENTIAL_RENOVATION? true : false;
    const [label, setLabel] = useState('F')
    const [label2, setLabel2] = useState('F')
    const [aansluitingActive, setAansluitingActive] = useState(0)
    const [leidingActive, setLeidingActive] = useState(0)
    const [info, setInfo] = useState([
        {paragraph:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'},
        {paragraph:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'},
        {paragraph:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'}
    ]);

    const [buttonList, setButtonList] = useState(['Laag','Medium','Hoog'])
    const dispatch = useDispatch()
    const simulationParams = useSelector(state=> state.map.simulationParams)

    
    useEffect(() => {
        setLayer(props.map.selectedMapLayer)
    }, [props.map.selectedMapLayer,props.map.searchLevel,props.map.selectedArea, props.mapCenter, props.mapZoom])

    useEffect(() => {
        console.log('labels!',label,label2)
    }, [label,label2,renovation,aansluitingActive,leidingActive])

    const handleChilds =(e,child)=> {
        e.stopPropagation();
        setResidential(child)
    }

    const handleInfo =(e,info)=> {
        e.stopPropagation()
        if(info == 'first') {
            setShowFirst(true)
            setFirstInfo(!firstInfo)
        }
        if(info == 'second') {
            setShowSecond(true)
            setSecondInfo(!secondInfo)
        }
        console.log('info')
    }

    const handleAansluitingChange =(e,title)=> {
        e.stopPropagation()
        setAansluitingActive(title)
        dispatch(setSimulationParams({connection_cost: title}))
    }
    const handleLeiderChange =(e,title)=> {
        e.stopPropagation()
        setLeidingActive(title)
        dispatch(setSimulationParams({price: title}))
    }

    const handleRenovationLevelChange = (label)=> {
        const updatedRenLevel = {
            renovation_level: {
                res: residential ? label : simulationParams.renovation_level.res,
                nres: !residential ? label : simulationParams.renovation_level.nres
            }
        }
        dispatch(setSimulationParams(updatedRenLevel))
    }

    return (
            
            <div className='sidepanel-panel col-12 shadow pb-3 p-0'>
                <div className={`sidepanel-section pt-1 ${showFirst?'pb-4':''}`}>
                    <div 
                    className='pb-2' 
                    onClick={()=>setShowFirst(!showFirst)}
                    >
                    <div className='d-inline'>
                        <span className={`fs-12 pl-3 col-8 d-inline ${!showFirst?'light-grey':'bold'}`}>{titleRenovatiegraad}</span>
                        <div className='float-right pr-3'>
                            <img
                                className='ml-2 pointer'
                                src={showFirst?questionMark:greyQuestion}
                                onClick={(e)=>handleInfo(e,'first')}
                                alt="questionMark"
                                width="20px"
                                height="20px"
                            />
                            <div className='float-right pt-1'>
                                <i 
                                className={`fa fa-chevron-down ${showFirst?'sidepanel-chevron-rotate':'sidepanel-chevron'} ml-2 mr-1 pointer`} aria-hidden="true"
                                onClick={()=>setShowFirst(!showFirst)}
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
                {showFirst?
                <div className='container'>
                    <div className='row text-center sidepanel-inside p-0 m-0 sidepanel-active-border'>
                        <div 
                        className={`col-6 p-2 pointer ${residential?'':'bg-white'}`}
                        onClick={(e)=>handleChilds(e,true)}>
                            <span className='fs-13 black'>{headingRenovatiegraadResidentieel}</span>
                        </div>
                        <div 
                        className={`col-6 p-2 pointer ${!residential?'':'bg-white'}`}
                        onClick={(e)=>handleChilds(e,false)}>
                            <span className='fs-13 black'>{headingRenovatiegraadNietResidentieel}</span>
                        </div>
                    </div>
                <div className='sidepanel-inside'>
                        <div className='col-12 pt-2 pb-2'>
                        <MultiColorBar
                            name='renovation'
                            colors={[
                                {hex: RENOVATION_DEGREE["1"], percentage: 20}, 
                                {hex: RENOVATION_DEGREE["2"], percentage: 20},
                                {hex: RENOVATION_DEGREE["3"], percentage: 20, label:'F', active:residential?simulationParams.renovation_level.res == 'F'? true:false:simulationParams.renovation_level.nres == 'F'? true:false},
                                {hex: RENOVATION_DEGREE["4"], percentage: 20, label:'D', active:residential?simulationParams.renovation_level.res == 'D'? true:false:simulationParams.renovation_level.nres == 'D'? true:false},
                                {hex: RENOVATION_DEGREE["5"], percentage: 20, label:'B', active:residential?simulationParams.renovation_level.res == 'B'? true:false:simulationParams.renovation_level.nres == 'B'? true:false},
                                {hex: RENOVATION_DEGREE["6"], percentage: 20, label:'A', active:residential?simulationParams.renovation_level.res == 'A'? true:false:simulationParams.renovation_level.nres == 'A'? true:false}
                            ]}
                            labels={[]}
                        />
                        
                    </div>
                    <div>
                    <CheckboxesList
                     setLabel={(label)=> handleRenovationLevelChange(label)}
                     label={residential?simulationParams.renovation_level.res:simulationParams.renovation_level.nres}
                    />
                    </div>
                </div>
                {showFirst && firstInfo?
                    <>
                        <div className='container pt-3'>
                            <Divider className=''/>
                        </div>
                        <div className='col-12 pt-3'>
                           <p className='fs-11 light-grey p-0 m-0'>{parse(`${helpTextRenovatiegraad}`)}</p>
                        </div>
                      {/* <Divider className=''/> */}
                    </>
                  :''
                  }
                </div>
                :''}
                </div>
                {/* </div> */}

                <Divider className={''} />
                <div 
                className='sidepanel-section'>
                <div 
                className='pt-1 pb-2'
                onClick={()=>setShowSecond(!showSecond)}
                >
                <div className='d-inline'>
                    <span className={`fs-12 pl-3 col-8 d-inline ${!showSecond?'light-grey':'bold'}`}>{titlePlaatsingkostenWarmtenet}</span>
                    <div className='float-right pr-3'>
                        <img
                            className='ml-2 pointer'
                            src={showSecond?questionMark:greyQuestion}
                            onClick={(e)=>handleInfo(e,'second')}
                            alt="questionMark"
                            width="20px"
                            height="20px"
                        />
                        <div className='float-right pt-1'>
                            <i 
                            className={`fa fa-chevron-down ${showSecond?'sidepanel-chevron-rotate':'sidepanel-chevron'} ml-2 mr-1 pointer`} aria-hidden="true"
                            onClick={()=>setShowSecond(!showSecond)}
                            >
                        </i>
                        </div>
                    </div>
                </div> 
                </div>
                {showSecond?
                <div className='container'>
                    <span className='clr-text fs-12'>€ / {headingAansluitingPlaatsingkostenWarmtenet}</span>
                    <div className='row pb-3'>
                        <div className='col-12'>
                            <div className='d-inline'>
                                {buttonList.map((btn,index)=>
                                (
                                    <button 
                                     className={`sidepanel${index == 0?'-btn-left':index == 1?'-btn-center':'-btn-right'} ${simulationParams.connection_cost === btn?'bg-Dgray':''}`}
                                     onClick={(e)=>handleAansluitingChange(e,btn)}
                                     >
                                     {btn}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <span className='clr-text fs-12'>€ / {headingMLeidingPlaatsingkostenWarmtenet}</span>
                    <div className='row pb-3'>
                        <div className='col-12'>
                            <div className='d-inline'>
                                {buttonList.map((btn,index)=>
                                (
                                    <button 
                                     className={`sidepanel${index == 0?'-btn-left':index == 1?'-btn-center':'-btn-right'} ${simulationParams.price === btn?'bg-Dgray':''} `}
                                     onClick={(e)=>handleLeiderChange(e,btn)}
                                     >
                                     {btn}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                :''}
                {showSecond && secondInfo?
                    <>
                        <div className='container pb-3'>
                            <Divider className=''/>
                        </div>
                        <div className='col-12'>
                            <p className='fs-11 light-grey'>{parse(`${helpTextPlaatsingkostenWarmtenet}`)}</p>
                        </div>
                        <Divider className=''/>
                    </>
                    :''
                }

                </div>
                <Divider className='mb-1'/>
                {simulationParams.simulation_name &&
                    <div>
                        <span className='pl-3 clr-text fs-12 text-small'>
                            Active Simulation: {simulationParams.simulation_name}
                        </span>
                        <Divider className='mb-1'/>
                    </div>
                }
                <div className='text-center pt-3 pb-1'>
                    {/* <EmptyButton name={buttonDownloadText} /> */}
                    {[MAP_LAYERS.COLLECTIVE_HEAT_MAPPING, MAP_LAYERS.HEAT_DEMAND].includes(props.map.selectedMapLayer) &&
                    <FillButton
                        // loading={props.submitCalculateState}
                        onClickHandler={props.handleDownload}
                        name={buttonDownloadText}/>
                    }
                    <FillButton
                        loading={props.submitCalculateState}
                        onClickHandler={props.handleCalculateSimulation}
                        name={buttonBottomBerekenenText} />
                    <FillButton
                        loading={props.submitSimulationState}
                        onClickHandler={props.handleSubmitSimulation}
                        name={buttonBottomOpslagenText} />
                    {/*<FillButton*/}
                    {/*    loading={props.submitSimulationState}*/}
                    {/*    onClickHandler={props.handleToggleMapService}*/}
                    {/*    name={props.wmsInAction ? toggleWMSToWFSText : toggleWFSToWMSText} />*/}
                </div>
            </div>
    )
}

const mapStateToProps = ({map,loader}) => ({map,loader})
export default connect(mapStateToProps, {setMapLayers: setSelectedMapLayer}) ( BottomSection );