import axios from '../axios/axios';
import { createBrowserHistory } from 'history';
import { userRegister, login, refresh, forgotPass, changePass, logout, checkEmail } from '../constants/ApiEndPoints';
import { USER_LOGIN, USER_VIEW, CHECK_EMAIL } from '../constants/constants';
const customHistory = createBrowserHistory();

export const userRegistration = (Email,Password) => {
  return async dispatch =>{
    const { data, status } = await axios.post(userRegister, {
      email: Email,
      password:Password
    });

    if(data.result.data.domainWhiteListed){
      dispatch(userLogin(Email, Password, false));
    }

    return {data, status};
  }
}

export const userLogin = (email, password,remember) => {

  return async dispatch =>{
    const { data, status } = await axios.post(`${login}?remember_me=${remember}`, {
      email, password
    });

    localStorage.setItem('userEmail',email)
    dispatch(setCookieToken(data,true))
    return {data, status};
  }
}

export const checEmail = (Email) => {
  return async dispatch =>
  {
  const { data } = await axios.post(checkEmail, 
  { 
  email: Email,
  });
  dispatch({ type:CHECK_EMAIL, payload:data.result.data.email_exists })
  return data;
}
}

  
export const refreshToken = (token) => {
  return async dispatch => {
    const { data } = await axios.put(refresh, 
    { 
      refresh_token: token
    });
    setCookieToken(data,false);
    return data;
}
}
export const forgotPassword = (Email) => {
  return async dispatch => {
    const { data } = await axios.post(forgotPass, 
    { 
    email: Email,
    });
    return data;
}
}

export const changePassword = (Password) => {
  return async dispatch => {
    const { data } = await axios.put(changePass, 
    { 
    email: localStorage.getItem('userEmail'),
    password:Password
    });
    return data;
}
}


export const userLogout = () => {
  let access_token = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  let refresh_token = document.cookie.replace(/(?:(?:^|.*;\s*)refresh_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");

  return async dispatch =>{
    await axios.post(logout, {
      access_token,
      refresh_token
    });

    localStorage.clear();
    document.cookie = '';  
    customHistory.push('/');
    window.location.reload(true); 
      
  }
}

 export const setCookieToken = (data,reload) => {
  let result = data?.result.data;
  let expires_at = result?.expires_at;
  let refresh_expires_at = result?.refresh_expires_at;
  let date = new Date(expires_at);
  let date2 = new Date(refresh_expires_at);
  let token = 'access_token';
  let refresh = 'refresh_token';

  let d = new Date();
  let d2 = new Date();
  d.setTime(d.getTime() + 60*60*1000 ); 
  d2.setTime(d2.getTime() + 120*60*1000 );

  localStorage.setItem('expireTime',d.getTime())
  let expires = 'expires=' + d.toUTCString();
  let refresh_expires = 'expires=' + d2.toUTCString();
  document.cookie = token + '=' + result.access_token + ';' + expires + ';path=/';
  document.cookie = refresh + '=' + result.refresh_token + ';' + refresh_expires + ';path=/';
  localStorage.setItem('refresh',true)
  if(reload)
  {
    window.location.href='/'
  } 

 }

  export const activeView = (view) => {
    return async dispatch =>
    {
      localStorage.setItem('userView',view)
      dispatch({ type:USER_VIEW, payload:view })
    }
  }
