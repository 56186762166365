import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { Grid, Divider } from '@material-ui/core';
import CopyText from 'react-copy-text'
import { MultiColorBar } from "../sharedComponents/multiColorBar";
import BottomSection from './bottomSection';
import InsideContainer from './insideContainer';
import questionMark from '../../assets/question.svg';
import greyQuestion from '../../assets/greyQuestion.svg';
import eye from '../../assets/eye.svg';
import hideEye from '../../assets/hideEye.svg';
import close from '../../assets/close.svg';
import { GradientBar } from "../sharedComponents/gradientBar";
import { SearchBar } from '../homePage/searchBar';
import {
 COLLECTIVE_HEATING_PROBABILITY,
 HEAT_DEMAND_COLORS,
 MAP_LAYERS,
 SEARCH_BAR_TYPES,
 HEAT_SUPPLY
} from "../../constants/map";
import {
firstRadioConstant,
secondRadioConstant,
fieldConstant,
field2Constant
} from '../../constants/sidepanel';
import {setSelectedMapLayer} from '../../redux/actions/map';
import { EmptyButton } from '../../helper/buttons';
import OtherMessages from '../../helper/otherMessages';
import {ROUTES} from "../../constants/routes";
import {URL_MESSAGE} from '../../constants/messages';
import {validateObject} from "./validationHelpers";
import {activeView} from '../../redux/actions/auth';
import EconomicViability from './economicViability/economicViability';

function SidePanel(props) {
    let checkAuth = document.cookie.indexOf('access_token') == -1 ? false : true;
    const history = useHistory();
    const [options, setOptions] = useState([])
    const [hidePanel, setHidePanel] = useState(false)
    const [firstInfo, setFirstInfo] = useState(false)
    const [secondInfo, setSecondInfo] = useState(false)
    const [thirdInfo, setThirdInfo] = useState(false)
    const [fourthInfo, setFourthInfo] = useState(false)
    const [copyQuery, setCopyQuery] = useState(window.location.href)
    const [queryValues, setQueryValues] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [firstRadioList, setFirstRadioList] = useState(firstRadioConstant)
    const [secondRadioList, setSecondRadioList] = useState(secondRadioConstant)
    const [fields, setFields] = useState(fieldConstant)
    const [fields2, setFields2] = useState(field2Constant)
    const [layer, setLayer] = useState(props.map.selectedMapLayer)
    const [downArrow, setDownArrow] = useState(false)
    const [demand, setDemand] = useState(layer == MAP_LAYERS.HEAT_DEMAND || layer == MAP_LAYERS.HEAT_DEMAND_2050? true : false)

    useEffect(() => {
        console.log('arrowDown')
    },[downArrow])

    useEffect(() => {
       let years = [];
       for(let i=2020; i< 2051; i++) years.push(i);
       setOptions(years)
       let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)refresh_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
       let layer = props.map.selectedMapLayer;
       let level = props.map.searchLevel;
       let area = props.map.selectedArea;
       let host = window.location.hostname;
       let searchParams = {
           layer: layer,
           level: level
       }
       if (area.municipalityName !== "")
           searchParams["municipality"] = area.municipalityName
       if (area.municipalityId !== "")
           searchParams["municipalityId"] = area.municipalityId
       if (area.streetName !== "")
           searchParams["street"] = area.streetName
       if (area.streetId !== "")
           searchParams["streetId"] = area.streetId
       if (area.houseNo !== "")
           searchParams["houseNo"] = area.houseNo
       if (area.addressId !== "")
           searchParams["addressId"] = area.addressId
       if (area.postCode !== "")
           searchParams["postCode"] = area.postCode
       if (props.mapCenter !== "")
           searchParams["mapCenter"] = "["+props.mapCenter.lat + "," + props.mapCenter.long + "]"
       if (props.mapZoom !== "")
           searchParams["mapZoom"] = props.mapZoom

       setLayer(props.map.selectedMapLayer)
       setDemand(layer == MAP_LAYERS.HEAT_DEMAND || layer == MAP_LAYERS.HEAT_DEMAND_2050? true : false)
       let paramsQuery = new URLSearchParams({...searchParams}).toString()
       history.push({
           pathname: '/map',
           search: paramsQuery
       })
       if(props.auth.active_view == 0) localStorage.setItem('queryMapInspirational',paramsQuery)
       if(props.auth.active_view == 1) localStorage.setItem('queryMapSimulation',paramsQuery)
       setQueryValues(`${host}${ROUTES.MAP_PAGE}${paramsQuery}`)

    }, [props.map.selectedMapLayer,props.map.searchLevel,props.map.selectedArea, props.mapCenter, props.mapZoom, props.auth.active_view])

    useEffect(() => {
        console.log('updated!')
    }, [fields])

    useEffect(() => {
        if(layer != demand) {
            let items = [...fields];
            items.map((item)=> item.error = false)
            setFields([...items])
            setShowErrorDialog(false)
        }
    }, [layer,demand])

    const handleFirstRadio =(event)=> {
      let index = event.target.id;
      let prevValue =  firstRadioList[index].value;
      firstRadioList.map(radioItem=> radioItem.value = false)
      firstRadioList[index].value = !prevValue;
      setFirstRadioList([...firstRadioList])
    }

    const handleSecondRadio =(event)=> {
      let index = event.target.id;
      secondRadioList.map(radioItem=> radioItem.value = false)
      let prevValue =  secondRadioList[index].value;
      secondRadioList[index].value = !prevValue;
      setSecondRadioList([...secondRadioList])
    }

    const handleFields =(e,index,name,fieldsToCheck=fields, setFieldsFunc=setFields)=> {
      let items = fieldsToCheck;
      let value = e.target.value;
      items[index].value=value;
      setFieldsFunc([...items])
    }

    if (props.sidePanelContent) {
        var {
            titleWaarschijnlijkheid,
            titleWaartevraagdichtheid,
            titleWarmteAanbod,
            titleWarmteBestemmingsplannen,
            helpTextWaarschijnlijkheid,
            helpTextWaartevraagdichtheid,
            helpTextWarmteAanbod,
            helpTextWarmteBestemmingsplannen,
            buttonLinkDelen,
            vermogenWarmtevraagdichtheidTitle,
            annualHeatDemandWarmtevraagdichtheidTitle,
            yearWarmtevraagdichtheidTitle,
            extraStreetLengthWarmtevraagdichtheidTitle,
            addWarmtevraagdichtheidBtn,
            addWarmtevraagdichtheidObliged,
            buttonWaartevraagdichtheidText,
            buttonWarmteAanbodText,
            annualHeatDemandWarmtevraagdichtheidUnit,
            vermogenWarmtevraagdichtheidUnit,
            WarmtebronnenAddNewBtn,
            extraStreetLengthWarmtevraagdichtheidUnit,
            incinerationWarmtebronnenRadioText,
            combineHeatPowerWarmtebronnenRadioText,
            geoThermalWarmtebronnenRadioText,
            aquaAndRioWarmtebronnenRadioText,
            bioMassWarmtebronnenRadioText,
            solarWarmtebronnenRadioText,
            nameLabelWarmtebronnenInputTitle,
            vermonWarmtebronnenInputTitle,
            energyWarmtebronnenInputTitle,
            productionCostWarmtebronnenInputTitle,
            temperatureLevelWarmtebronnenInputTitle,
            contactInfoWarmtebronnenInputTitle,
            remarkWarmtebronnenInputTitle,
            vermonWarmtebronnenInputUnit,
            energyWarmtebronnenInputUnit,
            productionCostWarmtebronnenInputUnit,
            temperatureLevelWarmtebronnenInputUnit,
            contactInfoWarmtebronnenInputUnit,
            remarksWarmtebronnenInputUnit,
            addWarmtebronnenObliged,
            ...bottomSectionContent
        } = props.sidePanelContent;
    }

    useEffect (()=> {
        const _fields = [...fields];
        const _secondRadioList = [...secondRadioList];
        const _fields2 = [...fields2];

        if (props.sidePanelContent){
            _fields[0].name = vermogenWarmtevraagdichtheidTitle;
            _fields[1].name = annualHeatDemandWarmtevraagdichtheidTitle;
            _fields[2].name = yearWarmtevraagdichtheidTitle;
            _fields[3].name = extraStreetLengthWarmtevraagdichtheidTitle;
            _fields[0].unit = vermogenWarmtevraagdichtheidUnit;
            _fields[1].unit = annualHeatDemandWarmtevraagdichtheidUnit;
            _fields[3].unit = extraStreetLengthWarmtevraagdichtheidUnit;
            
            _secondRadioList[0].name = incinerationWarmtebronnenRadioText;
            _secondRadioList[1].name = combineHeatPowerWarmtebronnenRadioText;
            _secondRadioList[2].name = geoThermalWarmtebronnenRadioText;
            _secondRadioList[3].name = aquaAndRioWarmtebronnenRadioText;
            _secondRadioList[4].name = bioMassWarmtebronnenRadioText;
            _secondRadioList[5].name = solarWarmtebronnenRadioText;

            _fields2[0].name = nameLabelWarmtebronnenInputTitle;
            _fields2[1].name = vermonWarmtebronnenInputTitle;
            _fields2[2].name = energyWarmtebronnenInputTitle;
            _fields2[3].name = productionCostWarmtebronnenInputTitle;
            _fields2[4].name = temperatureLevelWarmtebronnenInputTitle;
            _fields2[5].name = contactInfoWarmtebronnenInputTitle;
            _fields2[6].name = remarkWarmtebronnenInputTitle;

            _fields2[1].unit = vermonWarmtebronnenInputUnit;
            _fields2[2].unit = energyWarmtebronnenInputUnit;
            _fields2[3].unit = productionCostWarmtebronnenInputUnit;
            _fields2[4].unit = temperatureLevelWarmtebronnenInputUnit;
            _fields2[5].unit = contactInfoWarmtebronnenInputUnit;
            _fields2[6].unit = remarksWarmtebronnenInputUnit;
            
        }

        setFields([..._fields])
        setSecondRadioList([..._secondRadioList])
        
    }, [props.sidePanelContent])


    const handleButton=()=> {
        setCopyQuery(window.location.href)
        navigator.clipboard.writeText(window.location.href)
        setShowMessage(true)
        setTimeout(() => {
            setShowMessage(false)
        }, 2000);
    }

    const onCopied = (text) =>  {
        console.log(`${text}`)
    }

    const handleChild =(e,value)=> {
        e.stopPropagation();
        props.setMapLayers(value)
    }

    const handleAddHeatDemandSubmit = ()=> {
        fields.map((field)=> {
            if(field.required) {
                if(field.value && field.value !== "") field.error = false ;
                else field.error = true ;
            }
        })
        let selectedDemandType = firstRadioList.find(item=> item.value)
        let fieldsObject = {
            MW: !isNaN(parseFloat(fields[0].value)) ? parseFloat(fields[0].value) : fields[0].value,
            kWh: !isNaN(parseFloat(fields[1].value)) ? parseFloat(fields[1].value) : fields[1].value,
            Jaar: !isNaN(parseInt(fields[2].value)) ? parseInt(fields[2].value) : fields[2].value,
            L: !isNaN(parseFloat(fields[3].value)) ? parseFloat(fields[3].value) : fields[3].value.length ? fields[3].value: null,
            new_dev_type: selectedDemandType ? selectedDemandType.name : null
        }
        const validationErrors = validateObject(fieldsObject,
            {
                MW: {type: "number", required: true}, kWh: {type: "number", required: true}, Jaar: {type: "number", required: true}, L: {type: "number"},
                new_dev_type: {type: "string", required: true, title: "Radio Option"}
            })
        if (validationErrors.length) {
            setShowErrorDialog(true)
            setErrorMessage(validationErrors[0])
        }
        else {
            setShowErrorDialog(false)
            props.handleNewHeatDemandAddition(fieldsObject)
        }
    }

    const handleAddHeatSupplySubmit = ()=> {
        const localFields2 = fields2
        let error = false
        localFields2.map((field)=> {
            if(field.required) {
                if(field.value && field.value.length) field.error = false
                else {
                    field.error = true
                    error = true
                    setShowErrorDialog(true)
                    setErrorMessage("Gelieve alle verplichte velden in te vullen")
                }
            }
        })
        setFields2([...localFields2])
        if (!error) {
            setShowErrorDialog(false)
            let selectedSupplyType = secondRadioList.find(item=> item.value)
            props.handleNewHeatSupplyAddition({
                supply_type: selectedSupplyType.name,
                name: localFields2[0].value,
                power: localFields2[1].value,
                energy: localFields2[2].value,
                production_cost: localFields2[3].value,
                temperature: localFields2[4].value,
                contact_info: localFields2[5].value,
                comments: localFields2[6].value
            })
        }
    }

    const handleArrow = (value) => {
        setDownArrow(value)
    }

    return (
        <>
          {/* <Container> */}
                <Grid container item xs={8} md={6} lg={4} xl={4} className='sidepanel-container' justifyContent='flex-end'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SearchBar
                            setClick={props.setSearchBtnClick}
                            searchBarType={SEARCH_BAR_TYPES.WITH_SEARCH_ICON}
                            searchBarClickHandler={props.searchAreaUpdateHandler}
                            setActiveView={props.activeView}
                            handleArrow={handleArrow}
                        />
                    </Grid>

                <div className={
                    !hidePanel && downArrow?`sidepanel-close-icon sidepanel-with-down`
                    :hidePanel && downArrow?`sidepanel-hide-panel sidepanel-with-down`
                    :!hidePanel && !downArrow?`sidepanel-close-icon`
                    :`sidepanel-hide-panel`
                    }
                >
                        <img
                            className='ml-2 pointer'
                            src={close}
                            onClick={()=>setHidePanel(!hidePanel)}
                            alt="close"
                            width="50px"
                            height="50px"
                        />
                </div>
                {!hidePanel?
                <div className='sidepanel-panel col-12 shadow p-0'>
                    <div 
                    className='sidepanel-section pt-1'
                    onClick={()=>props.setMapLayers(MAP_LAYERS.COLLECTIVE_HEAT_MAPPING)}
                    >
                    <div className='pb-2'>
                        <Grid container direction="row">
                            <Grid item xs={12} sm={8}>
                                <div className={`fs-12 pl-3 ${props.smallScreen ? 'paragraph' : 'span'} ${layer != MAP_LAYERS.COLLECTIVE_HEAT_MAPPING?'light-grey':'bold'}`}>{titleWaarschijnlijkheid}</div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={props.smallScreen ? 'float-left pl-2' : 'float-right pr-3'}>
                                <img
                                    className='ml-2 pointer'
                                    src={layer == MAP_LAYERS.COLLECTIVE_HEAT_MAPPING?questionMark:greyQuestion}
                                    onClick={()=>setFirstInfo(!firstInfo)}
                                    alt="questionMark"
                                    width="20px"
                                    height="20px"
                                />
                                <img
                                    className={layer == MAP_LAYERS.COLLECTIVE_HEAT_MAPPING?'ml-2':'ml-2 pointer'}
                                    src={layer == MAP_LAYERS.COLLECTIVE_HEAT_MAPPING?eye:hideEye}
                                    onClick={()=>props.setMapLayers(MAP_LAYERS.COLLECTIVE_HEAT_MAPPING)}
                                    alt="eye"
                                    width="20px"
                                    height="20px"
                                />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {layer == MAP_LAYERS.COLLECTIVE_HEAT_MAPPING?
                        <div className='col-12 pt-2 pb-2'>
                            <MultiColorBar
                                colors={[
                                    {hex: COLLECTIVE_HEATING_PROBABILITY["1"], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY["2"], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY["3"], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY["4"], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY["5"], percentage: 20}
                                ]}
                                labels={[
                                    "Collectief", "Onbeslist", "Individueel"
                                ]}
                            />
                        </div>
                        : ''
                        
                    }
                    
                    {layer == MAP_LAYERS.COLLECTIVE_HEAT_MAPPING && firstInfo?
                    <>
                        <div className='container'>
                            <Divider className=''/>
                        </div>
                        <div className='col-12 pt-3'>
                            <div className='fs-11 light-grey'>{parse(`${helpTextWaarschijnlijkheid}`)}</div>
                        </div>
                        <Divider className=''/>
                        </>
                    : <Divider className=''/>
                    }
                    </div>
                    <div 
                    className='sidepanel-section pt-1'
                    onClick={()=>props.setMapLayers(layer === MAP_LAYERS.HEAT_DEMAND_2050 ? MAP_LAYERS.HEAT_DEMAND_2050 : MAP_LAYERS.HEAT_DEMAND)}
                    > 
                    <div className='pb-2'>
                        <Grid container direction="row">
                            <Grid item xs={12} sm={8}>
                                <div className={`fs-12 pl-3 ${props.smallScreen ? 'paragraph' : 'span'} col-8 d-inline ${!demand ? 'light-grey' : 'bold'}`}>{titleWaartevraagdichtheid}</div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={props.smallScreen ? 'float-left pl-2' : 'float-right pr-3'}>
                                    <img
                                        className='ml-2 pointer'
                                        src={demand ? questionMark : greyQuestion}
                                        onClick={() => setSecondInfo(!secondInfo)}
                                        alt="questionMark"
                                        name="question"
                                        width="20px"
                                        height="20px"
                                    />
                                    <img
                                        className={demand ? 'ml-2' : 'ml-2 pointer'}
                                        src={hideEye}
                                        src={demand ? eye : hideEye}
                                        onClick={() => props.setMapLayers(MAP_LAYERS.HEAT_DEMAND)}
                                        alt="eye"
                                        width="20px"
                                        height="20px"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    
                    {demand?
                    <>
                        <div className='col-12 pt-2 pb-2'>
                            <GradientBar  
                             leftColor={HEAT_DEMAND_COLORS.left}
                             rightColor={HEAT_DEMAND_COLORS.right}
                             radius = {'30'}/>
                        </div>

                        {!checkAuth || checkAuth && props.auth.active_view == 0 ?
                            <div className={`pb-3 text-center`}>
                                <span className={layer == MAP_LAYERS.HEAT_DEMAND ?`rounded-span`:`rounded-span sidepanel-current`}
                                onClick={(e)=>handleChild(e,MAP_LAYERS.HEAT_DEMAND)}>
                                Nu
                                </span>
                                <span className={layer == MAP_LAYERS.HEAT_DEMAND_2050 ?`rounded-span2 sidepanel-future`:`rounded-span2`}
                                onClick={(e)=>handleChild(e,MAP_LAYERS.HEAT_DEMAND_2050)}>
                                2050
                                </span>
                            </div>
                        :''}

                        {checkAuth && props.auth.active_view == 1?
                            <Grid className="pt-4" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InsideContainer
                                    radioList={firstRadioList} 
                                    fields={fields}
                                    handleRadio={handleFirstRadio}
                                    handleFields={handleFields}
                                    handleSubmit={handleAddHeatDemandSubmit}
                                    loaderMessage={"Click on map to add new Demand"}
                                    cancelSubmission={props.handleCancelHeatDemandAddition}
                                    submitError={showErrorDialog}
                                    name='first'
                                    text={buttonWaartevraagdichtheidText}
                                    addNewText={addWarmtevraagdichtheidBtn}
                                    obligedText={addWarmtevraagdichtheidObliged}
                                    addButtonLoadingState={props.addHeatDemandLoadingState}
                                    options={options}
                                />
                            </Grid>
                        :""}
                    </>
                    :''}
                    
                    {demand && secondInfo?
                    <>
                       <div className='container'>
                            <Divider className=''/>
                       </div>
                       <div className='col-12 pt-3'>
                            <div className='fs-11 light-grey'>{parse(`${helpTextWaartevraagdichtheid}`)}</div>
                       </div>
                       <Divider className=''/>
                    </>
                   : <Divider className=''/>
                   }
                    </div>
                   <div className='sidepanel-section pt-1'
                            onClick={()=>props.setMapLayers(MAP_LAYERS.ZONING_PLANS)}
                            >
                        <Grid container direction="row">
                            <Grid item xs={12} sm={8}>
                                    <div className={`fs-12 pl-3 ${props.smallScreen ? 'paragraph' : 'span'} ${layer != MAP_LAYERS.ZONING_PLANS?'light-grey':'bold'}`}>
                                        {titleWarmteBestemmingsplannen || "Warmtezoneringsplannen"}
                                    </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={props.smallScreen ? 'float-left pl-2' : 'float-right pr-3'}>
                                    <img
                                        className='ml-2 pointer'
                                        src={layer === MAP_LAYERS.ZONING_PLANS?questionMark:greyQuestion}
                                        onClick={()=>setFourthInfo(!fourthInfo)}
                                        alt="questionMark"
                                        width="20px"
                                        height="20px"
                                    />
                                    <img
                                        className={layer === MAP_LAYERS.ZONING_PLANS?'ml-2':'ml-2 pointer'}
                                        src={layer === MAP_LAYERS.ZONING_PLANS?eye:hideEye}
                                        onClick={()=>props.setMapLayers( MAP_LAYERS.ZONING_PLANS)}
                                        alt="eye"
                                        width="20px"
                                        height="20px"
                                    />
                                    </div>
                                </Grid>
                                </Grid>
                   </div>
                                {layer === MAP_LAYERS.ZONING_PLANS ?
                                <>
                                    <div className='container pt-3 pb-4'>
                                        <MultiColorBar
                                            colors={[
                                                {hex: COLLECTIVE_HEATING_PROBABILITY["1"], percentage: 20},
                                                {hex: COLLECTIVE_HEATING_PROBABILITY["2"], percentage: 20},
                                                {hex: COLLECTIVE_HEATING_PROBABILITY["3"], percentage: 20},
                                                {hex: COLLECTIVE_HEATING_PROBABILITY["4"], percentage: 20},
                                                {hex: COLLECTIVE_HEATING_PROBABILITY["5"], percentage: 20}
                                            ]}
                                            labels={[
                                                "Collectief", "Onbeslist", "Individueel"
                                            ]}
                                        />
                                    </div>
                                    {fourthInfo?
                                        <>
                                        <div className='col-12 pt-2'>
                                            <div className='fs-11 light-grey'>
                                                {/* {parse(`${helpTextWarmteBestemmingsplannen}`)} */}
                                                Binnenkort beschikbaar!
                                            </div>
                                        </div>
                                        <Divider className=''/>
                                        </>
                                    :''
                                    }
                                </>
                                    :
                                    ""
                                }
                    {checkAuth && props.auth.active_view == 1?
                        <div className='sidepanel-section pt-1'
                            onClick={()=>props.setMapLayers( MAP_LAYERS.HEAT_SUPPLY)}
                            > 
                            <div className='pb-2'>
                        <Grid container direction="row">
                            <Grid item xs={12} sm={8}>
                                <div className={`fs-12 pl-3 ${props.smallScreen ? 'paragraph' : 'span'} ${layer != MAP_LAYERS.HEAT_SUPPLY?'light-grey':'bold'}`}>{titleWarmteAanbod}</div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={props.smallScreen ? 'float-left pl-2' : 'float-right pr-3'}>
                                    <img
                                        className='ml-2 pointer'
                                        src={layer == MAP_LAYERS.HEAT_SUPPLY ? questionMark : greyQuestion}
                                        onClick={() => setThirdInfo(!thirdInfo)}
                                        alt="questionMark"
                                        width="20px"
                                        height="20px"
                                    />
                                    <img
                                        className={layer == MAP_LAYERS.HEAT_SUPPLY ? 'ml-2' : 'ml-2 pointer'}
                                        src={layer == MAP_LAYERS.HEAT_SUPPLY ? eye : hideEye}
                                        onClick={() => props.setMapLayers(MAP_LAYERS.HEAT_SUPPLY)}
                                        alt="eye"
                                        width="20px"
                                        height="20px"
                                    />
                                    </div>
                                </Grid>
                                </Grid>
                                {layer == MAP_LAYERS.HEAT_SUPPLY && checkAuth?
                                <>
                                    <div className='container pt-3 pb-4'>
                                        <GradientBar  
                                            leftColor={HEAT_SUPPLY.left}
                                            rightColor={HEAT_SUPPLY.right}
                                            radius = {'30'}
                                        />
                                    </div>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InsideContainer
                                            radioList={secondRadioList} 
                                            fields={fields2}
                                            handleRadio={handleSecondRadio}
                                            handleFields={(e, index, name)=> handleFields(e, index, name, fields2, setFields2)}
                                            handleSubmit={handleAddHeatSupplySubmit}
                                            loaderMessage={"Click on map to add new Supply"}
                                            submitError={showErrorDialog}
                                            cancelSubmission={props.handleCancelHeatSupplyAddition}
                                            addButtonLoadingState={props.addHeatSupplyLoadingState}
                                            name='second'
                                            obligedText={addWarmtebronnenObliged}
                                            addNewText={WarmtebronnenAddNewBtn}
                                            text={buttonWarmteAanbodText}
                                            options={options}
                                        />
                                    </Grid>
                                    {thirdInfo?
                                        <>
                                        <div className='container'>
                                            <Divider className=''/>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <div className='fs-11 light-grey'>{parse(`${helpTextWarmteAanbod}`)}</div>
                                        </div>
                                        <Divider className=''/>
                                        </>
                                    :''
                                    }
                                </>
                            :""}  
                        </div>
                        </div>
                        :""
                    }
                    <Divider className=''/>
                    {!checkAuth || props.auth.active_view == 0 ?
                        <div className='text-center pt-3 pb-3'>
                            <EmptyButton name={buttonLinkDelen} handle={handleButton} />
                            <CopyText text={copyQuery} onCopied={onCopied} />
                        </div>
                        :''
                    }
                </div>
                :''}
                {checkAuth && !hidePanel && props.auth.active_view == 1?
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <BottomSection
                        wmsInAction={props.wmsInAction}
                        handleToggleMapService={props.handleToggleMapService}
                        changeMapServiceHtml={()=> {}}
                        bottomSectionContent={bottomSectionContent}
                        handleCalculateSimulation={props.handleCalculateSimulation}
                        submitCalculateState={props.submitCalculateState}
                        handleDownload={props.handleDownload}
                        handleSubmitSimulation={props.handleSubmitSimulation}
                        submitSimulationState={props.submitSimulationState}
                        handleChild={handleChild}
                    />
                </Grid>
                :''}

                    {checkAuth && props.auth.active_view == 1 &&
                        <EconomicViability
                            smallScreen={props.smallScreen}
                        />
                    }

                <OtherMessages
                    show={showMessage}
                    message={URL_MESSAGE}
                />
                {/* </div> */}
                </Grid >
                {/* <ErrorMessage
                    error={showErrorDialog}
                    msg={errorMessage}
                /> */}
            {/* </Container> */}
        </>
    )
}
const mapStateToProps = ({auth,content,map,loader}) => ({auth,content,map,loader})
export default connect(mapStateToProps, {setMapLayers: setSelectedMapLayer, activeView}) ( SidePanel );