export const DEFAULT_SELECTED_AREA = {
    addressId: "",
    houseNo: "",
    streetId: "",
    streetName: "",
    municipalityName: "",
    municipalityId: "",
    postCode: ""
}

export const MAP_LAYERS = {
    COLLECTIVE_HEAT_MAPPING: 'collective-mapping',
    HEAT_DEMAND: 'heat-demand',
    HEAT_SUPPLY: 'heat-supply',
    ZONING_PLANS: 'zoning-plans',
    RESIDENTIAL_RENOVATION: 'residential-renovation',
    NON_RESIDENTIAL_RENOVATION: 'non-residential-renovation',
    COST_HEAT_NETWORK: 'cost-heat-network',
    HEAT_DEMAND_2050: 'heat-demand-2050',
}

export const SEARCH_BAR_TYPES = {
    WITH_BUTTON: 1,
    WITH_SEARCH_ICON: 2
}


export const RENOVATION_DEGREE = {
    1: "#D00D0D",
    2: "#FC0000",
    3: "#FD7F00",
    4: "#FFC400",
    5: "#BAD00D",
    6: "#43B02A"
}

export const SEARCH_LEVELS = {
    HOUSE: "house",
    STREET: "street",
    MUNICIPALITY: "municipality",
    COMPLETE: "complete"
}

export const COLLECTIVE_HEATING_PROBABILITY = {
    0: "#908c39",
    1: "#702082",
    2: "#B78FC0",
    3: "#97999B",
    4: "#A0D794",
    5: "#43B02A"
}

export const COLLECTIVE_HEATING_PROBABILITY_CLUSTERING = {
    0: "#908c39",
    "Collectief": "#702082",
    "Eerder Collectief": "#B78FC0",
    "Onzeker": "#dedede",
    "Eerder individueel": "#A0D794",
    "Individueel": "#43B02A",
    "SelectedCluster": "#f57623"
}

export const HEAT_DEMAND_COLORS = {
    left: "#BED7FF",
    leftRgb: "rgb(190, 215, 255)",
    right: "#1A3D74",
    rightRgb: "rgb(26, 61, 116)"
}

export const HEAT_SUPPLY = {
    left:'#DAC0E0',
    leftRgb: "rgb(218, 192, 224)",
    right:'#702082',
    rightRgb: "rgb(112, 32, 130)"
}

export const MAX_HEAT_DEMAND_VALUE = 250

export const SIMULATION_STATUS = {
    IDLE: "idle",       // no changes
    CALCULATING: "calculating",
    UPDATING: "updating",
    CALCULATED: "calculated",
    TOUCHED_AFTER_CALCULATION: "touched_after_calculation",      // to track immediate saving after calculation => Decision algo is not triggered otherwise
    UPDATED: "updated",
    SAVED: 'saved',
    TOUCHED_AFTER_SAVING: "touched_after_saving",      // to track immediate changes after saving => Decision algo is not triggered otherwise
}

export const HEAT_STATES = {
    ADDED: "added",
    CALCULATED: "calculated",
    SUBMITTED: "submitted"
}

export const DEFAULT_HEAT_DEMAND = {
    power: null,
    heat_demand: null,
    year: null,
    length: null,
    location: {
        lat: null, lng: null
    },
    type: ""
}

export const DEFAULT_SIMULATION_STATE = {
    simulation_id: null,
    simulation_saved_id: null,
    simulation_name: null,
    map_view: {lat: 0.0, lng: 0.0},
    price: "Medium",
    connection_cost: "Medium",
    renovation_level: {
        "res": "F",
        "nres": "F"
    }
}

export const DEFAULT_SELECTED_SIMULATION = {
    simulation_id: null,
    municipalityNames: [],
    statistical_sectors: [],
    simulationData: null
}

export const ICON_PATH = {
    RESIDENTIAL: "/icons/residential.svg",
    INDUSTRIAL: "/icons/industrial.svg",
    INDUSTRIAL_LIGHT: "/icons/industrial_light.svg",
    SCHOOL: "/icons/school.svg"
}

export const RENOVATION_LEVEL_TO_YEAR = {
    F: 2020,
    D: 2030,
    B: 2040,
    A: 2050
}

export const RENOVATION_LEVEL_TO_YEAR_REVERSE = {
    2020: 'F',
    2030: 'D',
    2040: 'B',
    2050: 'A'
}

export const COST_MAPPING = {
    "Laag": "low",
    "Medium": "medium",
    "Hoog": "high"
}

export const COST_REVERSE_MAPPING = {
    "low": "Laag",
    "medium": "Medium",
    "high": "Hoog"
}

export const HEAT_DEMAND_TYPES = {
    RESIDENTIAL: "residential",
    INDUSTRIAL: "industrial",
    OTHER: "other"
}

export const HEAT_DEMAND_TITLES_DUTCH = [
    {
        english: HEAT_DEMAND_TYPES.RESIDENTIAL,
        dutch: "Residentie"
    },
    {
        english: HEAT_DEMAND_TYPES.INDUSTRIAL,
        dutch: "Industrieel"
    },
    {
        english: HEAT_DEMAND_TYPES.OTHER,
        dutch: "Tertair"
    }
]

export const simulationReportFormat = [
    {
        label: "Gemeente",
        key: "name"
    },
    {
        label: "Totale oppervlakte (km²)",
        key: "area"
    },
    {
        label: "Straatlengte (km)",
        key: "streets_length"
    },
    {
        label: "Totaal aantal gebouwen",
        key: "no_of_buildings"
    },
    {
        label: "Aandeel gebouwen individueel (%)",
        key: "buildings_percentage_with_individual_heating"
    },
    {
        label: "Aandeel gebouwen collectief (%)",
        key: "buildings_percentage_with_collective_heating"
    },
    {
        label: "Aantal niet-residentiële gebouwen",
        key: "nres_buildings"
    },
    {
        label: "Aantal woongebouwen",
        key: "res_buildings"
    },
    {
        label: "Gemiddelde Warmtevraag huidige toestand Residentieel (MWh)",
        key: "avg_consumption_res_now"
    },
    {
        label: "Gemiddelde Warmtevraag huidige toestand Niet-residentieel (MWh)",
        key: "avg_consumption_nres_now"
    },
    {
        label: "Gemiddelde Warmtevraag renovatiescenario Residentieel (MWh)",
        key: "avg_consumption_res_2050"
    },
    {
        label: "Gemiddelde Warmtevraag renovatiescenario Niet-residentieel (MWh)",
        key: "avg_consumption_nres_2050"
    },
    {
        label: "Totale huidige warmtevraag (GWh)",
        key: "avg_consumption_total_now"
    },
    {
        label: "Totale warmtevraag - renovatiescenario (GWh)",
        key: "avg_consumption_total_2050"
    }]
