import React from "react";
import {Card} from "reactstrap";
import parse from 'html-react-parser';
export const InfoCard = ({
    classes="",
    cardSize="small",            // sizes can be none, small, medium and large
    background="grey",          // two types grey and green available
    title="",
    innerText="",
    innerTextListItemIdentifier="file_name",
    innerTextUrlAttribute="file_url",
    innerTextClasses="",
    onInnerTextClickUrl="",
    innerTextPostfix = null,
    innerText2="",
    innerText2Postfix = null,
    bottomPadding
    })=> {
    return (
        <Card 
         className={`${classes} info-card p-2 rounded ${background === "green" ?
         "bg-green" : ""} ${cardSize === "small" ?
         "small-card" : cardSize === "medium" ?
         "medium-card" : cardSize === "large" ?
         "large-card" : cardSize ==='x-large' ? 
         "x-large-card" : cardSize ==='half-card' ? 
         "half-card" : cardSize ==='full-large' ? 
         "full-card" : ""} ${typeof innerText === "object" ? "overflow-y" : ""}`
        }
        >
            {title !== "" && <p className="">{parse(`${title}`)}</p>}
            <span className="bold inner-card-text">
                {typeof innerText === "object" ?
                    <>
                        {
                            innerText.map(oneTextItem=> {
                                return (
                                    <>
                                    <span>
                                        {
                                            oneTextItem[innerTextUrlAttribute] ?
                                                <a className={innerTextClasses}
                                                   href={oneTextItem[innerTextUrlAttribute]}>{oneTextItem[innerTextListItemIdentifier]}</a>
                                                :
                                                <span className={innerTextClasses}>
                                                    {oneTextItem[innerTextListItemIdentifier]}
                                                </span>
                                        }
                                    </span>
                                        <br/>
                                    </>
                                )
                            })
                        }
                    </>
                    :
                    <>
                        {onInnerTextClickUrl === "" ?
                            <span
                                className={`${innerText2Postfix ? "two-unit-left" : bottomPadding ? 'two-unit-left' : ''} ${innerTextClasses}`}>{innerText}</span>
                            :
                            <a className={innerTextClasses} href={onInnerTextClickUrl}>{innerText}</a>
                        }
                    </>
                }
                {innerTextPostfix !== null && 
                <span className={ innerText2Postfix?
                "two-unit-left pl-1 units-text light-grey font-weight-light":
                `pl-1 units-text light-grey font-weight-light ${bottomPadding?'two-unit-left':''}`
                }
                >
                    {innerTextPostfix}
                </span>}
                {innerText2Postfix &&
                <span className="two-unit-right">
                    
                    {innerText2 !== "" && <span className="ml-2"> {innerText2} </span>}
                    {innerText2Postfix !== null && <span className="units-text light-grey font-weight-light">{innerText2Postfix}</span>}
                </span>
                }
            </span>
        </Card>
    )
}