// BASE
//export const BASE_URL = 'https://backend.inspiratiekaartwarmtezonering.be';
export const BASE_URL = "https://backend.ww.production.vvsgkube.agifly.cloud";
// export const BASE_URL = "http://localhost:3002"


// AUTH
export const USER_LOGIN = 'USER_LOGIN';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const TOKEN_EXPIRE = 'TOKEN_EXPIRE';
export const USER_VIEW = 'USER_VIEW';
export const CHECK_EMAIL = 'CHECK_EMAIL';

// LOADER
export const SHOW_ERROR_MESSAGE    = "SHOW_ERROR_MESSAGE ";
export const SHOW_SUCCESS_MESSAGE  = "SHOW_SUCCESS_MESSAGE";
export const SHOW_LOADER  = "SHOW_LOADER";
export const HIDE_LOADER  = "HIDE_LOADER";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

// MAP
export const SELECTED_AREA = "SELECTED_AREA"
export const SEARCH_LEVEL = "SEARCH_LEVEL"
export const SELECTED_MAP_LAYER = "MAP_LAYER_TYPE"
export const SIMULATION_PARAMS = "SIMULATION_PARAMS"
export const SIMULATION_STATUS_TOGGLE = "SIMULATION_STATUS_TOGGLE"
export const SIMULATION_HEAT_DEMAND = "SIMULATION_HEAT_DEMAND"
export const SIMULATION_HEAT_SUPPLY = "SIMULATION_HEAT_SUPPLY"
export const SELECTED_SIMULATION_DETAILS = "SELECTED_SIMULATION_DETAILS"
export const SIMULATION_CASE = "SIMULATION_CASE"
export const SELECTED_CLUSTER = "SELECTED_CLUSTER"
export const SELECTED_CLUSTER_GEOJSON = "SELECTED_CLUSTER_GEOJSON"
export const SELECTED_CLUSTER_TYPE = "SELECTED_CLUSTER_TYPE"

// CONTENT
export const HEADER_CONTENT = "HEADER_CONTENT";

// ZONING
export const ZONING_MUNICIPALITY = "ZONING_MUNICIPALITY";
export const ZONING_MUNICIPALITY_GEOMETRY = "ZONING_MUNICIPALITY_GEOMETRY";
export const ZONING_MUNICIPALITY_GEOMETRY_LOADING = "ZONING_MUNICIPALITY_GEOMETRY_LOADING";
export const UPLOADED_DOCUMENTS = "UPLOADED_DOCUMENTS";

// ECONOMIC VIABILITY
export const CHANGE_VIABILITY_STATE = "CHANGE_VIABILITY_STATE";
export const SHOW_CARDS = "SHOW_CARDS";

