import React, {useState, useEffect} from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {connect, useDispatch} from "react-redux";
import {withRouter, useHistory} from "react-router-dom";
import simulationService from "../../services/simulationService";
import {activeView} from '../../redux/actions/auth';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ROUTES} from "../../constants/routes";
import {MAP_LAYERS} from "../../constants/map";
import {setSimulationParams} from "../../redux/actions/simulation";
import {FillButton} from "../../helper/buttons";
import {getContentByModuleLocation} from '../../redux/actions/content'

const ITEM_HEIGHT = 48;

let simulationHeader = []

const UserSimulations = (props) => {
    const [simulations, setSimulations] = useState([])
    const [anchorEls, setAnchorEls] = useState({})
    const [headerContent, setHeaderContent] = useState(undefined);
    const [simulationAction, setSimulationAction] = useState(undefined);

    const history = useHistory()
    const dispatch = useDispatch()

    const options = [
        {
            name: "Edit",
            handler: (row) => {
                handleClose()
                props.activeView(1)
                dispatch(setSimulationParams({simulation_id: row.id, simulation_saved_id: row.id, simulation_state: "saved"}))
                // let searchParams = {
                //     layer: MAP_LAYERS.HEAT_DEMAND,
                //     level: "complete"
                // }
                // let paramsQuery = new URLSearchParams({...searchParams}).toString()
                // history.push({
                //     pathname: '/map',
                //     search: paramsQuery
                // })
                history.push(ROUTES.MAP_PAGE)
            }
        },
        {
            name: "Delete",
            handler: async (row) => {
                console.log('sim id ', row, row.id)
                await simulationService.deleteUserSimulation(row.id)
                gettingAllSimulations();
                handleClose()
            }
        }
    ];

    const gettingAllSimulations = async () => {
        try {
            const allSimulationsRes = await simulationService.getUserSimulations()
            if (!allSimulationsRes.errors.length) {
                setSimulations(allSimulationsRes.data)
                makeAnchorEls(allSimulationsRes.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(()=> {
        props.activeView(5)
        ;(async ()=> {
            gettingAllSimulations();
        })();

        async function getContent(){
            let data = await props.getContentByModuleLocation('simulations');
            console.log(data);
            setHeaderContent(data?.tableHeadings?.attributes);
            setSimulationAction(data?.simulationActions?.attributes)
        }

        getContent();
    }, [])

    // let simulationHeader = ["Naam van de simulatie", "Creatiedatum", "Datum van de laatste wijziging", "Status", "Acties"]

    useEffect (()=> {
        simulationHeader = [
            headerContent?.simulationName, 
            headerContent?.simulationCreatedDate,
            headerContent?.simulationModificationDate,
            headerContent?.simulationStatus,
            headerContent?.simulationAction
        ];
    }, [headerContent])
    const makeAnchorEls = (sims) => {
        let localAnchorEls = {}
        for (let sim of sims) {
            localAnchorEls[sim.id] = {open: false, el: null}
        }
        setAnchorEls(localAnchorEls)
    }

    const handleClick = (event, id) => {
        let localAnchorEls = {...anchorEls}
        localAnchorEls[id] = { el: event.currentTarget, open: Boolean(event.currentTarget)}
        setAnchorEls(localAnchorEls)
    };

    const handleClose = () => {
        let localAnchorEls = {...anchorEls}
        for (let key of Object.keys(anchorEls)) {
            localAnchorEls[key].el = null
            localAnchorEls[key].open = false
        }
        setAnchorEls(localAnchorEls)
    };

    return (
    <div className="simulation-page">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                  {simulationHeader.map(item=> {
                      return (<TableCell align="center">
                          {item}
                      </TableCell>)
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(simulations.length && Object.keys(anchorEls).length) ? simulations.map((simRow) => (
                <TableRow
                  key={simRow.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {/* <TableCell align="center">
                    {simRow.id}
                  </TableCell> */}
                  <TableCell align="center">{simRow.name}</TableCell>
                  <TableCell align="center">{simRow.created_at}</TableCell>
                  <TableCell align="center">{simRow.updated_at}</TableCell>
                  <TableCell align="center">{simRow.sim_state}</TableCell>
                  <TableCell align="center">
                      <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls="long-menu"
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(event)=> handleClick(event, simRow.id)}
                      >
                          <MoreVertIcon/>
                      </IconButton>
                      <Menu
                          id="long-menu"
                          MenuListProps={{
                              'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEls[simRow.id].el}
                          open={anchorEls[simRow.id].open}
                          onClose={handleClose}
                          PaperProps={{
                              style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                              },
                          }}
                      >
                          {options.map((option) => (
                              <MenuItem key={option.name} onClick={()=> option.handler(simRow)}>
                                  {option.name}
                              </MenuItem>
                          ))}
                      </Menu>
                  </TableCell>
                </TableRow>
              ))
              :
              (<div>
              </div>)}
            </TableBody>
          </Table>
        </TableContainer>
        {!simulations.length && <div className="bg-orange rounded d-flex justify-content-center align-content-center">
            <span className="text-primary text-small">
                {simulationAction?.noSavedSimulationText}
            </span>
        </div>}
        <div className="mt-2 d-flex justify-content-center align-content-center">
            <FillButton
                name={simulationAction?.newSimulationButton}
                extra_classes='new-sim-button'
                onClickHandler={()=> {
                    props.activeView(1)
                    history.push(ROUTES.MAP_PAGE)
                }}
            />
        </div>
    </div>
    )
}

const mapStateToProps = ({main,loader}) => ({main,loader})
export default connect(mapStateToProps, { activeView,getContentByModuleLocation }) ( withRouter(UserSimulations) );