
const INIT_STATE = {
    all_headings:[],
}
const content= (state = INIT_STATE, action) => {
    switch(action.type) {
        case 'TESTING':
            return {
                ...state,
                all_headings: action.payload
            }

        default:
            return state;
    }
}

export default content;