import L from "leaflet";
import {COLLECTIVE_HEATING_PROBABILITY, HEAT_DEMAND_COLORS, MAP_LAYERS, MAX_HEAT_DEMAND_VALUE, COLLECTIVE_HEATING_PROBABILITY_CLUSTERING, HEAT_STATES, RENOVATION_LEVEL_TO_YEAR, HEAT_DEMAND_TYPES, HEAT_DEMAND_TITLES_DUTCH} from "../constants/map";
import {textWithCapitalFirstLetter} from "./generalHelpers";

export function interpolateColor (color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5;
    }
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
}

// My function to interpolate between two colors completely, returning an array
export function interpolateColors(color1, color2, steps) {
    var stepFactor = 1 / (steps - 1),
        interpolatedColorArray = [];

    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);

    for(var i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
}

export const getExtremesInGeoJSON = (geojson, attr)=> {
    let extremes = {
        min: 100000000000000,
        max: -1
    }
    geojson.features.forEach((feature)=> {
        if (feature.properties[attr] > extremes.max)
            extremes.max = feature.properties[attr]
        if (feature.properties[attr] < extremes.min)
            extremes.min = feature.properties[attr]
    })
    return extremes
}

export const getMarkerIcon = (iconUrl="/icons/mapMarker.png", width=25, height=25, anchorLeft=10, anchorRight=10)=> {
    return L.icon({
        iconUrl: iconUrl,
        iconSize: [width, height],
        iconAnchor: [anchorLeft, anchorRight],
        pane: "markerPane"
    })
}
export const getStreetsMapLayer = (streetsGeometry, layerType=MAP_LAYERS.COLLECTIVE_HEAT_MAPPING, newHeatDemands={}, layerTitle="layerTitle", simulationParams=null)=> {
    const heatDemandColors = interpolateColors(HEAT_DEMAND_COLORS.leftRgb, HEAT_DEMAND_COLORS.rightRgb, MAX_HEAT_DEMAND_VALUE)
    return L.geoJSON(
        streetsGeometry,
        {
            title: layerTitle,
            style: (feature => {
                let calculatedHeatDemand = feature.properties.heat_demand
                const segmentId = feature.properties.segment_id
                if (layerType === MAP_LAYERS.HEAT_DEMAND && newHeatDemandCompliesToSettings(simulationParams, segmentId, newHeatDemands))
                    calculatedHeatDemand += newHeatDemands[segmentId] ? newHeatDemands[segmentId].value : 0
                return {
                    color: layerType === MAP_LAYERS.COLLECTIVE_HEAT_MAPPING ? COLLECTIVE_HEATING_PROBABILITY[feature.properties.heat_probability] || COLLECTIVE_HEATING_PROBABILITY[0]
                        :
                        `rgb(${calculatedHeatDemand >= MAX_HEAT_DEMAND_VALUE ? heatDemandColors[MAX_HEAT_DEMAND_VALUE-1] : heatDemandColors[parseInt(calculatedHeatDemand)]})`,
                    weight: 2
                }
            }),
            onEachFeature: (feature, layer)=>  {
                let calculatedHeatDemand = feature.properties.heat_demand
                const segmentId = feature.properties.segment_id
                if (layerType === MAP_LAYERS.HEAT_DEMAND && newHeatDemandCompliesToSettings(simulationParams, segmentId, newHeatDemands))
                    calculatedHeatDemand += newHeatDemands[segmentId] ? newHeatDemands[segmentId].value : 0
                layer.typeTag = layerTitle ? layerTitle : "Street"
                layer.bindPopup(
                    '<div class="popup">' +
                    '<b>' + "Straat: " + '</b>' + textWithCapitalFirstLetter(feature.properties.street_name) + '<br>' +
                    '<b>' + "Gemeente: " + '</b>' + feature.properties.mun_name + '<br>' +
                    `${layerType === MAP_LAYERS.COLLECTIVE_HEAT_MAPPING ?
                        '<b>' + "Zoekrichting: " + '</b>' + feature.properties.heat_probability :
                        '<b>' + "Warmtevraagdensiteit: " + '</b>' + calculatedHeatDemand.toFixed(2) 
                    }`
                        +
                    '</div>'
                )
            }
        }
    )
}

// excludeFeatures Structure: { key1: value1, key2: value2 }
export const getStreetsMapLayerClustering = (streetsGeometry, layerTitle="layerTitle", simulationParams=null, excludeFeatures={}, disablePopups=false)=> {
    return L.geoJSON(
        streetsGeometry,
        {
            title: layerTitle,
            style: (feature => {
                    for (const key of Object.keys(excludeFeatures)) {
                        if (excludeFeatures[key] === feature.properties[key])
                            return {
                            }
                    }
                    return {
                        color: layerTitle === "clusterSelectedLayer" ? COLLECTIVE_HEATING_PROBABILITY_CLUSTERING.SelectedCluster : COLLECTIVE_HEATING_PROBABILITY_CLUSTERING[feature.properties.cluster_label] || COLLECTIVE_HEATING_PROBABILITY_CLUSTERING[0],
                        weight: layerTitle === "clusterSelectedLayer" ? 5 : 2
                    }
                }),
            onEachFeature: (feature, layer)=>  {
                for (const key of Object.keys(excludeFeatures)) {
                    if (excludeFeatures[key] === feature.properties[key])
                        return
                }
                layer.typeTag = layerTitle ? layerTitle : "Street"
                if (!excludeFeatures.cluster_id && layerTitle !== "clusterSelectedLayer" && !disablePopups) {
                    layer.bindPopup(
                        '<div class="popup">' +
                        '<b>' + "Straat: " + '</b>' + textWithCapitalFirstLetter(feature.properties.street_name) + '<br>' +
                        '<b>' + "Gemeente: " + '</b>' + (feature.properties?.muni_name || feature.properties?.mun_name) + '<br>' +
                        '<b>' + "Zoekrichting: " + '</b>' + feature.properties.cluster_label
                        +
                        '</div>'
                    )
                }
            }
        }
    )
}

export const newHeatDemandCompliesToSettings = (simulationParams, segmentId, newHeatDemands)=> {
    if (![null, undefined].includes(simulationParams) && ![null, undefined].includes(segmentId) && Object.keys(newHeatDemands).includes(segmentId.toString()) && newHeatDemands[segmentId].type === "res" ? (newHeatDemands[segmentId]?.year <= RENOVATION_LEVEL_TO_YEAR[simulationParams?.renovation_level.res]) : newHeatDemands[segmentId]?.year <= RENOVATION_LEVEL_TO_YEAR[simulationParams?.renovation_level.nres])
        return true
    return false
}

export const convertCQLObjToGeoServerCQL = (cqlArray)=> {
    let cqlStr = ""
    for (let value of cqlArray) {
        if (value) {
            let subCql = value
            if (typeof value === "object" && value.length) {
                let subQuery = ""
                for (let subValue of value) {
                    if (subValue === "INCLUDE")
                        subQuery = "INCLUDE"
                    else if (subValue !== null) {
                        if (subQuery === "INCLUDE")
                            subQuery = subValue
                        else {
                            if (subQuery !== "")
                                subQuery += "AND"
                            subQuery += subValue
                        }
                    }
                }
                subCql = subQuery
            }
            if (cqlStr !== "")
                cqlStr += ";"
            cqlStr += subCql
        }
    }
    console.log('cql str i s ', cqlStr)
    return cqlStr
}

export const getPointsMapLayer = (pointsGeometry, layerType=MAP_LAYERS.HEAT_SUPPLY)=> {
    return L.geoJSON(
        pointsGeometry,
        {
            pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, {
                    radius: 8,
                    fillColor: "#ff7800",
                    color: "#000",
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.8
                })},
            onEachFeature: (feature, layer)=>  {
                layer.bindPopup(
                    '<div class="popup">' +
                    '<b>' + "ID: " + '</b>' + feature.properties.id + '<br>' +
                    '<b>' + "Dichtstbijzijnde straat segment: " + '</b>' + feature.properties.connected_segment + '<br>' +
                    '<b>' + "Type: " + '</b>' + feature.properties.type
                        +
                    '</div>'
                )
            }
        }
    )
}

export const getMunicipalityLayer = (municipalityGeometry)=> {
    return L.geoJSON(
        JSON.parse(municipalityGeometry),
        {
            title: "municipalityOutline",
            zIndex: 10000,
            style: (feature => {
                return {
                    "z-index": 1000,
                    color: "black",
                    fillOpacity: 0,
                    weight: 2
                }
            })
        }
    )
}

export const prepareSelectedAreaObjFromSlug = (slug)=> {
    return {
        municipalityName: slug.municipality || "",
        municipalityId: slug.municipalityId || "",
        addressId: slug.addressId || "",
        houseNo: slug.houseNo || "",
        streetId: slug.streetId || "",
        streetName: slug.street || "",
        postCode: slug.postCode || ""
    }
}

export const prepareHeatDemandsForStore = (heatDemandsFromDb)=> {
    let formattedDemands = []
    for (let dbDemand of heatDemandsFromDb) {
        formattedDemands.push({
            id: dbDemand.id,
            connected_segment: dbDemand.connected_segment,
            addition_status: dbDemand.addition_status,
            type: dbDemand.type,
            power: dbDemand.power_res ? dbDemand.power_res : dbDemand.power_nres,
            heat_demand: dbDemand.demand_res ? dbDemand.demand_res : dbDemand.demand_nres,
            year: dbDemand.year,
            length: dbDemand.length_res ? dbDemand.length_res : dbDemand.length_nres,
            location: dbDemand.location
        })
    }
    return formattedDemands
}

export const getHeatDemandPopupHtml = (demand, demandIndex, removeDemandHandler)=> {
    const allPopupContent = L.DomUtil.create("div", "popup")
    const popupSpan = document.createElement("span")
    let demandTitle = ""
    for (let demandTrans of HEAT_DEMAND_TITLES_DUTCH) {
        if (demandTrans.english === demand.type) {
            demandTitle = demandTrans.dutch
            break
        }
    }
    popupSpan.innerHTML = '<b>' + "Type: " + '</b>' + demandTitle + '<br>' +
        '<b>' + "Vermogen: " + '</b>' + demand.power + '<br>' +
        '<b>' + "Warmtevraagdensiteit: " + '</b>' + demand.heat_demand + '<br>' +
        '<b>' + "Jaar: " + '</b>' + demand.year + '<br>' +
        '<b>' + "Extra straatlengte: " + '</b>' + demand.length + '<br>'
    const popupButton = document.createElement("button")
    popupButton.classList.add("popup-button")
    popupButton.innerText = "Verwijder"
    popupButton.addEventListener("click", () => removeDemandHandler(demandIndex, demand.id))
    popupSpan.appendChild(popupButton)
    allPopupContent.appendChild(popupSpan.getRootNode())
    return allPopupContent
}

export const getHeatSupplyPopupHtml = (supply, supplyIndex, removeSupplyHandler)=> {
    const allPopupContent = L.DomUtil.create("div", "popup")
    const popupSpan = document.createElement("span")
    popupSpan.innerHTML = '<b>' + "Name: " + '</b>' + supply.name + '<br>' +
        '<b>' + "Type: " + '</b>' + supply.supply_type + '<br>' +
        '<b>' + "Vermogen: " + '</b>' + supply.power + '<br>' +
        '<b>' + "Jaarlijkse warmtevraag: " + '</b>' + supply.energy + '<br>' +
        '<b>' + "Productiekost: " + '</b>' + supply.production_cost + '<br>' +
        '<b>' + "Temperatuurniveau: " + '</b>' + supply.temperature + '<br>' +
        '<b>' + "Contact: " + '</b>' + supply.contact_info + '<br>' +
        '<b>' + "Extra info: " + '</b>' + supply.comments + '<br>'
    const popupButton = document.createElement("button")
    popupButton.classList.add("popup-button")
    popupButton.innerText = "Verwijder"
    popupButton.addEventListener("click", () => removeSupplyHandler(supplyIndex, supply.id))
    popupSpan.appendChild(popupButton)
    allPopupContent.appendChild(popupSpan.getRootNode())
    return allPopupContent
}

export const getZoningPlanMunicipalities = (muniGeometry, munisWithZoning, featureClickHandler, seletcedMuniciplaity, zoomLevel)=> {
    return L.geoJSON(
        muniGeometry,
        {
            title: "zoningMunicipalities",
            zIndex: 10000,
            style: (feature)=> getZoningMunicipalitiesStyle(feature, munisWithZoning, zoomLevel, seletcedMuniciplaity),
            onEachFeature: (feature, layer) => {
                layer.on("click",(event)=> {
                    console.log("Feature clicked", feature.properties.naam)
                    featureClickHandler(feature.properties.naam)
                })
            }
        }
    )
}

export const getZoningMunicipalitiesStyle = (feature, munisWithZoning, zoomLevel, seletcedMuniciplaity)=> {
    return {
        "z-index": 1000,
        color: munisWithZoning.includes(feature.properties.naam) ? "green" : "blue",
        fillOpacity: (zoomLevel >= 11 && feature.properties.naam === seletcedMuniciplaity) ? 0 : 0.1,
        weight: (feature.properties.naam === seletcedMuniciplaity) ? 2 : 1
    }
}

export const getHeatDemandLayerName = (resYear, nresYear)=> {
    const heatDemandLayers = {
        "2020_2020": "streetsDemandView",
        "2020_2030": "streetsHeatDemand2020res_2030nresView",
        "2020_2040": "streetsHeatDemand2020res_2040nresView",
        "2020_2050": "streetsHeatDemand2020res_2050nresView",
        "2030_2020": "streetsHeatDemand2030res_2020nresView",
        "2030_2030": "streetsHeatDemand2030res_2030nresView",
        "2030_2040": "streetsHeatDemand2030res_2040nresView",
        "2030_2050": "streetsHeatDemand2030res_2050nresView",
        "2040_2020": "streetsHeatDemand2040res_2020nresView",
        "2040_2030": "streetsHeatDemand2040res_2030nresView",
        "2040_2040": "streetsHeatDemand2040res_2040nresView",
        "2040_2050": "streetsHeatDemand2040res_2050nresView",
        "2050_2020": "streetsHeatDemand2050res_2020nresView",
        "2050_2030": "streetsHeatDemand2050res_2030nresView",
        "2050_2040": "streetsHeatDemand2050res_2040nresView",
        "2050_2050": "streetsHeatDemand2050res_2050nresView",
    }
    return heatDemandLayers[`${resYear}_${nresYear}`]
}