import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { EuroSymbol } from '@material-ui/icons';
import { setViabilityState, showCards } from '../../../redux/actions/economicViability';
import {setSelectedCluster, setSelectedClusterGeoJSON} from '../../../redux/actions/map';
import { ECONOMIC_VIABILITY_STATES } from '../../../constants/economicViability';
import { FillButton } from '../../../helper/buttons';
import {getContentByModuleLocation} from '../../../redux/actions/content'
import {SIMULATION_STATUS} from "../../../constants/map";

function EconomicViability(props) {

    const [state, setState] = useState(props.economicViability.selected_state);
    const [calculationDisabled, setCalculationDisabled] = useState(false);
    const [restrictionMessage, setRestrictionMessage] = useState("");
    const [content, setContent] = useState(undefined);

    useEffect(() => {
        async function getContent(){
            const data = await props.getContentByModuleLocation('sideEVMain');
            setContent(data?.economicViability?.attributes || undefined);            

        }

        getContent();

    }, []);

    useEffect(() => {
        setState(props.economicViability.selected_state)
    }, [props.economicViability.selected_state])

    useEffect(() => {
        setCalculationDisabled(true)
        if (["Individueel", "Eerder individueel"].includes(props.map.selectedClusterType)) {
            setRestrictionMessage("Vergelijkingsrapport kan enkel gemaakt worden voor onzekere, eerder collectief en collectieve clusters.")
        } else if (props.map.simulationHeatDemands.length && props.map.simulationStatus !== SIMULATION_STATUS.SAVED) {
            setRestrictionMessage("Economisch rapport kan niet berekend worden zonder eerst de simulatie te hebben opgeslagen")
        } else {
            setCalculationDisabled(false)
            setRestrictionMessage("")
        }
    }, [props.map.selectedClusterType, props.map.simulationStatus, props.map.simulationHeatDemands])

    const handleCalculate = (e) => {
        props.showCards(true)
        props.setViabilityState(ECONOMIC_VIABILITY_STATES.CALCULATED)
    }

    const handleEconomicViabilityEnd = ()=> {
        props.setViabilityState(ECONOMIC_VIABILITY_STATES.IDLE)
        props.setSelectedCluster(null)
        props.setSelectedClusterGeoJSON(null)
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row"
                    className='d-flex mt-5 pointer'
                    onClick={()=> {
                        if (state==ECONOMIC_VIABILITY_STATES.IDLE) props.setViabilityState(ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP);
                        if (state==ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP && (props.map.selectedCluster || props.map.selectedClusterInGeoJSON)) props.setViabilityState(ECONOMIC_VIABILITY_STATES.CLICKED_ON_MAP);
                        // else if (state==ECONOMIC_VIABILITY_STATES.CLICKED_ON_MAP) props.setViabilityState(ECONOMIC_VIABILITY_STATES.READY_TO_CALCULATE);
                        // else if (state==ECONOMIC_VIABILITY_STATES.READY_TO_CALCULATE) props.setViabilityState(ECONOMIC_VIABILITY_STATES.CALCULATED);
                        //For the time being
                        // if (state==ECONOMIC_VIABILITY_STATES.CLICKED_ON_MAP) props.setViabilityState(ECONOMIC_VIABILITY_STATES.IDLE)
                        // else props.setViabilityState(ECONOMIC_VIABILITY_STATES.IDLE)
                    }

                    }
                >
                    <Grid item xs={2} sm={1}
                        className={
                            state==ECONOMIC_VIABILITY_STATES.IDLE?'economic-circle':
                            state==ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP?'economic-circle2':
                            state==ECONOMIC_VIABILITY_STATES.CLICKED_ON_MAP?'economic-circle3':''
                        }
                    >
                        { state != ECONOMIC_VIABILITY_STATES.READY_TO_CALCULATE &&
                            <span className={`economic-icon ${props.smallScreen ? 'economic-icon-small' : ''}`}>
                                <EuroSymbol />
                            </span>
                        }
                    </Grid>
                    <Grid item xs={10} sm={11}
                        className={
                            state==ECONOMIC_VIABILITY_STATES.IDLE?'economic-step1':
                            state==ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP?'economic-step2':
                            state==ECONOMIC_VIABILITY_STATES.CLICKED_ON_MAP?'economic-step3':''
                        }
                    >
                        { 
                            state==ECONOMIC_VIABILITY_STATES.IDLE?
                                <span className={`economic-text pl-3 ${props.smallScreen ? 'text-x-small' : ''}`}>
                                    {content?.calculateHeading}
                                </span> :
                            state==ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP?
                                <span className='economic-text2'>
                                   {content?.placeHeading}
                                </span> :
                            state==ECONOMIC_VIABILITY_STATES.CLICKED_ON_MAP?
                                <div className='text-center mr-2'>
                                    <span className='economic-text3 pl-4'>
                                        {content?.placeHeading1}
                                    </span>
                                    <span className='economic-light-text'>
                                        {content?.closeText}
                                    </span>
                                    <div className='mt-2'>
                                        <FillButton
                                            name={content?.buttonCalculateText}
                                            disabled={calculationDisabled}
                                            onClickHandler={handleCalculate}
                                            loading={props.addButtonLoadingState || false}
                                        />
                                        <FillButton
                                            name={content?.evBackButtonText || 'Back'}
                                            onClickHandler={()=> props.setViabilityState(ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP)}
                                            loading={props.addButtonLoadingState || false}
                                        />
                                    </div>
                                    {calculationDisabled &&
                                        <span className="pb-3 text-red small">
                                            {restrictionMessage}
                                        </span>
                                    }
                                </div>
                            :''
                        }
                    </Grid>
                </Grid>
                {
                    state!==ECONOMIC_VIABILITY_STATES.IDLE &&
                        <div className="d-flex justify-content-center">
                            <FillButton
                                name={content?.endButton}
                                onClickHandler={handleEconomicViabilityEnd}
                            />
                        </div>
                }
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({economicViability,map,auth}) => ({economicViability,map,auth})
export default connect(mapStateToProps, { setSelectedCluster, setSelectedClusterGeoJSON, setViabilityState, showCards,getContentByModuleLocation }) ( EconomicViability );
