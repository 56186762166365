import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import simulationService from "../../../services/simulationService";
import { InfoCard } from "../../sharedComponents/infoCard";
import { FillButton } from "../../../helper/buttons";
import { CSVLink } from "react-csv";
import { simulationReportFormat } from "../../../constants/map";
import Grid from "@material-ui/core/Grid";

export const SimulationMapPageCard = ({content, smallScreen}) => {
    const [infoLoading, setInfoLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)
    const [csvHeaders] = useState(simulationReportFormat)
    const [csvFile, setCsvFile] = useState({})

    if (content) {
    var {
        generalInfoMainTitle,
        surfaceAreaTitle,
        streetLengthTitle,
        municipalityLevelTitle,
        contactInfoGemeente,
        totalBuildingsTitle,
        residentialBuildingsTitle,
        individualTitle,
        nonResidentialBuildingTitle,
        energyInfoMainTitle,
        currentAvgConsumptionResAndNRes,
        currentAvgConsumption,
        futureAvgConsumptionResAndNRes,
        futureAvgConsumption
    } = {
        ...content?.areaInfo.attributes,
        ...content?.energyInfo.attributes,
        ...content?.level.attributes
    }

}

    const [simPageData, setSimPageData] = useState({})

    const selectedArea = useSelector(state=> state.map.selectedArea)
    const simulationCase = useSelector(state=> state.map.simulationCase)

    useEffect(()=> {
        if (simPageData) {
            const dataForCsv = {}
            for (const [genCategoryKey, value] of Object.entries(simPageData)) {
                if (typeof value === "object") {
                    for (const [subCategoryKey, subValue] of Object.entries(value)) {
                        dataForCsv[subCategoryKey] = subValue
                    }
                }
                else {
                    dataForCsv[genCategoryKey] = value
                }
            }
            setCsvFile({
              data: [dataForCsv],
              headers: csvHeaders,
              filename: `${dataForCsv.name}.csv`
            })
        }
    }, [simPageData])

    useEffect(()=> {
        ;(async()=> {
            if (selectedArea.municipalityId !== "" || selectedArea.municipalityName !== "") {
                setInfoLoading(true)
                setDataAvailable(true)
                const simPageResult = await simulationService.getSimulationPageCardInfo(selectedArea.municipalityId !== "" ? selectedArea.municipalityId : null, selectedArea.municipalityName, simulationCase)
                if (simPageResult.data)
                    setSimPageData(simPageResult.data)
                setInfoLoading(false)
            }
            else {
                setDataAvailable(false)
            }
        })()
    }, [selectedArea.municipalityId, selectedArea.municipalityName, simulationCase])

    return (
        <div
            className={`simulation-card bg-white mb-3`}
            style={{width: '100%', height: '50%'}}
        >
            <div className="d-flex justify-content-center align-items-center simulation-card-header pt-1">
                <label> {municipalityLevelTitle}</label>
            </div>
            {dataAvailable
                ?
                <div>
                    {!infoLoading
                        ?
                        <div>
                            <div className="p-4">
                                <h6 className="primary-clr bold">
                                    {generalInfoMainTitle}
                                </h6>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InfoCard
                                            classes="card-title"
                                            cardSize="none" title={surfaceAreaTitle}
                                            innerText={simPageData.hasOwnProperty("general_municipality_details") ? simPageData.general_municipality_details.area.toFixed(2) : 0}
                                            innerTextPostfix={<>km<sup>2</sup></>}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InfoCard
                                            classes="card-title"
                                            cardSize="none" title={streetLengthTitle}
                                            innerText={simPageData.hasOwnProperty("general_municipality_details") && simPageData.general_municipality_details.streets_length ? simPageData.general_municipality_details.streets_length.toFixed(2) : 0}
                                            innerTextPostfix={"km"}
                                        />
                                    </Grid>

                                </Grid>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InfoCard
                                            classes="card-title"
                                            cardSize="none" title={totalBuildingsTitle}
                                            innerText={simPageData.hasOwnProperty("general_municipality_details") ? simPageData.general_municipality_details.no_of_buildings : 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InfoCard
                                            classes="card-title"
                                            cardSize="none" title={individualTitle}
                                            innerText={simPageData.hasOwnProperty("general_municipality_details") ? parseFloat(simPageData.general_municipality_details.buildings_percentage_with_individual_heating).toFixed(1) : 0}
                                            innerTextPostfix="%"
                                            innerText2={simPageData.hasOwnProperty("general_municipality_details") ? parseFloat(simPageData.general_municipality_details.buildings_percentage_with_collective_heating).toFixed(1) : 0}
                                            innerText2Postfix="%"
                                        />
                                    </Grid>
                                </Grid>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InfoCard
                                            classes="card-title"
                                            cardSize="none" title={nonResidentialBuildingTitle}
                                            innerText={simPageData.hasOwnProperty("general_municipality_details") ? simPageData.general_municipality_details.nres_buildings : 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InfoCard
                                            classes="card-title"
                                            cardSize="none" title={residentialBuildingsTitle}
                                            innerText={simPageData.hasOwnProperty("general_municipality_details") ? simPageData.general_municipality_details.res_buildings : 0}
                                        />
                                    </Grid>
                                </Grid>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <InfoCard
                                            classes="text-green bold"
                                            background="green"
                                            cardSize="none"
                                            title={contactInfoGemeente}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        <div className="p-4 pb-0">
                            <h6 className="primary-clr bold">
                                {energyInfoMainTitle}
                            </h6>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <InfoCard
                                        classes="card-title"
                                        cardSize="none" title={currentAvgConsumptionResAndNRes}
                                        innerText={simPageData.hasOwnProperty("power_consumption") && simPageData.power_consumption.avg_consumption_res_now ? simPageData.power_consumption.avg_consumption_res_now.toFixed(2) : 0}
                                        innerTextPostfix="MWh"
                                        innerText2={simPageData.hasOwnProperty("power_consumption") && simPageData.power_consumption.avg_consumption_nres_now ? simPageData.power_consumption.avg_consumption_nres_now.toFixed(2) : 0}
                                        innerText2Postfix="MWh"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <InfoCard
                                        classes="card-title"
                                        cardSize="none" title={currentAvgConsumption}
                                        innerText={simPageData.hasOwnProperty("power_consumption") && simPageData.power_consumption.avg_consumption_total_now ? simPageData.power_consumption.avg_consumption_total_now.toFixed(2) : 0}
                                        innerTextPostfix="GWh"
                                    />
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <InfoCard
                                        classes="card-title"
                                        cardSize="none" title={futureAvgConsumptionResAndNRes}
                                        innerText={simPageData.hasOwnProperty("power_consumption") && simPageData.power_consumption.avg_consumption_res_2050 ? simPageData.power_consumption.avg_consumption_res_2050.toFixed(2) : 0}
                                        innerTextPostfix="MWh"
                                        innerText2={simPageData.hasOwnProperty("power_consumption") && simPageData.power_consumption.avg_consumption_nres_2050 ? simPageData.power_consumption.avg_consumption_nres_2050.toFixed(2) : 0}
                                        innerText2Postfix="MWh"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InfoCard
                                        classes="card-title"
                                        cardSize="none" title={futureAvgConsumption}
                                        innerText={simPageData.hasOwnProperty("power_consumption") && simPageData.power_consumption.avg_consumption_total_2050 ? simPageData.power_consumption.avg_consumption_total_2050.toFixed(2) : 0}
                                        innerTextPostfix="GWh"
                                        bottomPadding={true}
                                    />
                                </Grid>

                            </Grid>
                        </div>
                                <CSVLink {...csvFile}>
                                    <FillButton
                                        extra_classes={"ml-4 mb-3"}
                                        onClickHandler={()=> {}}
                                        name={"Export"}
                                    />
                                </CSVLink>
                        </div>
                        :
                        <p className="text-small m-2">
                            Het rapport voor de gemeente wordt geladen ....
                        </p>
                    }
                </div>
                :
                <p className="text-small m-2">
                    Er is op dit moment geen gemeente geselecteerd
                </p>
            }

        </div>
    )
}