export const infoConstant= [
    {paragraph:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'},
    {paragraph:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'},
    {paragraph:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'}
]

export const firstRadioConstant = [
    {label: 'Residentie', name:'residential',value:true},
    {label: 'Industrieel', name:'industrial',value:false},
    {label: 'Tertair', name:'other',value:false},
]
export const secondRadioConstant= [
    {label: 'Industriële (rest)warmte en afvalverbranding', name:'industrial',value:true},
    {label: 'Warmtekracht koppeling', name:'heat_and_power',value:false},
    {label: 'Geothermie', name:'geothermal',value:false},
    {label: 'Aqua- en Riothermie', name:'aqua',value:false},
    {label: 'Biomassa', name:'biomass',value:false},
    {label: 'Zonnewarmte', name:'solar',value:false},
]

export const fieldConstant = [
    {name:'*Vermogen',type:'number',value:'',value2:'',error:false,error2:false,required:true},
    {name:'*Jaarlijkse warmtevraag',type:'number',value:'',value2:'',error:false,error2:false,required:true},
    {name:'*Jaar',type:'number',value:'2020',value2:'2020',error:false,error2:false,required:false},
    {name:'Extra straatlengte',type:'number',value:'',value2:'',error:false,error2:false,required:false},
]

export const field2Constant = [
    {name:'*Naam - label',type:'text',value:'',error:false,required:true},
    {name:'*Vermon (MW)',type:'number',value:'',error:false,required:true},
    {name:'*Energie (MWh)',type:'number',value:'',error:false,required:true},
    {name:'*Productiekost (€/MWh)',type:'number',value:'',error:false,required:true},
    {name:'*Temperatuurniveau (°C)',type:'number',value:'',error:false,required:true},
    {name:'*Contactinfo (contact info voor warmtebron)',type:'text',value:'',error:false,required:true},
    {name:'Opmerking (vrij tekstveld)',type:'text',value:'',error:false,required:false}
]