import React, { useEffect, useState } from "react";
import Parser from 'html-react-parser';
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel } from '@material-ui/core';
import { setSearchLevel, setSelectedArea } from "../../redux/actions/map";
import MapApiService from "../../services/mapApiService";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { DEFAULT_SELECTED_AREA, SEARCH_BAR_TYPES, SEARCH_LEVELS } from "../../constants/map";
import { ROUTES } from "../../constants/routes";
import search from '../../assets/search.svg';
import '../../css/search-bar.css';
import { CustomRadio } from '../../helper/customRadio';
import Grid from "@material-ui/core/Grid";

export const SearchBar = ({
        placeHolderText = 'Brusselsesteenweg 1-8, 1000 Brussel',
        searchBarBtnText,
        setClick = ()=> {},
        searchBarType=SEARCH_BAR_TYPES.WITH_BUTTON,
        searchBarClickHandler=()=> {},
        setActiveView,
        handleArrow,
        landing
    })=> {
        
        let inputCondition = searchBarType===SEARCH_BAR_TYPES.WITH_SEARCH_ICON;

        const [radioBtnStatus, changeRadioBtnStatus] =  useState(localStorage.getItem('searchBy') === 'mun' ? "mun": "street");
        const [radioBtnPHTxt, setRadioBtnPHTxt] =  useState(placeHolderText);
        const [localSearchArea, setLocalSearchArea] = useState({...DEFAULT_SELECTED_AREA})
        const [localSearchLevel, setLocalSearchLevel] = useState(SEARCH_LEVELS.HOUSE)

        const [currentMun, setCurrentMun] = useState('');
        const [currentSt, setCurrentSt] = useState('');

        const [availableAddresses, setAvailableAddresses] = useState([]);
        const [startFindingSearchedAreas, setStartFindingSearchedAreas] = useState(false)
        const [clickOnInput, setClickOnInput] = useState(false);
        const [radioBtnChange, setRadioBtnChange] = useState(localStorage.getItem('searchBy') === 'mun' ? "municipality": "street");
        const [submitAddress, setSubmitAddress] = useState(null)
        let searchTextTimer, timeoutVal = 500;
    
        const dispatch = useDispatch()
        const location = useLocation()
    
        const selectedAreaInStore = useSelector(state=> state.map.selectedArea)
        const searchLevel = useSelector(state=> state.map.searchLevel)
        const checkAuth = document.cookie.indexOf('access_token') == -1 ? false : true;
        const {
            getRootProps,
            getInputProps,
            getListboxProps,
            getOptionProps,
            groupedOptions,
        } = useAutocomplete({
            id: 'use-autocomplete-demo',
            value: localSearchArea,
            onChange: ((event, newValue)=> {setClick(true); handleDropDownValueChange(newValue)}),
            options: availableAddresses,
            getOptionLabel: (option) => option.level === "municipality" ? option.municipalityName : (option.level === "street" || option.level === "house" || option.level === 2) ? `${option.streetName} ${option.houseNo}, ${option.postCode} ${option.municipalityName}` : option.streetName,
        });

    const handleRadioChange = (e) => {
        const {name} = e.target;
        localStorage.setItem('searchBy', name);

        if (name === "street")
            dispatch(setSearchLevel(SEARCH_LEVELS.STREET))
        else if (name === "mun")
            dispatch(setSearchLevel(SEARCH_LEVELS.MUNICIPALITY))

        if (radioBtnChange !== name){
            setRadioBtnChange('name');
            dispatch(setSelectedArea({}))
            setAvailableAddresses([])
        }
    }

    // do local changes if store updates
    useEffect(()=> {
        setLocalSearchArea(selectedAreaInStore)
        setLocalSearchLevel(searchLevel)
    }, [selectedAreaInStore, searchLevel])

    useEffect(()=> {
        const name = localStorage.getItem('searchBy')
        if (name === "street")
            dispatch(setSearchLevel(SEARCH_LEVELS.HOUSE))
        else if (name === "mun")
            dispatch(setSearchLevel(SEARCH_LEVELS.MUNICIPALITY))
    }, [localStorage.getItem('searchBy')])

    useEffect(()=> {
        if (
            selectedAreaInStore !== undefined &&
            startFindingSearchedAreas
        ) {
            const {postCode, streetName, houseNo, level, municipality} = extractAreaInfoFromString()
            ;(async () => {
                 try {
                    const result = await MapApiService.getSearchedAreas({
                        type: radioBtnStatus,
                        postCode,
                        municipality: currentMun !== '' && streetName === currentSt ? currentMun : municipality,
                        streetName,
                        houseNo,
                        level,
                        "searchLevel": searchLevel === SEARCH_LEVELS.STREET ? SEARCH_LEVELS.HOUSE : searchLevel
                    });
                    
                    let allData = [];

                    result.data.forEach((levelOneData)=> {
                        allData = allData.concat(levelOneData.areas.map(levelTwoData=> {
                            return {
                                ...DEFAULT_SELECTED_AREA,
                                ...levelTwoData,
                                level: levelOneData.level
                            }
                        }))
                    })
                    setAvailableAddresses(allData)
                 } catch (err) {
                    console.log(err)
                    setAvailableAddresses([])
                 }
             })()

            setStartFindingSearchedAreas(false)
         }
     }, [startFindingSearchedAreas, localSearchArea, localSearchLevel]);
    
    useEffect(() => {
        if (radioBtnStatus === 'street'){
            if (placeHolderText.includes("</")) {
                placeHolderText = placeHolderText.replace( /(<([^>]+)>)/ig, '');
            }
            setRadioBtnPHTxt(placeHolderText);
        }
        else {
            setRadioBtnPHTxt('Gemeente');
        }

    },[radioBtnStatus, placeHolderText]);

    const extractAreaInfoFromString = ()=> {
        let searchText = getInputProps().value.split(",");
        let splittedSearchedText = searchText[0].split(' ');

        let streetName = "";
        let houseNo = "";
        let postCode= '';
        let municipality= '';

        if (searchText[1]){
            let pCodeAndMunicipality = searchText[1].split(' ');

            /* 3 Scenarios for pCodeAndMunci...
             * const pCodeAndMunicipality = ['','postCode'];
             * const pCodeAndMunicipality = ['','postCode','municipality'];
             * const pCodeAndMunicipality = ['postCode'];
             * const pCodeAndMunicipality = ['postCode', 'municipality'];
            */
            if (pCodeAndMunicipality[0] === '') pCodeAndMunicipality.shift();

            let rest = [];
            [postCode, ...rest] = pCodeAndMunicipality;
            municipality = rest.join(' ');
         }

        if (!isNaN(splittedSearchedText[splittedSearchedText.length-1])) {
            streetName = splittedSearchedText.slice(0, splittedSearchedText.length-1).join(" ")
            houseNo = splittedSearchedText[splittedSearchedText.length-1]
        }else streetName = splittedSearchedText.join(" ");

        //The street will act as changed (in autocomplete)
        //if not a single character of the house number is entered
        //To rectify that operation, we need this variable.
        let isStreetNotChange = true;

        /*  Checking if the state variable for street name is
            changed or not. Comparing state variable with text
            value. If this is true, then refresh the value and
            search will be based on the street name, rather than
            house number. Which is the case in opposite case
            See the level number if statement below
        */

        if (streetName !== currentSt){
            isStreetNotChange = false;
            setCurrentSt(''); setCurrentMun('');
        }

        /* Search contains 4 parms street, houseNo, pCode and munici...
            * format: street houseNo, pCode muni
            * if only street is defined (default case), level = 0
            * if street and houseNo is defned, level = 1
            * if street, houseNo and postCode is provided, level = 2
        */

        let level = 0;
        if (postCode !== '') level = 2;
        else if (houseNo !== '' || isStreetNotChange) level = 1;

        if (municipality === "" && localSearchLevel === SEARCH_LEVELS.MUNICIPALITY) municipality = streetName;

        return {
            streetName,
            houseNo,
            postCode,
            municipality,
            level
        }
    }
     const handleSearchAreaKeyUp = (event)=> {
         if (event.keyCode===13) {
             handleSearchBarSubmit()
             return
         }
         clearTimeout(searchTextTimer)

         searchTextTimer = setTimeout(() => {
           let enteredValue = event.target.value;
           
           if (enteredValue !== "") { 
               // to not let entered text vanish from searchbar
               if (
                    !enteredValue.includes(localSearchArea.streetName)
                    || (localSearchLevel===SEARCH_LEVELS.MUNICIPALITY
                        && !enteredValue.includes(localSearchArea.municipalityName)
                        ) 
                    || enteredValue.length >= 1) {
                    if (!enteredValue.includes(",")) {
                        setLocalSearchArea(prevValue=> {
                           return {
                                ...DEFAULT_SELECTED_AREA,
                                streetName: enteredValue,
                                municipalityName: enteredValue
                           }
                        })
                    }
                    else {
                        setLocalSearchArea(prevValue=> {
                           return {
                                ...DEFAULT_SELECTED_AREA,
                                streetName: enteredValue,
                                municipalityName: ""
                           }
                        })
                    }
               }
               setStartFindingSearchedAreas(true)
           }
           else {
//               setLocalSearchArea(SEARCH_LEVELS.COMPLETE)
               setLocalSearchArea({...DEFAULT_SELECTED_AREA})
               setAvailableAddresses([])
           }}, timeoutVal)
     }

     const handleSearchAreaKeyPress = (event)=> {
       clearTimeout(searchTextTimer)
        if (availableAddresses.length && event.key !== " " && event.target.value.split(",").length === 1) {
            setAvailableAddresses([])
        }
       setStartFindingSearchedAreas(false)
   }
   
   const handleDropDownValueChange = (newValue)=> {
        if (newValue) {
            setCurrentMun(newValue?.municipalityName.replace(/ /g,'') || '');
            setCurrentSt(newValue?.streetName.replace(/ /g,'') || '');
            setLocalSearchArea(newValue)
            setLocalSearchLevel(newValue ? newValue?.level : localSearchLevel)
        }
   }

    const handleSearchBarSubmit = async ()=> {
        if (!localSearchArea.addressId.length && !localSearchArea.municipalityId.length)
            return
        const capitalize1st = (s) => s.charAt(0).toUpperCase() + s.slice(1);
        setClick(true);

        //Handling full text search
        const {municipalityName, streetName} = localSearchArea;

        if (streetName.includes(",") || localSearchArea.municipalityId !== "" || localSearchLevel === SEARCH_LEVELS.HOUSE || localSearchLevel === SEARCH_LEVELS.MUNICIPALITY){
            // If house level address selected from dropdown
            if (localSearchArea.level === SEARCH_LEVELS.HOUSE) {
                dispatch(setSelectedArea(localSearchArea))
                dispatch(setSearchLevel(SEARCH_LEVELS.HOUSE))
            }
            // If house level address written as it is
            else if (streetName.includes(',')){
                const [streetAndHouse, codeAndMuncipiality] = streetName.split(',');
                const [_streetName, houseNo] = streetAndHouse.split(' ');
                const [postCode, municipality] = codeAndMuncipiality.split(' ').filter(value => value !== '');
                if (availableAddresses.length !== 1 || availableAddresses[0].postCode !== postCode ||
                    availableAddresses[0].municipalityName !== capitalize1st(municipality) ||
                    availableAddresses[0].streetName !== capitalize1st(_streetName) || availableAddresses[0].houseNo !== houseNo) {
                    return
                    }

                dispatch(setSearchLevel(SEARCH_LEVELS.HOUSE));

                dispatch(setSelectedArea({
                    type: radioBtnStatus,
                    streetName:capitalize1st(_streetName) ,
                    municipalityName: capitalize1st(municipality),
                    searchLevel: SEARCH_LEVELS.HOUSE,
                    postCode,
                    addressId: availableAddresses[0].addressId,
                    streetId: availableAddresses[0].streetId,
                    houseNo,
                    level:2
                }));               
            }

            else{
                const result = await MapApiService.getSearchedAreas({
                    type: radioBtnStatus,
                    postCode:'',
                    municipality: capitalize1st(municipalityName),
                    streetName ,
                    houseNo:'',
                    level: 0,
                    searchLevel
                });

                const {municipalityId} = result.data['0'].areas['0'];
                dispatch(setSearchLevel('municipality'));
                
                dispatch(setSelectedArea({
                    streetName: "",
                    municipalityId,
                    postCode:"",
                    level: "municipality",
                    municipalityName: capitalize1st(municipalityName),
                    addressId:"",
                    streetId:"",
                    houseNo:""
                }));                
            }
        }
//        if (localSearchArea.addressId !== "" || localSearchArea.municipalityName !== "") {
//            setActiveView(+localStorage.getItem('userEmail'));
//        }

        if (location.pathname !== ROUTES.MAP_PAGE){
            if (checkAuth) {
                setActiveView(1)
            }
            else
                setActiveView(0)
        }
        searchBarClickHandler()
    }

    const handleInputClick = () => {
        setClickOnInput(true)
        if(handleArrow) handleArrow(true)
    }

    return (
        <div>
        <div className={`${inputCondition?'':'d-inline-flex'} search-bar position-relative`}>
            <div {...getRootProps()}>
                <input
                    type="text"
                    onClick={handleInputClick}
                    className={ inputCondition?`text-small sidepanel-input landing-input-size`:`landing-input text-small landing-input-size`}
                    onBlur={(event => event.preventDefault())}
                    onKeyUp={handleSearchAreaKeyUp}
                    onKeyPress={handleSearchAreaKeyPress}
                    placeholder={radioBtnPHTxt}
                    {...getInputProps()}
                />
            </div>
            {groupedOptions.length > 0 ? (
                <ul className={inputCondition?`listbox mt-0`:`listbox`} {...getListboxProps()}>
                  {groupedOptions.map((option, index) => (<>{console.log(option)}
                    <li className="small" {...getOptionProps({ option, index })}>{option.level === "municipality" ? option.municipalityName : option.level === "street" || option.level === "house" ? `${option.streetName} ${option.houseNo}, ${option.postCode} ${option.municipalityName}` : ""}</li>
                    </>
                  ))}
                </ul>
              ) : null}
            {searchBarType === SEARCH_BAR_TYPES.WITH_BUTTON ?
                <span className="input-group-btn">
                    <button
                        className="landing-btn"
                        onClick={handleSearchBarSubmit}
                    >
                        {Parser(searchBarBtnText)}
                    </button>
                </span>
                :
                <span>
                    <img
                        className='pointer position-absolute sidepanel-search'
                        src={search}
                        alt="eye"
                        width="20px"
                        height="20px"
                        onClick={handleSearchBarSubmit}
                    />
                </span>
            }
        </div>
        {clickOnInput &&
                <div
                    className={landing? 'search-radio-btn-landing': 'search-radio-btn-sidepanel mt-3'}
                    // style={{background: window.location.pathname === '/' ? '': 'rgba(255,255,255,0.6)'}}
                >
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className="form-check">
                        {/* <input
                            className="form-check-input"
                            type="radio"
                            onClick={handleRadioChange}
                            name="street"
                            id="flexRadioDefault1"
                            onChange={()=> {changeRadioBtnStatus('street')}}
                            checked={radioBtnStatus === 'street'}
                        />
                        <label className="form-check-label" for="flexRadioDefault1">
                            Straat
                        </label> */}
                        <FormControlLabel
                            key={'flexRadioDefault1'}
                            // className="ps-4"
                            control={
                                <CustomRadio
                                    id="flexRadioDefault1"
                                    checked={radioBtnStatus === 'street'}
                                    onClick={handleRadioChange}
                                    onChange={()=> {changeRadioBtnStatus('street')}}
                                    name="street"
                                />
                            }
                            label={'Straat'}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="form-check">
                        <FormControlLabel
                            key={'flexRadioDefault1'}
                            // className="ps-4"
                            control={
                                <CustomRadio
                                    id="flexRadioDefault2"
                                    checked={radioBtnStatus === 'mun'}
                                    onClick={handleRadioChange}
                                    onChange={()=> {changeRadioBtnStatus('mun')}}
                                    name="mun"
                                />
                            }
                            label={'Gemeente'}
                        />
                    </div>
            </Grid>
                </Grid>
                </div>
        }
        </div>
    )
}  