import axios from "axios";
import {
    addNewSimulation,
    allFlandersMapDetails, allFlandersMapDetailsPrivate, GEOSERVER_STORE,
    houseLevelMapDetails, mapDetailsOfRequestedArea,
    mapDetailsOfClickedArea,
    municipalityMapDetails,
    searchAreas, updateSimulation
} from "../redux/constants/ApiEndPoints";
import {getCookieItem} from "../helper/requestHelpers";
import {ApiService} from "./ApiService";
import {MAP_LAYERS} from "../constants/map";


class MapAPIService extends ApiService{
   
    async getSearchedAreas(query) {
        const searchedAreas = await this.apiWrapper("Could not get searched Areas", searchAreas, "post", false, query);
        const arr = [];

        searchedAreas.data[0].areas = [...searchedAreas?.data[0]?.areas.filter(area => {
            const {
                streetName,
                houseNo,
                streetId,
                postCode,
                municipalityName
            } = area;

            const entry = 
                `${streetName} ${houseNo} ${streetId} ${postCode} ${municipalityName}`

            const doesNotHave = !arr.includes(entry);
            
            if (doesNotHave) arr.push(entry)
            
            return doesNotHave;
        })];

        return searchedAreas;
    }

    async getMunicipalityDetails(municipalityId, privateUser=false) {
        return await this.apiWrapper("Could not get municipality details", municipalityMapDetails, "post",
            privateUser===true, {municipalityId: municipalityId})
    }

    async getAllFlandersMap(privateUser=false) {
        return await this.apiWrapper("Could not get flanders maps details", privateUser === true ? allFlandersMapDetailsPrivate : allFlandersMapDetails, "get",
            privateUser===true)
    }

    async getHouseLevelMap(addressId, privateUser=false) {
        return await this.apiWrapper("Could not get Address details", houseLevelMapDetails,
            "post", privateUser===true, {addressId: addressId})
    }
// : layerType === MAP_LAYERS.HEAT_DEMAND ? `${GEOSERVER_STORE}:streetsDemandView` : layerType === MAP_LAYERS.COLLECTIVE_HEAT_MAPPING ? `${GEOSERVER_STORE}:streetsHeatProbView` : `${GEOSERVER_STORE}:Municipality`}
    async getRequestedMapArea(bounds=null, mapLayer=`${GEOSERVER_STORE}:streetsDemandView`, geomColumn="geom", cql=null, type="geojson", returnUrl=false, epsgCode=4326) {
        let apiUrl = `${mapDetailsOfRequestedArea}&typeName=${mapLayer}`
        if (type === "geojson")
            apiUrl += "&outputFormat=application/json"
        else
            apiUrl += "&outputFormat=shape-zip"

        let localCql = "&CQL_FILTER="
        if (bounds)
            localCql += `BBOX(${geomColumn}, ${bounds._southWest.lat},${bounds._southWest.lng},${bounds._northEast.lat},${bounds._northEast.lng}, 'urn:x-ogc:def:crs:EPSG:4326')`
        else if (cql) {
            localCql += cql
        }
        apiUrl += (bounds || cql ? localCql : "") + `&srsName=urn:x-ogc:def:crs:EPSG:${epsgCode}`

        if (returnUrl)
            return apiUrl

        return await this.apiWrapper("Could not get requested area map",
            apiUrl,
            "get", false, {}, true)
    };
    async getClickedMapArea(x, y, bounds=null, mapLayer=`${GEOSERVER_STORE}:clusterout_case_1`, mapWidth=500, mapHeight=500, bufferAroundClick=5, cql=null) {
        let apiUrl = `${mapDetailsOfClickedArea}&width=${mapWidth}&height=${mapHeight}&query_layers=${mapLayer}&layers=${mapLayer}&TYPENAME=${mapLayer}&x=${x}&y=${y}&exceptions=application/json`
        let localCql = ""
        if (bounds)
            apiUrl += `&buffer=${bufferAroundClick}&bbox=${bounds._southWest.lat},${bounds._southWest.lng},${bounds._northEast.lat},${bounds._northEast.lng}`
        if (cql) {
            if (bounds)
                localCql += ";"
            localCql += cql
        }
        return await this.apiWrapper("Could not get requested area map",
            apiUrl + localCql,
            "get", false, {}, true)
    };
}

export default new MapAPIService()