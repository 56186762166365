/**
 * validates an object for emptiness and types
 * @param values: Json object to be validated
 * @param validations: {type: <expectedType>, required: <boolean>}
 */
export const validateObject = (values, validations) => {
    let errors = []
    for (let key of Object.keys(values)) {
        const value = values[key]
        const type = validations[key].type
        if (["", null, undefined].includes(value) && validations[key].required)
            errors.push(`Attribute ${validations[key].title || key} is required`)
        if (typeof values[key] !== type && !["", null, undefined].includes(value))
            errors.push(`Attribute ${validations[key].title || key} must be of type ${type}`)
    }
    return errors
}
