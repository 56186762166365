import React from "react";
import MainRouter from "./router/router";
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';
import 'bootstrap/dist/css/bootstrap.css';
import 'leaflet/dist/leaflet.css';
import '../src/scss/warmte/index.scss';
import ComingSoon from './components/pages/comingSoon';
require('dotenv').config()

function App() {
  return (
  <Provider store={store} >
     <MainRouter />
  </Provider>
  );
}

export default App;


