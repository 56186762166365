import { USER_VIEW, CHECK_EMAIL } from '../constants/constants';
const INIT_STATE = {
    active_view:null,
    check_email:null
}
const content= (state = INIT_STATE, action) => {
    switch(action.type) {
        case USER_VIEW:
            return {
                ...state,
                active_view: action.payload
            }
        case CHECK_EMAIL:
            return {
                ...state,
                check_email: action.payload
            }
        default:
            return state;
    }
}

export default content;