import {
    ZONING_MUNICIPALITY,
    ZONING_MUNICIPALITY_GEOMETRY,
    ZONING_MUNICIPALITY_GEOMETRY_LOADING
} from '../constants/constants';
const INIT_STATE = {
    all_municipalities:[],
    municipalities_geometry: null,
    municipalities_loading: false
}
const zoning = (state = INIT_STATE, action) => {
    switch(action.type) {
        case ZONING_MUNICIPALITY:
            return {
                ...state,
                all_municipalities: action.payload
            }
        case ZONING_MUNICIPALITY_GEOMETRY:
            return {
                ...state,
                municipalities_geometry: action.payload
            }
        case ZONING_MUNICIPALITY_GEOMETRY_LOADING:
            return {
                ...state,
                municipalities_loading: action.payload
            }
        default:
            return state;
    }
}

export default zoning;