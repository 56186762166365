import React from 'react';
import {CloseableCard} from "../../sharedComponents/closableCard";
import {Grid} from '@material-ui/core';
import "../../../css/map.css";
import {connect} from 'react-redux';
import {MultiColorBar} from "../../sharedComponents/multiColorBar";
import {GradientBar} from "../../sharedComponents/gradientBar";
import parse from 'html-react-parser';
import {COLLECTIVE_HEATING_PROBABILITY, HEAT_DEMAND_COLORS} from "../../../constants/map";
import {getContentByModuleLocation} from '../../../redux/actions/content'

const AnalysisMapInfoCard = ({
                                 onCloseClicked = () => {
                                 },
                                 width = "60",
                                 height = "80",
                                 bannerContent,
                                 auth
                             }) => {


    if (bannerContent) {
        var {
            section1Title,
            section2Title,
            section3Title,
            section3HeatSupplyText,
            section1Text,
            section2Text,
            section3Text
        } = {
            ...bannerContent?.section1.attributes,
            ...bannerContent?.section2.attributes,
            ...bannerContent?.section3.attributes
        };
    }

    const highlight = location.pathname.includes('map')
    const rightSideInfo = [
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.'
    ]
    return (
        <Grid container direction="row"
              justifyContent="center"
              alignItems="center"
        >
            <Grid item xs={8}>
                <CloseableCard
                    title={section1Title}
                    width={width}
                    height={height}
                    onClose={onCloseClicked}
                >
                    <Grid
                        container
                        alignContent="flex-start"
                        className="pt-2"
                    >
                        <div className="h-full col-12 pl-0">
                    <span className='fs-12 clr-text d-block'>
                        {parse(`${section1Text}`)}
                    </span><br/>
                            <span className='fs-15 bold d-block pb-3'>
                        {section2Title}
                    </span>
                            <MultiColorBar
                                colors={[
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[1], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[2], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[3], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[4], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[5], percentage: 20}
                                ]}
                                labels={[
                                    "Collectief", "Onbeslist", "Individueel"
                                ]}
                            />
                            <br/>
                            <span className='fs-12 clr-text d-block'>
                        {parse(`${section2Text}`)}
                    </span>
                            <span className='fs-15 bold pt-4 position-relative d-block mb-3'>
                        {section3Title}
                    </span>
                            <GradientBar
                                leftColor={HEAT_DEMAND_COLORS.left}
                                rightColor={HEAT_DEMAND_COLORS.right}
                                radius={'30'}
                            />
                            <span className="fs-12 clr-text pt-4 d-block">
                        {parse(`${section3Text}`)}
                    </span>

                            {auth.active_view == 1 && highlight ?
                                <>
                                    <br/>
                                    <span className='fs-15 bold'>
                            Warmtebronnen
                        </span>
                                    <span className="fs-12 pt-3 clr-text d-block">
                            In de simulatieomgeving is het mogelijk om warmtebronnen als puntbronnen toe te voegen.
                            Merk daarbij op dat in het zoneringsalgoritme geen expliciete rekening wordt gehouden met
                            de aanwezigheid van (potentiële) bronnen. Deze berekening gebeurt puur vanuit de warmtevraag. 
                            Voor een zoekzone die als ‘collectief’ wordt ingekleurd zal de aanwezigheid van warmtebronnen 
                            echter een cruciale factor zijn in de effectieve slaagkansen van een warmtenet. Het identificeren 
                            van (potentiële) warmtebronnen zal daarom een belangrijke vervolgstap zijn. Met deze feature laten
                            we alvast toe om geïdentificeerde warmtebronnen mee te visualiseren op kaart en om een aantal 
                            relevante eigenschappen te documenteren.

                        </span><br/>

                                    <span className='fs-15 bold'>
                            Renovatiegraad
                        </span>
                                    <span className="fs-12 pt-3 clr-text d-block">
                            Bij het opstellen van de inspiratiekaart warmte is uitgegaan van één centraal renovatiescenario, 
                            in lijn met de LTRD van de Vlaamse overheid. Aangezien de rendabiliteit van collectieve systemen,
                            zoals warmtenetten, afneemt naarmate de warmtevraagdensiteit daalt, heeft deze renovatiegraad 
                            uiteraard een belangrijke invloed op de uiteindelijke zonering. De gesimuleerde warmtevraag voor
                            2050 kan daarom geïnterpreteerd worden als een best-case scenario in termen van energiebesparing
                            aangezien in dit scenario alle gebouwen voldoen aan de opgelegde doelstellingen voor 2050. 
                            In tegenstelling kan de huidige toestand die als worst-case gezien kan worden, aangezien zelfs
                            bij business as usual renovaties ervoor zullen zorgen dat de warmtevraag zal dalen. Bijkomend 
                            zijn nog 2 tussenliggende scenario’s berekend waarbij respectievelijk 33% en 66% van het 
                            renovatiepotentieel werd gerealiseerd. Deze kunnen dus geïnterpreteerd worden als een 2030 en
                            2040 scenario, uitgaande van een constante renovatiegraad. <br/>
                            In de simulatieomgeving kan – afzonderlijk voor residentiële en niet-residentiële gebouwen
                            – de renovatiegraad worden ingesteld volgens de scenario’s 2020-2030-2040-2050. Door deze 
                            parameters te wijzigen kan bvb. de impact van het verwachtte succes van bepaalde beleidskeuzes
                            worden ingeschat.


                        </span><br/>
                                    <span className='fs-15 bold'>
                            Warmtenetkosten
                        </span>
                                    <span className="fs-12 pt-3 clr-text d-block">
                            In het zoneringsalgoritme wordt via een vereenvoudigd economisch model de systeemkost van een
                            collectieve oplossing – op basis van warmtenetten – vergeleken met de kostprijs van individuele
                            oplossingen. In die berekening zijn 2 parameters belangrijk:<br/>
                            De aansluitkost: dit is de kost van leidingen en aansluitingen die gemaakt moet worden om de 
                            gebouwen in dat straatsegment aan te sluiten op een warmtenet. Standaard (medium) wordt deze kost
                            berekend in functie van het aansluitvermogen en de afstand tussen de gebouwen op basis van gemiddelde
                            waarden uit praktijkstudies. In het ‘laag’ en ‘hoog’ scenario worden deze kosten respectievelijk 
                            verlaagd en verhoogd met 10%.<br/>
                            De warmteprijs: deze fictieve prijs in €/MWh voor de warmteproductie. Standaard wordt hier gewerkt 
                            met 25€/MWh. In het ‘laag’ scenario met 10€/MWh, overeenkomstig een goedkope restwarmtebron. 
                            In het ‘hoog’ scenario met 50€/MWh, overeenkomstig een collectief warmtepomp systeem.<br/>
                            Merk op dat op indirecte manier, deze parameters ook kunnen gebruikt worden om de impact van 
                            prijsevoluties in technologieën of in elektriciteitsprijs (voor individuele warmtepompen) te evalueren.
                        </span>
                                </>
                                : ''}
                        </div>
                    </Grid>
                </CloseableCard>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({content, auth}) => ({content, auth})

export default connect(mapStateToProps, {getContentByModuleLocation})(AnalysisMapInfoCard);
