import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import contain3  from '../../assets/line-image.svg';

function Containers({containerContent}) {
    const [ collectief, setCollectief] = useState([])
    const [imgs, setImg]= useState({});
    const getActiveImg = ({attributes}) => {
        try {
            return Object.values(
                attributes.img
            ).filter(
                img => img.is_active
            )[0].attribute_value
        }
        catch (err) {
            console.log("error is ", err)
            return ""
        }
    }

    useEffect(()=>{   
    if (containerContent){    
        setImg({
            img2: getActiveImg(containerContent.section2),
            img3: getActiveImg(containerContent.section3),
        });
        
        setCollectief([
            { img:getActiveImg(containerContent.section5a), heading:containerContent?.section5a?.attributes.title, btn:containerContent?.section5a?.attributes?.urlText, para:containerContent?.section5a?.attributes.text},
            { img:getActiveImg(containerContent.section5b), heading:containerContent?.section5b?.attributes.title, btn:containerContent?.section5b?.attributes?.urlText, para:containerContent?.section5b?.attributes.text},
            { img:getActiveImg(containerContent.section5c), heading:containerContent?.section5c?.attributes.title, btn:containerContent?.section5c?.attributes?.urlText, para:containerContent?.section5c?.attributes.text}
        ])
    }
    },[containerContent])
 
if (containerContent)
   return (
      <Grid container>
        <Grid container className='pb-4' >
            <Grid item xs={12} sm={3} md={2} lg={2}>
                <div className='container-line-img'>
                    <img src={contain3} alt='image' style={{width:'100%'}} />
                </div>
            </Grid>
            <Grid item xs={12} sm={9} md={10} lg={10} className='mt-5 pl-5'>
                <h5 className='container-heading'> {parse(`${containerContent?.section1?.attributes.title}`)}</h5>
                <div className='pr-5'>
                    <span className='container-content'>
                    {parse(`${containerContent?.section1?.attributes.text}`)}
                    </span>
                </div>
            </Grid>
        </Grid>

        <Grid container className='mt-5 pt-4 pb-5 conatiner-bg-grey'>
            <Grid container >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <img src={imgs.img2} alt='image' className='container-image' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className='position-relative'>
                    <div className='pt-5 pl-5 pr-5 pb-5 '>
                        <h5 className='container-heading'> {parse(`${containerContent?.section2?.attributes.title}`)}</h5>
                        <span className='container-content'>
                            {parse(`${containerContent?.section2?.attributes.text}`)}
                        </span><br/><br/>
                        <button 
                        className='container-content-btn'
                        onClick={()=>window.open('http://www.energiesparen.be/warmtegids','_blank')}
                        >
                            {containerContent?.section2?.attributes?.buttonText}
                        </button>
                    </div>
                </Grid>
            </Grid>
        </Grid>

        <Grid container>
            <Grid container className='pt-3 pb-3'>
                <Grid item xs={12} sm={12} md={6} lg={6} className='position-relative p-5'>
                    <div className=''>
                        <h5 className='container-heading'> {parse(`${containerContent?.section3?.attributes.title}`)}</h5>
                        <span className='container-content'>
                            {parse(`${containerContent?.section3?.attributes.text}`)}
                        </span><br/><br/>
                        <button
                        className='container-content-btn float-right mr-2' 
                        onClick={()=>window.open('http://www.energiesparen.be/warmtegids','_blank')}
                        >
                            {parse(containerContent?.section3?.attributes.buttonText)}
                        </button>
                    </div>
                </Grid>
                <Grid item xs={8} md={6} lg={6}>
                    <img src={imgs.img3} alt='image' className='container-image' />
                </Grid>
            </Grid>
        </Grid>

        <Grid 
            container 
            className='pb-4' 
            id='Zie' 
            style={{backgroundColor:'#70208208'}}
            justifyContent='center'
        >
            <span style={{visibility:'hidden'}}>''</span>
            <h5 className='text-center container-heading pt-5'>
                {parse(containerContent?.section5?.attributes.mainTitle)}
            </h5>
            <Grid container justifyContent='space-around'>{
                collectief.map((obj, index)=>(
                    <Grid key={index} item xs={8} md={3} lg={3}>
                        <div className='text-center circled-border'>
                            <div className='p-5'>
                                <img src={obj.img} alt='image' className='container-grid-img' />
                                <h4 className='container-grid-head pt-3'>{parse(`${obj.heading}`)}</h4>
                                {obj.para && 
                                <>
                                    <span className='container-grid-para'>
                                        {parse(`${obj.para}`)}
                                    </span><br/>
                                </>
                                }
                                <HashLink to={`/info-page#info${index+1}`}>
                                    <span className='container-grid-btn'>
                                        Meer info
                                    </span>
                                </HashLink>
                            </div>
                        </div>
                    </Grid>
                ))
           }</Grid>
        </Grid>

      </Grid>
   );
   return <></>
}

const mapStateToProps = ({content,loader}) => ({content,loader})
export default connect(mapStateToProps,null) ( Containers );
