import React from "react";
import { Link } from "react-router-dom";

const Error403 = () => {
   return (
      <div className="errors-container">
         <div className="authincation h-100 p-meddle">
            <div className="container h-100">
               <div className="row justify-content-center h-100 align-items-center ">
                  <div className="col-md-5">
                     <div className="form-input-content text-center error-page">
                        <h1 className="error-text  font-weight-bold">403</h1>
                        <h4>
                           <i className="fa fa-times-circle text-danger" />{" "}
                           Verboden fout!
                        </h4>
                        <p>U heeft geen toestemming om deze bron te bekijken.</p>
                        <div>
                           <Link className="btn btn-success" to="/">
                              Terug naar huis
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Error403;
