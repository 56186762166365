import {COST_MAPPING, RENOVATION_LEVEL_TO_YEAR, HEAT_STATES} from "../constants/map";


export const getCookieItem = (attrName)=> {
    let nameInCookie = attrName + "=";
    let CookieToSearch = decodeURIComponent(document.cookie);
    let splittedCookieItems = CookieToSearch.split(';');
    for(let i = 0; i <splittedCookieItems.length; i++) {
        let currentCookieItem = splittedCookieItems[i];
        while (currentCookieItem.charAt(0) == ' ') {
            currentCookieItem = currentCookieItem.substring(1);
        }
        if (currentCookieItem.indexOf(nameInCookie) == 0) {
            return currentCookieItem.substring(nameInCookie.length, currentCookieItem.length);
        }
    }
    return "";
}

export const prepareDataForSimulationReq = (simData, heatDemands=[], save=false, simName=null)=> {
    const newDemands = []
    for (const demand of heatDemands) {
        if (save && (demand.addition_status !== HEAT_STATES.SUBMITTED || simName !== simData.simulation_name)) {
            newDemands.push({...demand, prev_addition_status: demand.addition_status, addition_status: HEAT_STATES.SUBMITTED})
        }
        else if (!save){
            newDemands.push({...demand, prev_addition_status: demand.addition_status, addition_status: demand.addition_status === HEAT_STATES.ADDED ? HEAT_STATES.CALCULATED : demand.addition_status})
        }
    }
    let requestData = {}
    if (simData.simulation_saved_id && save)
        requestData["id"] = simData.simulation_saved_id
    if (save) {
        requestData["simulation_state"] = "saved"
        requestData["name"] = simName
    }
    else
        requestData["simulation_state"] = "unsaved"
    requestData["map_view"] = simData["map_view"]
    requestData["new_heat_demands"] = newDemands
    requestData["price"] = COST_MAPPING[simData.price]
    requestData["connection_cost"] = COST_MAPPING[simData.connection_cost]
    requestData["renovation_level"] = {
        res: RENOVATION_LEVEL_TO_YEAR[simData.renovation_level.res],
        nres: RENOVATION_LEVEL_TO_YEAR[simData.renovation_level.nres]
    }

    return requestData
}

export const getAuthenticationErrors = (errorsList)=> {
    const emailErrors = []
    const passwordErrors = []
    for (const error of errorsList) {
        if (error.includes('EMAIL') || error.includes('e-mailadres')) {
            emailErrors.push(error)
        }
        else {
            passwordErrors.push(error)
        }
    }
    return {emailErrors, passwordErrors}
}
