import React,{ useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom"
import { Grid  } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {ROUTES} from "../../constants/routes";
import user from '../../assets/LoggedUser.svg';
import hamberger from "../../assets/hamberger.png"
import {setSelectedMapLayer} from '../../redux/actions/map';
import {MAP_LAYERS} from "../../constants/map";
import {activeView, refreshToken, userLogout} from '../../redux/actions/auth';
import {getContentByModuleLocation} from '../../redux/actions/content';

function Header(props) {
   const location = useLocation();
   const history = useHistory();
   const highlight = location.pathname.includes('map')
   let checkAuth = document.cookie.indexOf('access_token') == -1 ? false : true;
   let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)refresh_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
   const smallScreen = useMediaQuery('(max-width: 800px)')

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };
   let path = location.pathname;
   let currentTime = new Date()
   let expireTime = localStorage.getItem('expireTime')
   let refresh = localStorage.getItem('refresh') !='null' && localStorage.getItem('refresh') === 'true';

   const [ active, setActive ] = useState(false)
   const [header, setHeader] = useState(undefined);

   if(refresh && currentTime.getTime() + 60000 > expireTime)
   {
      localStorage.setItem('refresh',false)
      props.refreshToken(cookieValue)
   }

   useEffect(() => {
      let view = localStorage.getItem('userView');
      if(view && view != 'null') props.activeView(view);
      
      //Load data in local state
      async function fetchHeader() {
         const headerContent = await props.getContentByModuleLocation('top');
         setHeader({...headerContent?.header?.attributes});
      }

      fetchHeader();
   }, [])

   useEffect(() => {
      console.log('view!',props.auth.active_view);
   }, [props.auth.active_view])

   const homepage=()=>{
      props.activeView(2);
      history.push(ROUTES.HOME_PAGE);
      window.scrollTo({top: 0, behavior: 'smooth'});
   }
   const mounted = useRef();

   const redirectToMap = () => {
      if (window.location.pathname === '/map'){
         if (localStorage.getItem('userEmail')){
            props.activeView(1);
         }else{
            props.activeView(0);
         }
      }
      if (window.location.pathname === '/'){
         props.activeView(2);
      }
   }

   useEffect(() => {
      if (!mounted.current) {
        // do componentDidMount logic
        mounted.current = true;
      } else {
         window.onpopstate = e => {
            redirectToMap()
         }
      }
      // redirectToMap();
    });

    
   const userView =(view)=> {
      setActive(true)
      if(props.auth.active_view == 1 &&
         view == 0 &&
         props.map.selectedMapLayer != MAP_LAYERS.HEAT_DEMAND ) {
         props.setSelectedMapLayer(MAP_LAYERS.COLLECTIVE_HEAT_MAPPING)
         props.activeView(view);
      }
      if(props.auth.active_view != view) props.activeView(view);

//      if (checkAuth)
//         history.push(`${ROUTES.MAP_PAGE}?`+`${localStorage.getItem('queryMap')}`)
//      else
     history.push(ROUTES.MAP_PAGE)

//      if(view == 0 && localStorage.getItem('firstTime') )
//         history.push(`${ROUTES.MAP_PAGE}?`+`${localStorage.getItem('queryMapInspirational')}`)
//      else {
//         localStorage.setItem('firstTime',false)
//         history.push(ROUTES.MAP_PAGE)
//      }

//      if(view == 1 && localStorage.getItem('queryMapSimulation'))
//         history.push(`${ROUTES.MAP_PAGE}?`+`${localStorage.getItem('queryMapSimulation')}`)
//      else
//         history.push(ROUTES.MAP_PAGE)
   }

   const logoutUser =()=>
   {
      localStorage.clear();
      props.userLogout();

      document.cookie.split(";").forEach((c) => {
         document.cookie = c
           .replace(/^ +/, "")
           .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
       });
      history.push('/login');
      window.location.reload(true)
   }

   const clickLogin = ()=>{
      if(location.pathname == ROUTES.LOGIN_PAGE) console.log('')
      else history.push(ROUTES.LOGIN_PAGE);
   }

   return (
      <>
      <Grid container className='header-main pt-2 pb-2'>
         <Grid item xs={10} sm={7} lg={6} className={smallScreen ? 'pl-2' : 'pl-3'}>
            <div className='float-left'>
               <h1 className={`d-inline-flex header-left ${smallScreen ? 'header-small-screens' : ''}`} onClick={()=>homepage()}>{header?.headerLeft}</h1>
               <h2 className={`d-inline-flex header-right pl-2 ${smallScreen ? 'header-small-screens' : ''}`} onClick={()=>homepage()}>{header?.headerLeft2}</h2>
           </div>
         </Grid>
         <Grid item xs={2} sm={5} lg={6} className={smallScreen ? 'pr-2 header-small-screens' : 'pr-5'}>
            <div className={smallScreen ? '' : 'pt-2 ' + 'float-right '}>
               {
                  !smallScreen &&
                  <span className=
                            {`pr-4 pointer header-second ${
                                props.auth.active_view == 0 && highlight ? 'highlight' : ''}`}
                        onClick={() => userView(0)}
                  >
                  {header?.headerLeft}
               </span>
               }
               <>
                  {
                     (!smallScreen && checkAuth) &&
                     <span className=
                               {`header-second pointer mr-4 ${
                                   props.auth.active_view == 1 && highlight ? 'highlight' : ''}`}
                           onClick={() => userView(1)}
                     >
                  {header?.headerRightSimulatie}
                  </span>
                  }
                  <Tooltip title="Account settings">
                     <IconButton onClick={handleClick} className="float-right" size="small" sx={{ml: 2}}>
                        {
                           smallScreen && !checkAuth ?
                           <img
                               src={hamberger}
                               alt="eye"
                               width="25px"
                               height="25px"
                           />
                           :
                               checkAuth &&
                           <img
                               src={user}
                               alt="eye"
                               width="25px"
                               height="25px"
                           />
                        }
                     </IconButton>
                  </Tooltip>
                  <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                         elevation: 0,
                         sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 3.5,
                            '& .MuiAvatar-root': {
                               width: 32,
                               height: 32,
                               ml: -0.5,
                               mr: 1,
                            },
                            '&:before': {
                               content: '""',
                               display: 'block',
                               position: 'absolute',
                               top: 60,
                               right: 14,
                               width: 10,
                               height: 10,
                               bgcolor: 'background.paper',
                               transform: 'translateY(-50%) rotate(45deg)',
                               zIndex: 0,
                            },
                         },
                      }}
                      transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
                  >
                     {
                        smallScreen && !checkAuth && (
                            <MenuItem
                                onClick={clickLogin}
                            >
                               {header?.headerRightLogin}
                            </MenuItem>
                        )
                     }
                     {
                        smallScreen && (
                            <div>
                               <MenuItem
                                   onClick={()=>userView(0)}
                               >
                                  {header?.headerLeft}
                               </MenuItem>
                               {
                                  checkAuth &&
                                  <MenuItem
                                      onClick={() => userView(1)}
                                  >
                                     {header?.headerRightSimulatie}
                                  </MenuItem>

                               }
                            </div>
                        )
                     }
                     {
                        checkAuth &&
                        <div>
                           <MenuItem
                               onClick={() => history.push(ROUTES.SIMULATION_PAGE)}
                           >
                              Simulatieoverzicht
                           </MenuItem>
                           <MenuItem
                               onClick={() => history.push(ROUTES.CHANGE_PASS)}
                           >
                              Wachtwoord wijzigen
                           </MenuItem>
                           <MenuItem
                               onClick={() => history.push(ROUTES.ZONING_PLAN)}
                           >
                              Warmtezoneringsplan
                           </MenuItem>
                           <MenuItem
                               onClick={logoutUser}
                           >
                              Uitloggen
                           </MenuItem>
                        </div>
                     }
                     <Divider/>
                  </Menu>
               </>
               {
                  (!checkAuth && !smallScreen) &&
                  <span
                      className='header-login'
                      onClick={clickLogin}
                      style={{
                         background:
                             props.auth.active_view === 3 ? 'var(--green)' : 'var(--white)',
                         color:
                             props.auth.active_view === 3 ? 'var(--white)' : 'var(--green)'
                      }}
                  >
                  {header?.headerRightLogin}</span>
               }
           </div>
         </Grid>
      </Grid>
      </>
   )
}

const mapStateToProps = ({auth,content,loader,map}) => ({auth,content,loader,map})
export default connect(mapStateToProps,{ 
refreshToken,
activeView,
userLogout,
getContentByModuleLocation,
setSelectedMapLayer,
}) 
( Header );
