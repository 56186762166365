import L from "leaflet";
import { useEffect } from "react";
import {PopupForm} from "../../sharedComponents/popupForm";
import {useSelector, useDispatch} from "react-redux";
import mapApiService from "../../../services/mapApiService"
import {setSimulationParams, setSimulationStatus} from "../../../redux/actions/simulation";
import {setSelectedCluster, setSelectedClusterGeoJSON, setSelectedClusterType} from "../../../redux/actions/map";
import { setViabilityState } from "../../../redux/actions/economicViability";
import { SIMULATION_STATUS } from "../../../constants/map"
import {ECONOMIC_VIABILITY_STATES, FAULTY_CLUSTERS} from "../../../constants/economicViability"
import {valueExistsInListOfObjects} from "../../../helper/generalHelpers";


export const SimulationController = ({
    openNameForm=false,
    closeNameFormHandler= ()=> {},
    submitSimulationName= ()=> {},
    map = null,
    clusterMapLayer = null,
    simulationCollectiveLayer,
    activeCollectiveLayers,
    showPopupMessageOnMap
                              })=> {

    const simulationParams = useSelector(state=> state.map.simulationParams)
    const simulationStatus = useSelector(state=> state.map.simulationStatus)
    const simulationDetails = useSelector(state=> state.map.selectedSimulationDetails.simulationData)
    const selectedClusterInStore = useSelector(state=> state.map.selectedCluster)
    const selectedClusterGeoJSONInStore = useSelector(state=> state.map.selectedClusterInGeoJSON)
    const economicViabilityState = useSelector(state=> state.economicViability.selected_state)
    const dispatch = useDispatch()

    useEffect(()=> {
        if (simulationStatus === SIMULATION_STATUS.CALCULATED)
            dispatch(setSimulationStatus(SIMULATION_STATUS.TOUCHED_AFTER_CALCULATION))
        else if (simulationStatus === SIMULATION_STATUS.SAVED)
            dispatch(setSimulationStatus(SIMULATION_STATUS.TOUCHED_AFTER_SAVING))
    }, [simulationParams.simulation_id, simulationParams.price, simulationParams.connection_cost, simulationParams.renovation_level])

    const handleSubmitSimulationName = (newFieldsValues)=> {
        if (newFieldsValues[0] && newFieldsValues[0] !== "") {
            submitSimulationName(newFieldsValues[0], newFieldsValues[0] !== simulationParams.simulation_name)
            dispatch(setSimulationParams({
                simulation_name: newFieldsValues[0]
            }))
        }
    }
    useEffect(()=> {
        if (map && !map._container.classList.contains("pointer-on-map") && activeCollectiveLayers && activeCollectiveLayers.length && economicViabilityState === ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP) {
            map.on('click', handleMapClickForCluster)
            L.DomUtil.addClass(map._container,"pointer-on-map");
            showPopupMessageOnMap("Je kan nu een cluster selecteren door op de map te klikken")
        }
        else if (map && economicViabilityState !== ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP) {
            map.off('click')
            L.DomUtil.removeClass(map._container,"pointer-on-map");
        }
    }, [map, activeCollectiveLayers, economicViabilityState])

    const handleMapClickForCluster = async (event)=> {
        const bds = map.getBounds();
        const mapSize = map.getSize();
        const w = bds.getNorthEast().lng - bds.getSouthWest().lng;
        const h = bds.getNorthEast().lat - bds.getSouthWest().lat;
        const screenPtX= (((event.latlng.lng - bds.getSouthWest().lng) / w) * mapSize.x).toFixed(0);
        const screenPtY = (((bds.getNorthEast().lat - event.latlng.lat) / h) * mapSize.y).toFixed(0);
        const res = await mapApiService.getClickedMapArea(screenPtX, screenPtY, map.getBounds(), activeCollectiveLayers, mapSize.x, mapSize.y, 10)
        if (res?.data && res.data.features && res.data.features.length) {
            const selectedSegmentProperties = res.data.features[0].properties
            const selectedClusterId = selectedSegmentProperties.cluster_id
            if (FAULTY_CLUSTERS.includes(selectedClusterId) || selectedClusterId.startsWith("-")) {
                console.log("Cluster is ", selectedClusterId)
                return
            }
            if (selectedClusterId !== selectedClusterGeoJSONInStore && simulationDetails && valueExistsInListOfObjects(simulationDetails.features, 'segment_id', selectedSegmentProperties.segment_id, 'properties')) {
                dispatch(setSelectedClusterGeoJSON(selectedClusterId))
                dispatch(setSelectedCluster(null))
                showPopupMessageOnMap(`Cluster ${selectedClusterId} geselecteerd`)
            }
            else if (selectedClusterId !== selectedClusterInStore) {
                dispatch(setSelectedCluster(selectedClusterId))
                dispatch(setSelectedClusterGeoJSON(null))
                showPopupMessageOnMap(`Cluster ${selectedClusterId} geselecteerd`)
            }
            dispatch(setViabilityState(ECONOMIC_VIABILITY_STATES.CLICKED_ON_MAP))
            dispatch(setSelectedClusterType(selectedSegmentProperties.cluster_label))
        }
    }

    return (
        <>
            <PopupForm
                open={openNameForm}
                onCancelHandler={closeNameFormHandler}
                onSubmitHandler={handleSubmitSimulationName}
                title="Naam van de simulatie"
                fieldNames={["Naam van de simulatie"]}
                fieldDefaultValues={[simulationParams.simulation_name]}
            />
        </>
    )
}
