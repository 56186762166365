import {SEARCH_LEVEL, SELECTED_AREA, SELECTED_MAP_LAYER, SIMULATION_HEAT_SUPPLY, SELECTED_CLUSTER, SELECTED_CLUSTER_GEOJSON, SELECTED_CLUSTER_TYPE} from "../constants/constants";
import {DEFAULT_SELECTED_AREA, HEAT_STATES } from "../../constants/map";

export const setSelectedArea = (value = {}) => {
    return {
        type: SELECTED_AREA,
        payload: {
            ...DEFAULT_SELECTED_AREA,
            ...value
        }
    }
}

export const setSearchLevel = (value = "") => {
    return {
        type: SEARCH_LEVEL,
        payload: value
    }
}

export const setSelectedMapLayer = (value) => {
    return {
        type: SELECTED_MAP_LAYER,
        payload: value
    }
}

export const setAddedHeatSupply = (value) => {
    return {
        type: SIMULATION_HEAT_SUPPLY,
        payload: value
    }
}

export const setSelectedCluster = (value) => {
    return {
        type: SELECTED_CLUSTER,
        payload: value
    }
}

export const setSelectedClusterGeoJSON = (value) => {
    return {
        type: SELECTED_CLUSTER_GEOJSON,
        payload: value
    }
}

export const setSelectedClusterType = (value) => {
    return {
        type: SELECTED_CLUSTER_TYPE,
        payload: value
    }
}

export const removeHeatSupply = (supplyId, supplyIndexInStore)=> {
    return async (dispatch, getState) => {
        const { map } = getState()
        const heatSupplies = map.simulationHeatSupplies
        let simDeleteRes = null
        let supplySavedInDb = heatSupplies[supplyIndexInStore].addition_status === HEAT_STATES.SUBMITTED
        if (supplySavedInDb) {
//            simDeleteRes = await simulationService.deleteHeatDemand(demandId)
        }
        if (!supplySavedInDb || simDeleteRes?.data === 1) {
            heatSupplies.splice(supplyIndexInStore, 1)
            dispatch(setAddedHeatSupply([...heatSupplies]))
            return true
        }
        return false
    }
}
