import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid  } from '@material-ui/core';
import parse from 'html-react-parser';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SearchBar } from "./searchBar";
import { ROUTES } from "../../constants/routes";
import { activeView } from '../../redux/actions/auth';

 function LandingPage(props) {

   if (props.lpContent){
      var {
         title, 
         text, 
         searchBarButtonText, 
         placeHolderText,
         zieVoorbeelden
      } = props?.lpContent.attributes;
   }
   
   const history = useHistory()

   useEffect(()=>{
    console.log('content',props.content.all_headings)
   },[props.content.all_headings])

   return (
      <>
      <Grid container>
         <Grid item xs={12} sm={12} md={12} lg={12} className='landing-image'>
         <Grid container item xs={12} sm={12} md={8} lg={6}  justifyContent='center'>
              <div className='landing-container'>
                  <span className='text-center landing-container-heading'> {parse(`${title}`)}</span>
                    <span className='text-center landing-font pt-2'>{parse(`${text}`)}</span>
                    <div className="input-group pt-3 landing-btn-container">
                        <SearchBar
                           searchBarClickHandler={()=> 
                           history.push(ROUTES.MAP_PAGE)}
                           placeHolderText={placeHolderText}
                           setActiveView={props.activeView}
                           searchBarBtnText={searchBarButtonText}
                           landing={true}
                        />                   
                    </div>
                    <div className='pt-3'>
                        <a href='#Zie' className='search-bottom'>{zieVoorbeelden}</a>
                    </div>
                    <div className='text-center pr-5'>
                        <a href='#container'>
                           <div className='landing-bottom-circle' id='container'>
                              <span className='bottom-icon pt-2'>?</span>
                              <span className='arrow-icon'><ExpandMoreIcon style={{color:'white'}} /></span>
                           </div>
                        </a>
                    </div>
              </div>  
            </Grid>
         </Grid>
      </Grid>
      </>
   )
}

const mapStateToProps = ({auth, content,loader}) => ({auth, content,loader})
export default connect(mapStateToProps, {activeView}) ( LandingPage );
