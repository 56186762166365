import React,{useState,useEffect} from "react"
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import '../../scss/warmte/login.scss';
import { AvForm } from "availity-reactstrap-validation";
import profile from "../../assets/profile-img.png";
import { useHistory} from "react-router-dom"
import {userLogin,userRegistration,checEmail} from '../../redux/actions/auth';import {activeView} from '../../redux/actions/auth';
import Validation_Icon from '../../icons/bootstrap/validation';
import {showErrorMsg, hideMessage} from '../../redux/actions/loader';
import {getContentByModuleLocation} from '../../redux/actions/content'
import {getAuthenticationErrors} from "../../helper/requestHelpers";

const Login = (props) => {

  // BEFORE API CALL VALIDATION
  const [isEmailNotValid, setIsEmailNotValid] = useState(false);
  const [isPasswordNotValid, setIsPasswordNotValid]= useState(false);
  const [ email, setEmail] = useState('');
  const [ password, setPassword] = useState('');
  const [ show, setShow] = useState(false);
  const [ remember, setRemember] = useState(false);
  const [passwordValidateText, setPasswordValidateText] = useState('');
  const [content, setContent] = useState(undefined);

  // AFTER API RESPONSE
  const [errorMsg, setErrorMsg] = useState();
  const [emailNotExists, setEmailNotExists] = useState(false);
  const [invalidPassword, setInvalidPassword]= useState(false);
  const history = useHistory();
  
  // useEffect(()=>
  // {
  //   setErrorMsg(props.loader.errorMsg);
  //   if(props.loader.errorMsg?.includes('password')) setInvalidPassword(true)
  //   else setInvalidPassword(false)
  // },[props.loader.errorMsg])

  useEffect(()=>{
    async function getContent(){
      const data = await props.getContentByModuleLocation('loginPage'); 
      setContent(data?.login?.attributes)
      console.log(data?.login?.attributes);
    }

    getContent();
    return ()=>props.showErrorMsg('')

  },[])

  useEffect(()=>
  {
    console.log('')
  },[email,password,remember])

  useEffect(()=>
  {
    console.log('');  
  },[invalidPassword,isPasswordNotValid])


  const handleLogin = async ({target}) =>{
    let {value, name} = target;
    if (isEmailNotValid && name=== 'email') setIsEmailNotValid(false);
    if(name == 'email') setEmail(value)
    else if(name == 'password') setPassword(value)
    else if(name == 'remember') setRemember(!remember)
  }

  const handleSubmit = async (
      event,
      errors,
      values
  ) => {
    // const emailExist = (await props.checEmail(email)).message.includes('Email Exists');
    // if (!emailExist) {
    //   setIsEmailNotValid(false);
    //   setEmailNotExists(true)
    //   setErrorMsg(content?.errorInvalidValue)
    // } else {
    setIsEmailNotValid(false)
    setErrorMsg("")
    setEmailNotExists(false)
    setIsPasswordNotValid(false)
    try {
      const loginRes = await props.userLogin(email, password, remember)
      if (loginRes && loginRes.status === 200) {
        setIsEmailNotValid(false)
        setIsPasswordNotValid(false);
      } else {
        takeActionsOnLoginErrors(loginRes.data.errors)
      }
    }
    catch (err) {
      console.log("exception came")
      const errorResponse = err.response
      if (errorResponse) {
          takeActionsOnLoginErrors(errorResponse.data.errors)
      }
    }

    // }
  }

  const takeActionsOnLoginErrors = (errors)=> {
      const {emailErrors, passwordErrors} = getAuthenticationErrors(errors)
      if (emailErrors.length) {
        if (emailErrors[0] === "EMAIL_NOT_EXISTS") {
          setErrorMsg(content?.errorEmailNotExistText)
          setEmailNotExists(true)
        }
        else {
          setIsEmailNotValid(true)
          if (emailErrors[0] === "EMAIL_VERIFICATION_PENDING")
            setErrorMsg(content?.errorEmailNotVerifiedText)
          else
            setErrorMsg(`${content?.errorEmailText}`)
        }
      }
      else if (passwordErrors.length) {
        setIsPasswordNotValid(true)
      }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="login-height my-5 pt-sm-5 bggg-bg">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-bg bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="login-text p-4">
                        <h5 className="login-text">{content?.loginHeading}</h5>
                        {/* <p>Sign in to continue to Skote.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                  </div>
                  <div className="p-2 pt-4">
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                    <label 
                        style={{color: isEmailNotValid || emailNotExists ? '#dc3545':'#212529'}}
                        className="position-relative">
                          E-mail
                    </label>
                    <div className="mb-3 position-relative">
                      <input
                        name="email"
                        label="E-mail"
                        value={email}
                        onChange={handleLogin}
                        className="form-control shadow-none"
                        placeholder={`E-mail`}
                        type="email"
                        style={{borderColor: isEmailNotValid || emailNotExists? '#dc3545':'rgb(206, 212, 218)'}}
                        required
                      />

                        {isEmailNotValid && !emailNotExists &&
                        <div>
                          <Validation_Icon />
                          <div className="invalid-field-label">{errorMsg}</div>
                        </div>
                        }
                        
                        {emailNotExists &&
                          <div>
                            <Validation_Icon />
                            <div className="invalid-field-label">
                              <span>{errorMsg}</span>
                            </div>
                          </div>
                        }
                      </div>
                      <label
                        style={{color: isPasswordNotValid || invalidPassword? '#dc3545':'#212529'}}
                      >
                        {content?.passwordText}
                      </label>
                      <div className="mb-3 position-relative">
                        <input
                          name="password"
                          label="Wachtwoord"
                          className='form-control shadow-none'
                          value={password}
                          onChange={handleLogin}
                          style={{borderColor: isPasswordNotValid || invalidPassword? '#dc3545':'rgb(206, 212, 218)'}}
                          type={show?'text':'password'}
                          required
                          placeholder="Wachtwoord"                          
                        />
                        <i 
                          className={`far ${show?'fa-eye-slash':'fa-eye'} icon-auth password-icon-login`}
                          style={{color: isPasswordNotValid || invalidPassword? '#dc3545':'#43B02A'}}
                          onClick={()=>setShow(!show)}
                        />

                        {isPasswordNotValid && !invalidPassword &&
                          <div>
                            {/* <Validation_Icon /> */}
                            <div className="invalid-field-label">
                              <span>{content?.errorInvalidValue}</span>
                            </div>
                          </div>
                        }

                        {invalidPassword &&
                            <div className="invalid-field-label">
                              <span>{content?.errorInvalidValue}</span>
                            </div>
                        }

                      </div>

                      {/*<div className="form-check">*/}
                      {/*  <input*/}
                      {/*    type="checkbox"*/}
                      {/*    className="form-check-input"*/}
                      {/*    id="customControlInline"*/}
                      {/*    value={remember}*/}
                      {/*    name='remember'*/}
                      {/*    onChange={handleLogin}*/}
                      {/*  />*/}
                      {/*  <label*/}
                      {/*    className="form-check-label"*/}
                      {/*    htmlFor="customControlInline"*/}
                      {/*  >*/}
                      {/*    {content?.rememberMeText}*/}
                      {/*  </label>*/}
                      {/*</div>*/}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block login-btn shadow-none"
                          type="submit"
                        >
                         Inloggen {props.loader.loader?<i className="fa fa-spinner fa-spin"></i>:''} 
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> {content?.forgetPasswordText}
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  {content?.registerTextOnLogin}{" "}
                  <Link
                    to="/registration"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    {content?.newRegistrationText}{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({auth,loader}) => ({auth,loader})
export default connect(mapStateToProps, { 
userLogin,
activeView,
userRegistration,
checEmail,
showErrorMsg,
getContentByModuleLocation,
hideMessage
}) ( Login );
