import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Grid,Container, Divider, LinearProgress, withStyles, Slider } from '@material-ui/core';
import {getEconomicViability, setViabilityState, showCards} from '../../../redux/actions/economicViability';
import { setSelectedCluster, setSelectedClusterGeoJSON, setSelectedClusterType } from "../../../redux/actions/map";
import BottomCard from './bottomCard';
import Box from "@material-ui/core/Box";
import parse from 'html-react-parser';
import Typography from "@material-ui/core/Typography";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import exit from '../../../assets/exit.svg';
import greyQuestion from '../../../assets/greyQuestion.svg';
import Tooltip from "@material-ui/core/Tooltip";
import {getContentByModuleLocation} from '../../../redux/actions/content'
import {ECONOMIC_VIABILITY_STATES} from "../../../constants/economicViability";
import {dutchFormatNumber} from "../../../helper/generalHelpers";
import {SIMULATION_STATUS} from "../../../constants/map";

function MainCard(props) {
const { classes } = props;


const CustomTooltip = withStyles({
    tooltip: {
        maxWidth: "600px",
        maxHeight: "1000px",
    }
})(Tooltip);


const CustomSlider = withStyles ({
    root: {
        height: 5,
        padding: "13px 0",
    },
    rail: {
        height:5,
        backgroundColor:'#B3B4B6'
    },
    track: {
        height: 5,
        borderRadius: 5,
        backgroundColor:'#43B02A',
    },
    thumb: {
        height: 15,
        width: 15,
        backgroundColor: "#fff",
        border: "3px solid #43B02A",
        marginTop: -5,
        marginLeft: -11,
        boxShadow: "#ebebeb 0 2px 2px",
        "&:focus, &:hover, &$active": {
            boxShadow: "#ccc 0 2px 3px 1px",
        },
        color: "#43af2a",
    },
    mark: {
        backgroundColor:'#43B02A',
        width:50,
        height:0
    },

})(Slider);

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 100,
        label: '100%',
    },
];

const theme = createTheme({
    typography: {
        htmlFontSize: 20,
        color: "#919195"
    }
})

const LinearProgressWithLabel = (props)=> {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
          <ThemeProvider theme={theme}>
            <Typography
                variant="body2" color="text.secondary">{`${Math.round(
              props.value,
            )}%`}</Typography>
          </ThemeProvider>
      </Box>
    </Box>
  );
}

const CustomProgress = withStyles ({
    colorPrimary: {
        backgroundColor: 'rgb(179, 180, 182,0.3)',
        borderRadius:20,
        height:6
    },
    barColorPrimary: {
        backgroundColor: '#702082',
        borderRadius:20
    }
})(LinearProgressWithLabel);

const CustomProgress2 = withStyles ({
    colorPrimary: {
        backgroundColor: 'rgb(179, 180, 182,0.3)',
        borderRadius:20,
        height:6
    },
    barColorPrimary: {
        backgroundColor: '#43B02A',
        borderRadius:20
    }
})(LinearProgress);

const [close, setClose] = useState(false)
const [reportCardData, setReportCardData] = useState(null)
const [reportCardLoading, setReportCardLoading] = useState(false)
const [bottomParamsValues, setBottomParamsValues] = useState(null)
const [content, setContent] = useState(undefined);
const [content2, setContent2] = useState(undefined);
const [futureInput, setFutureInput] = useState(25);
const [futureInputChange, setFutureInputChange] = useState(5);

useEffect(()=> {
    const getContent = async () => {
        let data = await props.getContentByModuleLocation('sideEVCard1');
        setContent(data?.economicViability?.attributes);
        data = await props.getContentByModuleLocation('sideEVCard2');
        setContent2(data?.economicViability?.attributes);
    }

    getContent();
}, [])

    useEffect(() => {
        setClose(props.economicViability.show_cards)
    }, [props.economicViability.show_cards])

    useEffect(()=> {
        if (bottomParamsValues) {
            ;(async () => await setEconomicViabilityReportData())()
        }
    }, [props.economicViability.show_cards, bottomParamsValues, props.clusterLayer])

    const handleClose = () => {
        props.setViabilityState(ECONOMIC_VIABILITY_STATES.IDLE)
        props.setSelectedCluster(null)
        props.setSelectedClusterGeoJSON(null)
        props.setSelectedClusterType(null)
        props.showCards(false)
    }

    const setEconomicViabilityReportData = async ()=> {
        setReportCardLoading(true)
        setReportCardData(null)
        // console.log("common streets are ", props.clusterLayer, props.clusterLayer ? commonStreetsBwSimulationAndCluster() : null)
        const response = await props.getEconomicViability(+futureInput/100, +futureInputChange/100,
            bottomParamsValues.gasInitial, bottomParamsValues.gasChange,
            bottomParamsValues.heatInitial, bottomParamsValues.heatChange,
            bottomParamsValues.co2Initial, bottomParamsValues.CO2Change,
            props.map.simulationStatus === SIMULATION_STATUS.SAVED && props.clusterLayer ? commonStreetsBwSimulationAndCluster() : [],
            props.map.simulationStatus === SIMULATION_STATUS.SAVED ? props.map.simulationParams.simulation_saved_id : null
        )
        if (response && !response.errors.length) {
            setReportCardData(response.data)
        }
        setReportCardLoading(false)
    }

    const commonStreetsBwSimulationAndCluster = ()=> {
        const commonStreets = []
        const geoJsonClusters = props.clusterLayer.toGeoJSON()
        for (let feature of props.map.selectedSimulationDetails.simulationData.features) {
            for (let clusterFeature of geoJsonClusters.features) {
                if (clusterFeature.properties.segment_id === feature.properties.segment_id && feature.properties.cluster_id === props.map.selectedClusterInGeoJSON)
                    commonStreets.push(clusterFeature.properties.segment_id)
            }
        }
        return commonStreets
    }
    const submitValues = (values)=> {
        setBottomParamsValues(values)
    }


if(close)
return (
    <Grid container direction="row"
        justifyContent="space-between"
        alignItems="center" className={'container-top'}>
        <Grid item xs={12} sm={6} >
            <img
                src={exit}
                alt='close'
                className='container-exit'
                onClick={handleClose}
            />
            <div className='card-container adjusted-height-ev-card shadow text-center'>
                <Container>
                    <CustomTooltip 
                        title={<h6>{parse(`${content?.popUpText}`)}</h6>}
                    >
                        <div style={{position:'absolute',left:'1%'}}>
                            <img
                                className='ml-2 pointer'
                                src={greyQuestion}
                                alt="questionMark"
                                width="20px"
                                height="20px"
                            />

                        </div>
                    </CustomTooltip>
                    <div className='card-section pb-5'>
                        <span className='section-heading'>{content?.currentSituationHeading}{' '}
                            <span className='section1-label '>
                                ({content?.currentSituationSubHeading})
                            </span>
                        </span>
                        {/* {[content?.heatSourceGas,content?.heatSourceOil,content?.heatSourceElectricity,content?.heatSourceOther].map((obj) => ( */}
                        {/*    {[content?.heatSourceGas,content?.heatSourceOther].map((obj) => (*/}
                            <div className='pt-2'>
                                <div className='d-flex'>
                                    <span className='bar-label'>
                                        {content?.heatSourceGas}
                                    </span>
                                    <div className='card-bar'>
                                        <CustomProgress
                                            variant="determinate" value={reportCardData ? Math.round(reportCardData.powerUsageGasPercentage < 100 ? Math.max(reportCardData.powerUsageGasPercentage, 0) : 100) : 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2'>
                                <div className='d-flex'>
                                    <span className='bar-label'>
                                        {content?.heatSourceOther}
                                      </span>
                                    <div className='card-bar'>
                                        <CustomProgress
                                            variant="determinate"
                                            value={reportCardData ? Math.round(reportCardData.powerUsageOtherPercentage < 100 ? Math.max(reportCardData.powerUsageOtherPercentage, 0) : 100) : 0}
                                        />
                                    </div>
                                </div>
                            </div>
                        {/*))}*/}
                    </div>
                </Container>
                    <Divider variant='horizontal' className='mt-4 mb-3'/>
                <Container>
                    <div className='card-section pb-3'>
                        <span className='section-heading'>{content?.investmentCostHeading}</span>
                        <div className='d-flex'>
                            <div className='inside-container'>
                                <span className='section-text-4'>
                                    {content?.investmentCostHeading}<br/>
                                    <span className='text-small card-section-text-colored'>
                                        {reportCardData ? dutchFormatNumber(Math.floor(reportCardData['yearly_replacecosts_gas'])) : "N/A"}
                                        <br/>
                                        <span className='card-section-text-colored-2'>
                                            Euro
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div className='inside-container'>
                                <span className='section-text-4'>
                                    {content?.investmentCostmaintenanceHeading}<br/>
                                    <span className='text-small card-section-text-colored'>
                                        {reportCardData ? dutchFormatNumber(Math.floor(reportCardData['yearly_replacecosts_gas']*0.05)) : "N/A"}
                                        <br/>
                                        <span className='card-section-text-colored-2'>
                                            Euro
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div><br/>
                        <div className='d-flex'>
                            <div className='inside-container'>
                                <span className='section-text-4 d-block'>
                                    {content?.investmentCostHeatHeading}
                                    <span className='section-text-3 d-block'> 
                                        ({content?.investmentCostHeatSources})
                                    </span>
                                </span>
                                <div className='d-flex'>
                                    {/*<span className='mt-2 text-small card-section-text-colored'>*/}
                                    {/*    2020*/}
                                    {/*    <br/>*/}
                                    {/*    <br/>*/}
                                    {/*    <span className='pt-5 section-text-2'>*/}
                                    {/*    {content?.investmentCostTime}*/}
                                    {/*    </span>*/}
                                    {/*</span>*/}
                                    <span className='mt-2 text-small card-section-text-colored'>
                                        {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.yearly_gasusage_cost_2020_gas)) : "N/A"}
                                        <br/>
                                        <br/>
                                        <span className='section-text-2'>
                                            Euro
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className='inside-container'>
                                <span className='section-text-4 d-block'>
                                    {content?.investmentCostHeatHeading2}
                                    <span className='section-text-3 d-block'> 
                                        ({content?.investmentCostHeatSources})
                                    </span>
                                </span>
                                <div className='d-flex'>
                                    {/*<span className='mt-2 text-small card-section-text-colored'>*/}
                                    {/*    2050*/}
                                    {/*    <br/>*/}
                                    {/*    <br/>*/}
                                    {/*    <span className='section-text-2'>*/}
                                    {/*    {content?.investmentCostTime}*/}
                                    {/*    </span>*/}
                                    {/*</span>*/}
                                    <span className='text-small mt-2 card-section-text-colored'>{reportCardData ? dutchFormatNumber(Math.floor(reportCardData.yearly_gasusage_cost_2050_gas)) : "N/A"}
                                    <br/>
                                    <br/>
                                        <span className='section-text-2'>
                                            Euro
                                        </span>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
                <Divider variant='horizontal' className='mt-3 mb-3'/>
                <Container>
                    <div className='pb-3'>
                        <div className='d-flex'>
                            <div className='inside-container d-flex justify-content-end'>
                                <span className='section-heading'>
                                   {content?.ownershipHeading}
                                </span>
                            </div>
                            <div className='inside-container'>
                                <span className='card-section-text-colored'>
                                    {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.tco_gas)) : "N/A"}
                                <span className='ml-2 euro-yearly'>
                                    Euro 
                                </span> 
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='pb-3'>
                        <div className='d-flex'>
                            <div className='inside-container d-flex justify-content-end'>
                                <span className='section-heading'>
                                   {content?.ownershipHeadingPerMwh}
                                </span>
                            </div>
                            <div className='inside-container'>
                                <span className='card-section-text-colored'>
                                    {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.tco_gas_perMWh)) : "N/A"}
                                <span className='ml-2 euro-yearly'>
                                    Euro
                                </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </Grid>


        <Grid item xs={12} sm={6}>
            <div className='card-container adjusted-height-ev-card shadow text-center'>
                <Container>
                    <div className='card-section'>
                        <span className='section-heading'>
                            {content2?.futureHeatnet || 'Future Heatnet'}
                        </span>
                        <div className='card2-section1-container'>
                            {['Starting'].map((obj,index) => (
                                <div className={index === 1 && 'mt-4 pt-2'}>
                                    <div className='card-bar'>
                                        {/* <CustomProgress2
                                            variant="determinate" value={50}
                                        /> */}
                                        <CustomSlider
                                            className='pb-0'
                                            defaultValue={futureInputChange}
                                            step={1}
                                            scale={(value)=> value}
                                            onChangeCommitted={(_, value)=> setFutureInputChange(value)}
                                            valueLabelDisplay="on"
                                            marks={marks}
                                            aria-labelledby="discrete-slider"
                                        />
                                    </div>
                                    {/*<div className='d-flex'>*/}
                                    {/*    <div className='inside-container'>*/}
                                    {/*        <span className='card2-section-bar-bottom-text'>*/}
                                    {/*            {content2?.futureHeatNetBarLeftTitleFirst}*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className='inside-container'>*/}
                                    {/*        <span className='card2-section-bar-bottom-text-2'>*/}
                                    {/*            {content2?.futureHeatNetBarRightTitleFirst}*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className='inside-container'>
                                        <span className='section-text-4 mr-4'>{content2?.connectionCostStartingText}</span>
                                    <input 
                                        type='number'
                                        className='bottom-card-inputs mt-2'
                                        min="0"
                                        onKeyPress={(e)=> {
                                            if (e.code === 'Minus') {
                                                e.preventDefault();
                                            }
                                        }}
                                        defaultValue={futureInput}
                                        onChange={(e)=>setFutureInput(e.target.value)}
                                    />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>
                <br/>
                    <Divider variant='horizontal' className='mt-4 mb-2'/>
                <Container>
                <div className='card-section'>
                        <span className='section-heading'>{content2?.investmentCostHeading}</span>
                        <div className='d-flex card2-section2-container'>
                            <div className='inside-container'>
                            <p className='section2-text m-0'>
                                {content2?.investmentcostConnections}
                            </p>
                            <span className='mt-2 text-small card2-section-text-colored'>
                                {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.investment_conn)) : "N/A"}
                                <br/>
                                <br/>
                                <span className='section-text-2'>
                                    Euro
                                </span>
                            </span>
                            </div>
                            <div className='inside-container'>
                            <p className='section2-text m-0'>
                                {content2?.investmentcostPiping}
                            </p>
                            <span className='text-small mt-2 card2-section-text-colored'>
                                {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.investment_pipes)) : "N/A"}
                                <br/>
                                <br/>
                                <span className='section-text-2'>
                                    Euro
                                </span>
                            </span>
                            </div>
                        </div>
                {/*    </div>*/}
                {/*</Container>*/}
                {/*/!* <Divider variant='horizontal' className='mt-2 mb-3'/> *!/*/}
                {/*<Container>*/}
                {/*    <div className='card-section'>*/}
                        {/* <span className='section-heading'>{content2?.investmentCostHeading}</span> */}
                        <div className='d-flex'>
                            {/* Heat 2020 */}
                            <div className='inside-container'>
                                <span className='section-text-4 d-block'>
                                    {content2?.investmentCostHeatHeading}
                                    <span className='section-text-3 d-block'>
                                        ({content2?.investmentCostHeatSources})
                                    </span>
                                    <div className='d-flex'>
                                        <span className='text-small mt-2 card2-section-text-colored'>
                                            {reportCardData  ? dutchFormatNumber(Math.floor(reportCardData.yearly_heat_cost_2020_dh)) : "N/A"}
                                            <br/>
                                            <br/>
                                            <span className='section-text-2'>
                                                Euro
                                            </span>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            {/* Heat 2050 */}
                            <div className='inside-container'>
                                <span className='section-text-4 d-block'>
                                    {content2?.investmentCostHeatHeading2}
                                    <span className='section-text-3 d-block'>
                                        ({content2?.investmentCostHeatSources})
                                    </span>
                                    <div className='d-flex'>
                                        <span className='text-small mt-2 card2-section-text-colored'>
                                            {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.yearly_heat_cost_2050_dh)) : "N/A"}
                                            <br/>
                                            <br/>
                                            <span className='section-text-2'>
                                                Euro
                                            </span>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div className='inside-container'>
                                <span className='section-text-4'>
                                    {content2?.investmentCostmaintenanceHeading}<br/>
                                    <span className='text-small mt-2 card2-section-text-colored'>
                                        {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.maintenance_dh + reportCardData.replacements_dh)) : "N/A"}
                                            <br/>
                                            <br/>
                                        <span className='section-text-2'>
                                            Euro
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </Container>
                <Divider variant='horizontal' className='mt-3 mb-3'/>
                <Container>
                    <div className='pb-3'>
                        <div className='d-flex'>
                            <div className='inside-container d-flex justify-content-end'>
                                <span className='section-heading'>
                                    {/* {content2?.totalYearCostHeading} */}
                                    {content2?.ownershipHeading}
                                </span>
                            </div>
                            <div className='inside-container'>
                                <span className='card2-section-text-colored'>
                                    {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.tco_dh)) : "N/A"}
                                    <span className='ml-2 euro-yearly'>
                                        Euro 
                                    </span> 
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='pb-3'>
                        <div className='d-flex'>
                            <div className='inside-container d-flex justify-content-end'>
                                <span className='section-heading'>
                                    {/* {content2?.totalYearCostHeading} */}
                                    {content2?.ownershipHeadingPerMwh}
                                </span>
                            </div>
                            <div className='inside-container d-flex justify-content-start
                            '>
                                <span className='card2-section-text-colored'>
                                    {reportCardData ? dutchFormatNumber(Math.floor(reportCardData.tco_dh_perMWH)) : "N/A"}
                                    <span className='ml-2 euro-yearly'>
                                        Euro
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </Grid>
        <BottomCard
            reportLoading={reportCardLoading}
            setParams={(values)=> submitValues(values)}
        />
    </Grid>
)
else return <></>
}

const mapStateToProps = ({economicViability,map,auth}) => ({economicViability,map,auth})
export default connect(mapStateToProps, { getEconomicViability, setSelectedCluster, setSelectedClusterGeoJSON, setSelectedClusterType, getContentByModuleLocation, setViabilityState, showCards }) ( MainCard );