export const textWithCapitalFirstLetter = (text)=> {
    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1)
}

export const convertJSListToStringifiedTuple = (inputList)=> {
    return `('${inputList.join("','")}')`
}

export const valueExistsInListOfObjects = (listOfObjects, key, value, parent_key=null)=> {  // parent key in case of nesting
    for (const item of listOfObjects) {
        let valueToCheck = null
        if (parent_key)
            valueToCheck = item[parent_key][key]
        else
            valueToCheck = item[key]
        if (valueToCheck === value)
            return true
    }
    return false
}

export const dutchFormatNumber = (number)=> {
    return new Intl.NumberFormat('de-DE').format(number)
    // let finalNumber = ""
    // let splittedNumber = number.toString().split(("."))
    // for (let numIndex in splittedNumber[0]) {
    //     if (numIndex != 0 && (+numIndex) % 3 === 0)
    //         finalNumber += "."
    //     finalNumber += splittedNumber[0][+numIndex]
    // }
    // return splittedNumber.length > 1 ? [finalNumber, splittedNumber[1]].join(",") : finalNumber
}