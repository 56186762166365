import { Radio, withStyles } from '@material-ui/core';
import { grey } from "@material-ui/core/colors";
export const CustomRadio = withStyles({
    root: {
      height:5,
      color: grey,
      "&$checked": {
        color: "#312C5B",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);