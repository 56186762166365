import React,{useEffect} from "react";
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import Header from '../components/header/header';
import Footer from '../components/footer/footer';

// AUTH
import LoginPage from '../components/auth/login';
import RegistrationPage from '../components/auth/registration';
import ForgotPassword from '../components/auth/forgot';
import ChangePassword from '../components/auth/changePassword';

// ZONING PLAN
import ZoningPlan from '../components/zoningPlans/zoning';

// HOMEPAGE
import LandingPage from '../components/homePage/index';
import UserSimulations from "../components/profileMenu/userSimulations";
import LeafletMap from "../components/MainPage/map/leafletMap";

// ERROR PAGE
import ErrorPage from '../components/pages/Error403';
import NotFoundPage from '../components/pages/Error404';
import ComingSoon from '../components/pages/comingSoon';


// INFO PAGE
import InfoPage from '../components/infoPage/infoPage';

function MainRouter(props) {
  let checkAuth = document.cookie.indexOf('access_token') == -1 ? false : true;
  return (
    <Router>
      <div className="app-content-wrapper">
          <Header />
          {checkAuth?
          <Switch>
            <Route exact path={ROUTES.MAP_PAGE}>
              <LeafletMap/>
            </Route>
            <Route exact path={ROUTES.HOME_PAGE}>
              <LandingPage />
            </Route>
            <Route exact path={ROUTES.SIMULATION_PAGE}>
              <UserSimulations/>
            </Route>
            <Route exact path={ROUTES.CHANGE_PASS}>
              <ChangePassword />
            </Route>
            <Route exact path={ROUTES.ZONING_PLAN}>
              <ZoningPlan />
            </Route>  
            <Route exact path={ROUTES.INFO_PAGE}>
              <InfoPage />
            </Route>
            <Route >
              <NotFoundPage />
            </Route>

          </Switch>
           :
          <Switch>
             <Route exact path={ROUTES.HOME_PAGE}>
              <LandingPage />
            </Route>
            <Route exact path={ROUTES.MAP_PAGE}>
              <LeafletMap/>
            </Route>
            <Route exact path={ROUTES.LOGIN_PAGE}>
              <LoginPage />
            </Route>
            <Route exact path={ROUTES.REGISTRATION}>
              <RegistrationPage />
            </Route>
            <Route exact path={ROUTES.FORGOT_PASS}>
              <ForgotPassword />
            </Route>
            <Route exact path={ROUTES.FORGOT_PASS_CHANGE}>
              <ChangePassword />
            </Route>
            <Route exact path={ROUTES.INFO_PAGE}>
              <InfoPage />
            </Route>
            <Route >
              <ErrorPage />
            </Route>
          </Switch>
          }
      </div>
          <Footer />
    </Router>
  );
}

const mapStateToProps = ({auth,content,map,loader}) => ({auth,content,map,loader})
export default connect(mapStateToProps, null) ( MainRouter);
