
export const ECONOMIC_VIABILITY_STATES = {
    IDLE: "idle",
    CLICK_ON_MAP: "click_on_map",
    CLICKED_ON_MAP: "clicked_on_map",
    READY_TO_CALCULATE: "ready_to_calculate",
    CALCULATED: "calculated"
}

export const FAULTY_CLUSTERS = [
    '-1-1'
]
