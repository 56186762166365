import {
    economicViabilityCurrent
} from "../redux/constants/ApiEndPoints";
import {ApiService} from "./ApiService";


class EconomicViabilityAPIService extends ApiService{
    async getEconomicViability(clusterId, simulationCase, conn_initial, conn_increment, gas_price, gas_increment,
                               heat_price, heat_increment, co2_price, co2_increment, segment_ids=[], simulation_id=null
    ) {
        return await this.apiWrapper("Could not get Economic Viability Report data", economicViabilityCurrent, "post", true,
            {
                cluster_id: clusterId,
                simulation_case: simulationCase,
                conn_initial,
                conn_increment,
                gas_price,
                gas_increment,
                heat_price,
                heat_increment,
                co2_price,
                co2_increment,
                segment_ids,
                simulation_id
            });
    }
}

export default new EconomicViabilityAPIService()