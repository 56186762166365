
import { CHANGE_VIABILITY_STATE, SHOW_CARDS } from '../constants/constants';

const INIT_STATE = {
    selected_state:'idle',
    show_cards:false
}
const economicViability = (state = INIT_STATE, action) => {
    switch(action.type) {
        case CHANGE_VIABILITY_STATE:
            return {
                ...state,
                selected_state: action.payload
            }
        case SHOW_CARDS:
            return {
                ...state,
                show_cards:action.payload
            }

        default:
            return state;
    }
}

export default economicViability;