import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom"
import { Grid , Container } from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import section1 from '../../assets/section1.png';
import section2 from '../../assets/section2.png';
import section3 from '../../assets/section3.png';


function InfoPage (props) {
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [image, setImage] = useState()

    const handleClose = () => {
        setOpen(false)
    }
    const handleImage = (value) => {
        setImage(value)
        setOpen(true)
    }
 
   return (
      <Grid container> 

        <Grid container className='mt-5 pt-4 pb-5' id='info1'>
            <Container>
                <Grid container >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <img 
                            src={section1} 
                            alt='image' 
                            className='info-page-image' 
                            onClick={()=>handleImage(section1)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className='position-relative mt-5'>
                        <div className='pt-5 pl-5 pr-5 pb-5 '>
                            <h5 className='info-page-heading'>
                                Voorbeeld 1: Grootschalig collectief netwerk
                            </h5>
                            <div className='info-page-content'>
                                <p>
                                    De kaart van voorbeeld 1 toont het resultaat van de inspiratiekaart warmtezonering
                                    voor het centrum van Gent. De rode stippenlijn toont bovendien het traject van het
                                    bestaande wertenet “Luminus Gent”.
                                </p>
                                <div>
                                    <p>
                                        <span className='bold'>
                                            Het warmetnet Luminus Gent
                                        </span> <br/>
                                        Dit warmtenet omvat vandaag een ondergonds leidingnet van 23 km en wordt gevoed
                                        met restwarmte van de elektriciteitscentrale van EDF Luminus. Het levert warmte
                                        aan 110 afnemers waaronder het AZ Sint-Lukas, universiteitsgebouwen, sociale
                                        wooncomplexen, een zwembad, enz.
                                    </p>
                                </div>
                                <div>
                                    <div>
                                        <span className='bold'>
                                        Link met aanpak “Inspiratiekaart warmtezonering”
                                        </span> <br/>
                                        <p>
                                            Zoals gebruikelijk bij de studie van het warmtenettracé is ook voor het
                                            warmtenet “Luminus Gent” voornamelijk gewerkt vanuit de warmtebron en
                                            gericht naar grootschalige afnemers.                                        </p>
                                        <p>
                                            Bij het opstellen van de “Inspiratiekaart warmtezonering” werd echter de
                                            omgekeerde aanpak genomen en werd het potentieel voor warmte vanuit de
                                            vraagzijde begroot. Het resultaat van de inspiratiekaart duidt hierdoor
                                            nagenoeg het volledige centrum van Gent aan als een zoekzone “eerder
                                            collectief”. Dit betekent dat de warmtevraagparameters voor deze zoekzone
                                            veerleer gunstig zijn voor warmtenetten.                                        </p>
                                        <p>
                                            Belangrijk hierbij is dus de interpretatie voor het begrip zoekzone. Zo
                                            omvatten de collectieve zoekzone van de stad Gent meer dan 190 GWh. Dit is
                                            maar liefst 3 keer meer dan de totale capaciteit van het reeds uitgevoerde
                                            warmtenet “Limunus Gent.” Eén van de belangrijkste volgende stappen in het
                                            uitwerken van een warmtezoneringsplan is dan ook om de potentiële
                                            warmtebronnen voor traditionele concepten zoals restwarmten, maar ook
                                            innovatieve oplossingen onderzocht worden, zoals aquathermie, riothermie,
                                            zonnewarmte, …                                        </p>
                                    </div>
                                </div>
                            </div><br/><br/>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Grid>

        <Grid container className='info-page-bg-grey' id='info2'>
            <Container>
                <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} className='position-relative mt-5'>
                        <div className='pt-5 pl-5 pr-5 pb-5 '>
                            <h5 className='info-page-heading'>
                                Voorbeeld 2: Kleinschalig collectief netwerk
                            </h5>
                            <div className='info-page-content'>
                                <p>
                                    {`De kaart van voorbeeld 2 toont het resultaat van de inspiratiekaart warmtezonering voor het noordoosten van Aalst. De rode stippenlijn toont bovendien het traject van het bestaande warmtenet ‘VMM St-Elisabeth.’`
                                    }
                                </p>
                                <div>
                                    <div>
                                        <span className='bold'>
                                            Het warmtenet VMM Sint-Elisabeth
                                        </span> <br/>
                                        Dit kleinschalige warmtenet werd gerealiseerd in 2008 uit een samenwerking
                                        tussen 3 openbare diensten: VMM, Stad Aalst en OCMW Aalst. Het warmtenet werkt
                                        met een centrale stookplaats met 4 gasketels van 500 kW goed voor een
                                        jaarproductie van 2500 MWh. Daarmee voorziet het warmtenet de warmtebehoefte van
                                        90 woningen.
                                    </div>
                                </div>
                                <div>
                                <div>
                                        <span className='bold'>
                                        Link met aanpak ‘Inspiratiekaart warmtezonering’
                                        </span> <br/>
                                        <p>
                                            Zoals weergegeven op de kaart bevat overlapt het warmtenet met
                                            straatsegmenten die deel uitmaken van een kleine cluster met aanduiding
                                            zoekzone ‘collectief’ als straatsegmenten met zoekzone ‘individueel’.
                                        </p> 
                                        <p>
                                            Om dit beter te begrijpen is het belangrijk om de werking van het cluster
                                            algoritme wat meer in detail te bekijken. Het clusteralgoritme zoekt
                                            namelijk naar naburige segmenten met een gelijkaardige score. Om de afstand
                                            te bepalen wordt gekeken naar het geografische middelpunt van het
                                            straatsegment. Indien de afstand van het middelpunt van een straatsegment
                                            tot een naburige cluster te groot wordt, zal het niet worden opgenomen in
                                            die cluster. Het straatsegment behoudt dan het label ‘individueel’.
                                        </p>
                                        <p>
                                            Zoals te zien op de kleine inzet met de ‘segment scores’ (voorafgaand aan
                                            het clusteralgoritme) toont inderdaad dat de straatsegmenten aan het noorden
                                            van het warmtenet wel degelijk een score collectief krijgen. Na clustering
                                            valt het linkse (lange) straatsegment echter, omwille van zijn lengte, uit
                                            de collectieve cluster.
                                        </p>
                                        <p>
                                            Met dit voorbeeld tonen we bijgevolg dat de aanduiding van collectieve
                                            zoekzones vooral gericht is op het identificeren van opportuniteiten voor
                                            grootschalige warmtenetten. Bij lokale projecten, op kleine schaal, geeft de
                                            aanwezigheid van een kleine cluster met zoekzone ‘collectief’ wel een
                                            indicatie van potentieel, maar blijft een lokale oefening ten allen tijde de
                                            moeite om in de nabije omgeving eventueel bijkomend potentieel niet uit te
                                            sluiten.
                                        </p>
                                    </div>
                                </div>
                            </div><br/><br/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <img 
                            src={section2} 
                            alt='image' 
                            className='info-page-image' 
                            onClick={()=>handleImage(section2)}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Grid> 

        <Grid container className='mt-5 pt-4 pb-5' id='info3'>
            <Container>
                <Grid container >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <img 
                            src={section3} 
                            alt='image' 
                            className='info-page-image' 
                            onClick={()=>handleImage(section3)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className='position-relative mt-5'>
                        <div className='pt-5 pl-5 pr-5 pb-5'>
                            <h5 className='info-page-heading'>
                                Voorbeeld 3: Individuele oplossingen
                            </h5>
                            <div className='info-page-content'>
                                <p>
                                    Buiten de stadcentra en dorpskernen en zeker bij toenemende renovatiegraad, kleurt de 
                                    inspiratiekaart warmte eerder naar ‘zoekzone individueel’. Dit houdt uiteraard rechtstreeks
                                    verband met de daling van warmtevraagdensiteit naarmate de gebouwen verder uit elkaar
                                    komen te staan (meer vrijstaande woningen…).
                                </p>
                                <p>
                                    Voor deze zoekzones zal de oplossing dus eerder gezocht moeten worden bij individuele 
                                    warmteoplossingen zoals warmtepompen en zonthermie. Dit betekent echter niet dat er geen
                                    collectieve acties kunnen worden opgezet. Collectieve wijkrenovaties kunnen bijvoorbeeld
                                    interessante pistes zijn om het traject naar lage-temperatuurverwarming te versnellen. 
                                </p>
                            </div><br/><br/>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <Modal open={open} onClose={handleClose} center>
            <div>
                <img 
                    src={image?image:null} 
                    alt='image' 
                    className='info-page-modal-image' 
                    onClick={()=>setOpen(true)}
                />
            </div>
       </Modal>

      </Grid>
   );
}

const mapStateToProps = ({content,loader}) => ({content,loader})
export default connect(mapStateToProps,null) ( InfoPage );
