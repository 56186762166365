import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Grid,Container, withStyles, Box, Slider } from '@material-ui/core';
import { setViabilityState } from '../../../redux/actions/economicViability';
import setting from '../../../assets/setting.svg';
import { FillButton } from '../../../helper/buttons';
import {getContentByModuleLocation} from '../../../redux/actions/content'


function BottomCard(props) {
const { classes } = props;
const CustomSlider = withStyles ({
    root: {
        height: 5,
        padding: "13px 0",
    },
    rail: {
        height:5,
        backgroundColor:'#B3B4B6'
    },
    track: {
        height: 5,
        borderRadius: 5,
        backgroundColor:'#702082',
    },
    thumb: {
        height: 15,
        width: 15,
        backgroundColor: "#fff",
        border: "3px solid #702082",
        marginTop: -5,
        marginLeft: -11,
        boxShadow: "#ebebeb 0 2px 2px",
        "&:focus, &:hover, &$active": {
            boxShadow: "#ccc 0 2px 3px 1px",
        },
        color: "#702081",
    },
    mark: {
        backgroundColor:'#702082',
        width:50,
        height:0
    },

})(Slider);

const marks = [
    {
        value: 0,
        label: '-5%',
    },
    {
        value: 100,
        label: '5%',
    },
];
// conn_0= 0.25, conn_inc= 0.05,pgas_0=95.0, pgas_inc=0.02, pheat_0=25.0, pheat_inc=0.01, pco2_0=250,
//                pco2_inc=0.0
const [content, setContent] = useState(undefined);
const [gas, setGas] = useState(95);
const [CO2, setCO2] = useState(250);
const [heat, setHeat] = useState(25);
const [gasChange, setGasChange] = useState(70);
const [CO2Change, setCO2Change] = useState(50);
const [heatChange, setHeatChange] = useState(60);

useEffect(() => {
    async function getContent(){
        let data = await props.getContentByModuleLocation('sideEVCardBottom');
        setContent(data?.economicViability?.attributes);
    }

    getContent();
}, [])

    useEffect(()=> {
        submitParams()
    }, [])

    const submitParams = ()=> {
        props.setParams({
            gasInitial: gas,
            co2Initial: CO2,
            heatInitial: heat,
            gasChange: (-5 + (gasChange * 0.1)) * 0.01,       // have to send percentage: 3% = 0.03
            heatChange: (-5 + (heatChange * 0.1)) * 0.01,
            CO2Change: (-5 + (CO2Change * 0.1)) * 0.01
        })
    }

    const setGasChangeNewValue = (_, newValue)=> {
        setGasChange(newValue)
    }

    const setHeatChaneNewValue = (_, newValue)=> {
        setHeatChange(newValue)
    }

    const setCO2ChaneNewValue = (_, newValue)=> {
        setCO2Change(newValue)
    }

    function valuetext(value) {
    return `${value}°C`;
}
    return (
        <Grid container justifyContent='flex-end' className='mb-3'>
            <Grid item xs={12} sm={12} >
                <div className='bottom-card-container  shadow text-center mt-3'>
                    <Container>
                        <Grid container justifyContent='space-between'>
                            <Grid item xs={12} sm={3} md={3} lg={2} xl={2} style={{alignSelf: 'center'}}>
                                <div className='d-flex'>
                                    <div className='card2-setting-container'>
                                        <img src={setting} alt='setting' />
                                        <span className='section-heading pl-2 pt-1'>
                                        {content?.priceSettingsTitle}
                                        </span>
                                        <p className='card2-setting-text m-0 pt-2'>
                                        {content?.priceSettingsText}

                                        </p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div>
                                    <Box className='position-relative'>
                                        <CustomSlider
                                            aria-label="Default"
                                            onChangeCommitted={setGasChangeNewValue}
                                            defaultValue={gasChange}
                                            step={1}
                                            valueLabelFormat={(value)=> (-5 + (value * 0.1)).toFixed(2)}
                                            valueLabelDisplay="on"
                                            marks={marks}
                                        />
                                        <span className='custom-track-label'>{content ? content["1stParamaterText"] || "Gas" : ""}</span>
                                        <div className='d-inline-flex'>
                                            <span className='section-text-4'>
                                                {content?.startingValueText}
                                            </span>
                                            <input
                                                type='number'
                                                className='ml-2 bottom-card-inputs'
                                                min="0"
                                                defaultValue={gas}
                                                onKeyPress={(e)=> {
                                                    if (e.code === 'Minus') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e)=>setGas(e.target.value)}
                                            />
                                        </div>
                                    </Box>
                                    <Box sx={{ mt:3 }} className='position-relative'>
                                        <CustomSlider
                                            aria-label="Default"
                                            step={1}
                                            valueLabelFormat={(value)=> (-5 + (value * 0.1)).toFixed(2
                                            )}
                                            defaultValue={heatChange}
                                            onChangeCommitted={setHeatChaneNewValue}
                                            valueLabelDisplay="on"
                                            marks={marks}
                                        />
                                        <span className='custom-track-label'>{content ? content["2ndParamaterText"] || "Energiekost" : ""}</span>
                                        <div className='d-inline-flex'>
                                            <span className='section-text-4'>
                                                {content?.startingValueText}
                                            </span>
                                            <input
                                                type='number'
                                                className='ml-2 bottom-card-inputs'
                                                min="0"
                                                defaultValue={heat}
                                                onKeyPress={(e)=> {
                                                    if (e.code === 'Minus') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e)=>setHeat(e.target.value)}
                                            />
                                        </div>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div>
                                    <Box className='position-relative'>
                                        <CustomSlider
                                            aria-label="Custom marks"
                                            defaultValue={CO2Change}
                                            onChangeCommitted={setCO2ChaneNewValue}
                                            valueLabelDisplay="on"
                                            step={1}
                                            valueLabelFormat={(value)=> (-5 + (value * 0.1)).toFixed(2)}
                                            marks={marks}
                                        />
                                        <span className='custom-track-label'>{content ? content["3rdParamaterText"] || "CO2" : ""}</span>
                                        <div className='d-inline-flex'>
                                            <span className='section-text-4'>
                                                {content?.startingValueText}
                                            </span>
                                            <input
                                                type='number'
                                                className='ml-2 bottom-card-inputs'
                                                min="0"
                                                defaultValue={CO2}
                                                onKeyPress={(e)=> {
                                                    if (e.code === 'Minus') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e)=>setCO2(e.target.value)}
                                            />
                                        </div>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='mt-4'>
                            <FillButton
                                name={content?.calculateButton || 'Berekent'}
                                loading={props.reportLoading}
                                onClickHandler={submitParams}
                                loadingMessage={content?.loadingButtonText}
                                // loading={props.addButtonLoadingState || false}
                            />
                        </div>
                    </Container>
                </div>
            </Grid>
        </Grid>
    )
}

const styles = props => ({
    colorPrimary: {
      backgroundColor: 'rgb(179, 180, 182,0.3)',
      borderRadius:20,
      height:5
    },
    barColorPrimary: {
      backgroundColor: '#702082',
      borderRadius:20
    }
});

const mapStateToProps = ({economicViability,map,auth}) => ({economicViability,map,auth})
export default connect(mapStateToProps, { setViabilityState, getContentByModuleLocation }) (withStyles(styles)( BottomCard));