import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Divider, FormControlLabel } from '@material-ui/core';
import {setSelectedMapLayer} from '../../redux/actions/map';
import { FillButton } from '../../helper/buttons';
import ErrorMessage from "../../helper/errorMessage";
import { CustomRadio } from '../../helper/customRadio';
import Grid from "@material-ui/core/Grid";

function InsideContainer (props) {
    useEffect(() => {
        console.log('updated')
    }, [props.radioList,props.fields,props.submitError])
  
    return (
        <>
            <div className='container'>
                <div className='sidepanel-inside p-3'>
                    <Grid container direction="row">
                        <Grid item xs={12} sm={4} className='sidepanel-radioList mt-3'>
                            {props?.radioList?.map((listItem,index)=>(
                                <div key={index}>
                                <FormControlLabel
                                // className="ps-4"
                                control={
                                    <CustomRadio
                                        id={`${index}`}
                                        checked={listItem.value}
                                        onChange={props.handleRadio}
                                        value={listItem.value}
                                        name={props.listName}
                                    />
                                }
                                label={listItem.label}
                                />
                                </div>
                            ))}

                        </Grid>
                        <Grid item xs={1} className='center sidepanel-vertical p-0 m-0'>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={12} sm={7} className='sidepanel-radioList pl-0 '>
                            <div className='row pl-0 pr-3'>
                                {props.fields?.map((obj, fieldsIndex)=> (
                                  <div key={fieldsIndex}>
                                    <div className="ellipses fontSize w-90percent mt-1" title={obj.label}>{obj.name} {obj.unit ? `(${obj.unit})`: ''}</div>
                                    {obj.name =='*Jaar'?
                                        <select
                                            style={{height:30}}
                                            onChange={(e)=>props.handleFields(e,fieldsIndex,props.name)}
                                            className={obj.error?'sidepanel-input-error':'sidepanel-sm-input' }
                                            defaultValue={'2020'}
                                        >
                                            {props.options.map((opt,index)=>(
                                                <option
                                                    key={index}
                                                    value={opt}>
                                                    {opt}
                                                </option>
                                            ))}

                                        </select>
                                        :
                                        <input
                                            type={obj.type}
                                            min="0"
                                            onKeyPress={(e)=> {
                                                if (e.code === 'Minus') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            className= {
                                                props.name == 'first' &&
                                                obj.error?'sidepanel-input-error':'sidepanel-sm-input'
                                            }
                                            onChange={(e)=>props.handleFields(e,fieldsIndex,props.name)}
                                            value={props.name == 'first'?obj.value:obj.value2}

                                        />
                                    }
                                  </div>
                                ))}
                            </div>
                            <span className='sidepanel-mandatory pt-2'>*{props?.obligedText}</span>
                        </Grid>
                    </Grid>
                    <div className='sidepanel-add'>
                        <span className='sidepanel-add-icon'>+</span>
                        <span className='pl-2'>{props?.addNewText}</span>
                    </div>
                    <div className='sidepanel-bottom-btn text-center '>
                    <FillButton
                        name={props.text}
                        onClickHandler={props.handleSubmit}
                        loadingMessage={props.addButtonLoadingState ? props.loaderMessage : ""}
                        loading={props.addButtonLoadingState || false}
                    />
                    {props.addButtonLoadingState &&
                        <FillButton
                            name="Cancel"
                            onClickHandler={props.cancelSubmission}
                        />
                    }
                </div>
                </div>
                {
                    props.submitError &&
                    <div className='mb-3'>
                    <ErrorMessage
                     error={true}
                     msg={'Gelieve alle verplichte velden in te vullen'}
                    />
                </div>
                }
        
            </div>
        </>
    )
}
const mapStateToProps = ({map,loader}) => ({map,loader})
export default connect(mapStateToProps, {setMapLayers: setSelectedMapLayer}) ( InsideContainer );