
import { combineReducers } from "redux";
import content from "./content";
import loader from "./loader";
import map from "./map";
import auth from "./auth";
import zoning from './zoning';
import economicViability from "./economicViability";

const rootReducer = combineReducers({
   auth:auth,
   content:content,
   loader:loader,
   map: map,
   zoning:zoning,
   economicViability:economicViability
});

export default rootReducer;
