import React, {useState, useEffect, useRef} from 'react'
import L from 'leaflet';
import qs from "querystring";
import parse from "html-react-parser";
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter, Prompt, useLocation } from 'react-router-dom';
import ReactLoader from 'react-fullpage-custom-loader';
import { useCookies } from "react-cookie";
import question from '../../../assets/question-mark-green.png';
import "leaflet/dist/leaflet.css";
import {getContentByModuleLocation} from '../../../redux/actions/content'
import {
    updateSimDetailsInStore,
    removeHeatDemand,
    cleanUpSimulation,
    updateSimulationCase, setSimulationHeatDemand, setSimulationStatus, setSimulationParams
} from "../../../redux/actions/simulation";
import { setAddedHeatSupply, removeHeatSupply } from "../../../redux/actions/map"
import { addMunicipalitiesInStore, getAllMunicipalitiesWithZoningPdfs } from "../../../redux/actions/zoning";
import SidePanel from '../../sidepanel/sidePanel';
import AnalysisMapInfoCard from "../informationCards/analysisMapInfoCard";
import {
    getStreetsMapLayer,
    getMunicipalityLayer,
    convertCQLObjToGeoServerCQL,
    prepareSelectedAreaObjFromSlug,
    getHeatDemandPopupHtml,
    getHeatSupplyPopupHtml,
    getZoningPlanMunicipalities,
    getZoningMunicipalitiesStyle, getHeatDemandLayerName,
    getStreetsMapLayerClustering
} from "../../../helper/mapHelpers";
import mapApiService from "../../../services/mapApiService";
import {
    DEFAULT_SIMULATION_STATE,
    ICON_PATH,
    MAP_LAYERS,
    RENOVATION_LEVEL_TO_YEAR, SEARCH_LEVELS, SIMULATION_STATUS,
    HEAT_STATES,
    HEAT_DEMAND_TYPES
} from "../../../constants/map";
import { ECONOMIC_VIABILITY_STATES } from "../../../constants/economicViability";
import {convertJSListToStringifiedTuple, textWithCapitalFirstLetter, valueExistsInListOfObjects } from "../../../helper/generalHelpers";
import {getMarkerIcon} from "../../../helper/mapHelpers";
import {
    setSearchLevel,
    setSelectedArea,
    setSelectedMapLayer
} from "../../../redux/actions/map";
import AlertMessage from "../../../helper/otherMessages";
import {prepareDataForSimulationReq} from "../../../helper/requestHelpers";
import simulationService from "../../../services/simulationService";
import {SimulationMapPageCard} from "../informationCards/simulationMapPageCard";
import {GEOSERVER_STORE, GEOSERVER_URL} from "../../../redux/constants/ApiEndPoints";
import {SimulationController} from "./SimulationController";
import {ROUTES} from "../../../constants/routes";
import ZoningLayerInfoCard from "../informationCards/ZoningLayerInfoCard";
import MainCard from '../../sidepanel/economicViability/mainCard';
import {FillButton} from "../../../helper/buttons";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function LeafletMap(props) {
    const [searchBtnClick, setSearchBtnClick] = useState(false);
    const [map, setMap] = useState(null)
    const [cookies, setCookies, removeCookies] = useCookies(['firstWebsiteVisit'])
    const [infoCardOpen, setInfoCardOpen] = useState(true)
    const [simulationPageInfoCardOpen, setSimulationPageInfoCardOpen] = useState(false)
    const [zoningLayerCardOpen, setZoningLayerCardOpen] = useState(false)
    const [mapLoading, setMapLoading] = useState(false)
    const [simulationLoading, setSimulationLoading] = useState(false)
    const [mapLoadingMsgs, setMapLoadingMsgs] = useState(["De simulatie wordt geladen...."])
    const [streetsCollectiveProbMap, setStreetsCollectiveProbMap] = useState(null)
    const [streetsHeatDemandMap, setStreetsHeatDemandMap] = useState(null)
    const [zoningGeoDataMap, setZoningGeoDataMap] = useState(null)
    const [streetsHeatCollectiveFromDecisionAlgo, setStreetsHeatCollectiveFromDecisionAlgo] = useState(null)
    const [zoningPlansMap, setZoningPlansMap] = useState(null)
    const [newHeatDemandsLayer, setNewHeatDemandsLayer] = useState(L.layerGroup())
    const [newHeatSuppliesLayer, setNewHeatSuppliesLayer] = useState(L.layerGroup())
    const [streetsHeatDemandMap2050, setStreetsHeatDemandMap2050] = useState(null)
    const [heatSources2020, setHeatSources2020] = useState(null)
    const [municipalityMap, setMunicipalityMap] = useState(null)
    const [addressPin, setAddressPin] = useState(null)
    const [mapView, setMapView] = useState({lat: 51.0950, long: 4.4478, zoom: 9})
    const [mapContainerClasses, setMapContainerClasses] = useState(["map-container"])
    const [showMapPagePopup, setShowMapPagePopup] = useState(false)
    const [mapPagePopupMessage, setMapPagePopupMessage] = useState("U kunt nu de locatie van de nieuwe warmtevraag op de kaart toevoegen")
    const [mapPagePopupType, setMapPagePopupType] = useState("info")
    const [addHeatDemandLoading, setAddHeatDemandLoading] = useState(false)
    const [addHeatSupplyLoading, setAddHeatSupplyLoading] = useState(false)
    const [simulationCalculating, setSimulationCalculating] = useState(false)
    const [simulationUpdating, setSimulationUpdating] = useState(false)
    const [wmsInAction,setWmsInAction] = useState(false);
    const [layersToShowInCollective,setLayersToShowInCollective] = useState([`${GEOSERVER_STORE}:clusterout_case_1`]);
    const [layersToShowInHeatDemand,setLayersToShowInHeatDemand] = useState([`${GEOSERVER_STORE}:streetsDemandView`]);
    const [layersToShowInHeatDemand2050,setLayersToShowInHeatDemand2050] = useState([`${GEOSERVER_STORE}:streetsDemandView2050`]);
    const [layersToShowInHeatSupply,setLayersToShowInHeatSupply] = useState([`${GEOSERVER_STORE}:heat_source_2020`]);
    const [layerCollectiveCQL,setLayerCollectiveCQL] = useState({streets: {sector_query: null, case_query: null}, mun_boundary: null});
    const [layerHeatDemandCQL,setLayerHeatDemandCQL] = useState({streets: null, mun_boundary: null});
    const [layerHeatSupplyCQL,setLayerHeatSupplyCQL] = useState({points: null, mun_boundary: null});
    const [mapUpdated,setMapUpdated] = useState(1);
    const [simulationNameFormOpen,setSimulationNameFormOpen] = useState(false);
    const [simulationDiscardFormOpen,setSimulationDiscardFormOpen] = useState(false);
    const [selectedMunicipalityOnZoning, setSelectedMunicipalityOnZoning] = useState(null);
    const [slug, setSlug] = useState(qs.parse(props.location.search.slice(1,), "&"));
    const [mapLock, setMapLock] = useState(false);
    const [clusterStreetsLayer, setClusterStreetsLayer] = useState(null);
    const [toggleMapServiceText, setToggleMapServiceText] = useState("");
    const [toggleMapServiceTooltipText, setToggleMapServiceTooltipText] = useState("");

    const selectedArea = useSelector(state=> state.map.selectedArea)
    const selectedAreaLevel = useSelector(state=> state.map.searchLevel)
    const selectedMapLayer = useSelector(state=> state.map.selectedMapLayer)
    const simulationParams = useSelector(state=> state.map.simulationParams)
    const simulationHeatDemands = useSelector(state=> state.map.simulationHeatDemands)
    const selectedSimDetails = useSelector(state=> state.map.selectedSimulationDetails)

    const [bannerContent, setBannerContent] = useState(undefined)
    const [sidePanelContent,setSidePanelContent] = useState(undefined);
    const [reportContent,setReportContent] = useState(undefined);

    const dispatch = useDispatch()
    const smallScreen = useMediaQuery('(max-width: 800px)')
    const location = useLocation()
    const checkAuth = document.cookie.indexOf('access_token') == -1 ? false : true;
    const mapCardRef = useRef(null)

    useEffect(()=> {
        handleSlugUpdate(slug)
    }, [])

    useEffect(() => {
        if (sidePanelContent)
            if (wmsInAction) {
                setToggleMapServiceText(sidePanelContent.toggleWMSToWFSText)
                setToggleMapServiceTooltipText(sidePanelContent.toggleWMSToWFSTextTooltip)
            }
            else {
                setToggleMapServiceText(sidePanelContent.toggleWFSToWMSText)
                setToggleMapServiceTooltipText(sidePanelContent.toggleWFSToWMSTextTooltip)
            }
    }, [sidePanelContent, wmsInAction])

    useEffect(()=> {
        if (cookies.firstWebsiteVisit === undefined) {
            setCookies('firstWebsiteVisit', true, {
                sameSite: 'strict',
                path: '/map',
                withCredentials: true,
                expires: new Date(new Date().getTime() + 2.628e+6 * 1000)
            })
            setInfoCardOpen(true)
        }
        else {
            setCookies('firstWebsiteVisit', false, {
                sameSite: 'strict',
                path: '/map',
                withCredentials: true,
                expires: new Date(new Date().getTime() + 2.628e+6 * 1000)
            })
        }
    }, [])

    useEffect(()=> {
        ;(async() => {
            let page = '';
            // TODO: Not sure what 2 means. Confirm with Ameen. Happens when you push to map page from searchbar
            if (!props.auth.active_view || ["0", "2"].includes(props.auth.active_view)) {
                let {
                    banner,
                    sidePanel,
                    ...cardData
                } = await props.getContentByModuleLocation('analysisPage');

                setBannerContent(cardData);
                setSidePanelContent(sidePanel?.attributes);
            }else if (props.auth.active_view == 1){
                let {
                    sidePanel,
                    energyInfo,
                    areaInfo,
                    level,
                    ...cardData
                } = await props.getContentByModuleLocation('simulationPage');
                if (selectedMapLayer === MAP_LAYERS.HEAT_DEMAND_2050)
                    dispatch(setSelectedMapLayer(MAP_LAYERS.HEAT_DEMAND))

                setReportContent( {areaInfo, energyInfo, level});
                setBannerContent(cardData);
                setSidePanelContent(sidePanel?.attributes);
            }
        })()
    }, [props.auth.active_view])

    useEffect(()=> {
        let baseMap = L.map('map', {
            zoomControl: false,
//            scrollWheelZoom: false,
            maxZoom: 20
        }).setView([51.048301133312265, 4.253082275390626],  9)
        // OSM Map: http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
        let backgroundMapLayer = L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
            {
                title: "basemap",
                maxZoom: 40,
                attribution: `<a href="https://carto.com/about-carto/">© Carto</a>` + ', ' + `<a href="http://osm.org/copyright">© OpenStreetMap </a>` + 'contributors'
            })
        let imageryLayer = L.tileLayer(
            'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            {
                attribution: `&copy; `+`<a href="http://www.esri.com/">Esri</a>`,
                maxZoom: 40,
            })
        backgroundMapLayer.addTo(baseMap)
        addMapHandlers(baseMap)
        setMap(baseMap)
        let zoomControlLocal = new L.Control.Zoom({
            position: "topleft"
        }).addTo(baseMap)
        props.addMunicipalitiesInStore()
        // to update zoom position
        zoomControlLocal.getContainer().parentNode.classList.add('zoom-controls')
    }, [])

    useEffect(()=> {
        if (map) {
            ;(async () => {
                await handleWMSMapLoading()
            })()
        }
    }, [map, layersToShowInCollective, layersToShowInHeatDemand, layersToShowInHeatDemand2050, layerCollectiveCQL, layerHeatDemandCQL])

    useEffect(()=> {
        if (map && !props.zoning.municipalities_loading && props.zoning.municipalities_geometry) {
            ;(async ()=> await handleZoningPlanLoading())()
        }
    }, [map, props.zoning.municipalities_loading, props.zoning.municipalities_geometry])

    useEffect(()=> {
        ;(async ()=> {
                if (zoningPlansMap) {
                    const municipalitiesWithZoningRes = await props.getAllMunicipalitiesWithZoningPdfs()
                    if (!municipalitiesWithZoningRes.errors.length) {
                        const munisWithZoning = municipalitiesWithZoningRes.result.data
                        zoningPlansMap.setStyle((feature)=> getZoningMunicipalitiesStyle(feature, munisWithZoning, mapView.zoom, selectedMunicipalityOnZoning))
                    }
                }
            })()
    }, [mapView.zoom, selectedMunicipalityOnZoning])

    useEffect(()=> {
        if (selectedAreaLevel === SEARCH_LEVELS.MUNICIPALITY && selectedArea.municipalityId && wmsInAction) {
            setMunicipalityWithStreetsActive()
        }
        else {
            setMunicipalityInActive()
        }
        if (selectedArea.municipalityName)
            setSelectedMunicipalityOnZoning(selectedArea.municipalityName)
    }, [selectedAreaLevel, wmsInAction, selectedArea.municipalityId, selectedArea.municipalityName])

    useEffect(()=> {
        if (map) {
            ;(async () => {
                if (searchBtnClick)
                    setSearchBtnClick(false);

                if (addressPin && map.hasLayer(addressPin)) {
                    map.removeLayer(addressPin)
                }
                if (selectedAreaLevel === SEARCH_LEVELS.HOUSE && selectedArea.addressId) {
                    try {
                        const result = await mapApiService.getHouseLevelMap(selectedArea.addressId)
                        let centerCoordinates = result.data["address_location"].coordinates
                        setMapView({
                            lat: centerCoordinates[1],
                            long: centerCoordinates[0],
                            zoom: map.getBoundsZoom(L.geoJSON(result.data["extents"]).getBounds())
                        })
                        const centerPin = L.marker(L.latLng(centerCoordinates[1], centerCoordinates[0]), {
                            title: "centerPinMap",
                            icon: getMarkerIcon()
                        }).bindPopup('<div class="popup">' +
                            '<b>' + "Huisnummer: " + '</b>' + selectedArea.houseNo + '<br>' +
                            '<b>' + "Straat: " + '</b>' + textWithCapitalFirstLetter(selectedArea.streetName) + '<br>' +
                            '<b>' + "Gemeente: " + '</b>' + selectedArea.municipalityName
                            +
                            '</div>')
                        setAddressPin(centerPin);
                    } catch (err) {
                        console.log(err)
                    }
                } else if (selectedAreaLevel === SEARCH_LEVELS.MUNICIPALITY && selectedArea.municipalityId.length) {
                    const municipalityOutlineRes = await mapApiService.getMunicipalityDetails(selectedArea.municipalityId)
                    if (municipalityOutlineRes) {
                        let municipalityLayer = getMunicipalityLayer(municipalityOutlineRes.data.municipality_geometry)
                        setMunicipalityMap(municipalityLayer)
                        map.fitBounds(municipalityLayer.getBounds())
                    }
                }
                else {
                    if (municipalityMap && map.hasLayer(municipalityMap))
                        map.removeLayer(municipalityMap)
                    if (addressPin && map.hasLayer(addressPin))
                        map.removeLayer(addressPin)
                    setMunicipalityInActive()
                }
            })()
        }
    }, [map, selectedAreaLevel, selectedArea.addressId, selectedArea.municipalityId])

    useEffect(() => {
        if (addressPin){
            map.removeLayer(addressPin);
            if (selectedMapLayer !== MAP_LAYERS.ZONING_PLANS)
                addressPin.addTo(map);

            if (addressPin.bringToFront) {
                addressPin.bringToFront();
            }
        }

    }, [addressPin, selectedMapLayer])

    useEffect(()=> {
        ;(async ()=> await reloadMapLayers())()
    }, [map, selectedAreaLevel, selectedMapLayer, streetsHeatDemandMap, streetsCollectiveProbMap, zoningPlansMap, streetsHeatDemandMap2050, clusterStreetsLayer, streetsHeatCollectiveFromDecisionAlgo])

    useEffect(()=> {
        if (map && zoningGeoDataMap) {
            if (zoningGeoDataMap && map.hasLayer(zoningGeoDataMap))
                map.removeLayer(zoningGeoDataMap)
            if (mapView.zoom >= 11 && selectedMapLayer === MAP_LAYERS.ZONING_PLANS) {
                zoningGeoDataMap.addTo(map)
                zoningGeoDataMap.bringToFront()
            }
            else
                map.removeLayer(zoningGeoDataMap)
        }
    }, [map, zoningGeoDataMap, mapView.zoom])

    useEffect(()=> {
        if (map && ![0, undefined].includes(mapView.lat)) {
            if (map.getCenter().lat !== mapView.lat || map.getCenter().lng !== mapView.long || map.getZoom() !== mapView.zoom) {
                map.setView([mapView.lat, mapView.long], mapView.zoom)
            }
        }
        if (mapView.zoom >= 14) {
            if (wmsInAction) {
                setWmsInAction(false)
                map.removeLayer(streetsCollectiveProbMap)
            }
            ;(async () => await handleWFSMapLoading())()
        }
        else {
            if (!wmsInAction)
                setWmsInAction(true)
            ;(async ()=> await handleWMSMapLoading(mapUpdated===1))()
        }
    }, [map, mapView, mapUpdated, selectedArea, props.map.selectedCluster, props.economicViability.selected_state])
//     if(map) {
//         var layers = [];
//         console.log('map layer', map.hasLayer(municipalityMap))
//         map.eachLayer(function (layer) {
//             layers.push(layer);
//         });
//         console.log(layers)
//     }
    useEffect(()=> {
        if (wmsInAction && streetsCollectiveProbMap) {
            const layerTitle = streetsCollectiveProbMap.options?.title
            if (layerTitle === "clusteringLayer") {
                setMapUpdated(prevValue=> prevValue + 1)
            }
        }
    }, [wmsInAction, streetsCollectiveProbMap])

    useEffect(()=> {
        setInfoCardOpen(false)
        setZoningLayerCardOpen(false)
        setSimulationPageInfoCardOpen(false)
        if(checkAuth && props.auth.active_view != 0 && selectedMapLayer !== MAP_LAYERS.ZONING_PLANS) {
            setSimulationPageInfoCardOpen(true)
        }
        else if (selectedMapLayer === MAP_LAYERS.ZONING_PLANS)
            setZoningLayerCardOpen(true)
        else {
            setInfoCardOpen(true)
        }
    }, [checkAuth, props.auth.active_view, selectedMapLayer])

    useEffect(()=> {
        if (simulationParams.simulation_id) {
            const runDecisionAlgo = ![SIMULATION_STATUS.CALCULATED, SIMULATION_STATUS.SAVED].includes(props.map.simulationStatus)
            if (runDecisionAlgo) {
                setSimulationLoading(true)
                setMapLoading(false)
                setMapLoadingMsgs(["De simulatie wordt geladen...."])
                setMapLoading(true)
            }
            ;(async ()=> {
                const simDetailsRes = await simulationService.getSimulationDetails(simulationParams.simulation_id,
                    runDecisionAlgo, simulationParams.simulation_state === "saved")
                props.updateSimDetailsInStore(simulationParams.simulation_id, simDetailsRes, runDecisionAlgo)
                if (runDecisionAlgo) {
                    const mapView = simDetailsRes.data.simulation_details.map_view
                    setMapView({lat: mapView.lat, long: mapView.lng, zoom: mapView.zoom})
                    setMapLoading(false)
                    setSimulationLoading(false)
                }
            })();
        }
    }, [simulationParams.simulation_id, mapUpdated])

    useEffect(()=> {
        let collectiveCQLForDecAlgo = {
            streets: {
                sector_query: layerCollectiveCQL.streets.sector_query
            },
            mun_boundary: layerCollectiveCQL.mun_boundary
        }
        if (selectedSimDetails.simulation_id !== null && selectedSimDetails?.simulationData.hasOwnProperty("features") && selectedSimDetails.statistical_sectors.length) {
            if (streetsHeatCollectiveFromDecisionAlgo && map.hasLayer(streetsHeatCollectiveFromDecisionAlgo))
                map.removeLayer(streetsHeatCollectiveFromDecisionAlgo)
            setStreetsHeatCollectiveFromDecisionAlgo(getStreetsMapLayerClustering(selectedSimDetails.simulationData, "decisionAlgoLayer", null, {cluster_id: props.map.selectedClusterInGeoJSON}, props.economicViability.selected_state !== ECONOMIC_VIABILITY_STATES.IDLE))
            collectiveCQLForDecAlgo.streets.sector_query = `sector_id NOT IN ${convertJSListToStringifiedTuple(selectedSimDetails.statistical_sectors)}`
            if (selectedAreaLevel === SEARCH_LEVELS.MUNICIPALITY) {
                collectiveCQLForDecAlgo.mun_boundary = `naam='${selectedArea.municipalityName}'`
            }
        }
        else {
            setStreetsHeatCollectiveFromDecisionAlgo(null)
        }
        setLayerCollectiveCQL((prevState)=> {
            return {
                streets: {
                    sector_query: collectiveCQLForDecAlgo.streets.sector_query,
                    case_query: prevState.streets.case_query
                },
                mun_boundary: collectiveCQLForDecAlgo.mun_boundary
            }
        })
        ;(async ()=> await reloadMapLayers())()
    }, [selectedSimDetails.simulation_id, selectedSimDetails.simulationData, props.economicViability.selected_state, props.map.selectedClusterInGeoJSON])

    const handleHeatDemandRemove = async (index, heatDemandId=null)=> {
        let simDeleteRes = null
        const demandStatus = simulationHeatDemands[index].addition_status
        if (demandStatus !== "added")
            simDeleteRes = await simulationService.deleteHeatDemand(heatDemandId, simulationParams.simulation_state==="saved")
        props.removeHeatDemand(index)
        if (demandStatus === "added" || simDeleteRes?.data === 1) {
            showPopupOnMap("Warmtevraag kon niet met succes worden verwijderd", "success")
            setMapUpdated(mapUpdated+1)
        }
        else
            showPopupOnMap("Warmtevraag kon niet succesvol worden verwijderd", "error")
    }

    const handleHeatSupplyRemove = (index, heatSupplyId=null)=> {
        let result = props.removeHeatSupply(heatSupplyId, index)
        if (result) {
            showPopupOnMap("Warmtetoevoer succesvol verwijderd", "success")
            setMapUpdated(mapUpdated+1)
        }
        else
            showPopupOnMap("Warmtetoevoer kan niet succesvol worden verwijderd", "error")
    }

    useEffect(()=> {
        if (newHeatDemandsLayer) {
            newHeatDemandsLayer.clearLayers()
            simulationHeatDemands.forEach((demand, index)=> {
                let addedPoint = L.marker(L.latLng(demand.location.lat, demand.location.lng), {
                    title: "newHeatDemandMarker",
                    icon: getMarkerIcon(demand.type === HEAT_DEMAND_TYPES.RESIDENTIAL ? ICON_PATH.RESIDENTIAL : demand.type === HEAT_DEMAND_TYPES.INDUSTRIAL ? ICON_PATH.INDUSTRIAL : ICON_PATH.SCHOOL)
                }).bindPopup(getHeatDemandPopupHtml(demand, index, handleHeatDemandRemove))
                newHeatDemandsLayer.addLayer(addedPoint)
            })
        }
    }, [newHeatDemandsLayer, simulationHeatDemands])

    useEffect(()=> {
        if (newHeatSuppliesLayer) {
            newHeatSuppliesLayer.clearLayers()
            props.map.simulationHeatSupplies.forEach((supply, index)=> {
                let addedPoint = L.marker(L.latLng(supply.location.lat, supply.location.lng), {
                    title: "newHeatSupplyMarker",
                    icon: getMarkerIcon(ICON_PATH.INDUSTRIAL_LIGHT, 45, 45, 22, 22)
                }).bindPopup(getHeatSupplyPopupHtml(supply, index, handleHeatSupplyRemove))
                newHeatSuppliesLayer.addLayer(addedPoint)
            })
        }
    }, [newHeatSuppliesLayer, props.map.simulationHeatSupplies])

    useEffect(()=> {
        props.updateSimulationCase(simulationParams)
    }, [simulationParams.price, simulationParams.connection_cost, simulationParams.renovation_level.res, simulationParams.renovation_level.nres])

    // cleanup for removing all layers from map
    useEffect(()=> {
        return ()=> {
            if (checkAuth)
                props.cleanUpSimulation()
            removeStreetLayers([], true)
        }
    }, [])

    useEffect(()=> {
        if (!checkAuth || props.auth.active_view != 1 || !map || !layersToShowInCollective.length)
            return
        if (props.map.selectedCluster) {
            ;(async ()=> {
                const clusterFeatures = await mapApiService.getRequestedMapArea(null, `${layersToShowInCollective[0]}`, "geom", `cluster_id='${props.map.selectedCluster}'`)
                const clusterHighlightedLayer = getStreetsMapLayerClustering(clusterFeatures.data, "clusterSelectedLayer", null)
                setClusterStreetsLayer(clusterHighlightedLayer)
            })()
        }
        else {
            if (clusterStreetsLayer && map.hasLayer(clusterStreetsLayer))
                map.removeLayer(clusterStreetsLayer)
            setClusterStreetsLayer(null)
        }
    }, [map, props.map.selectedCluster])

    useEffect(()=> {
        const squeezedMapClassIndexContainer = mapContainerClasses.indexOf("inner-map-squeezed")
        if ((props.map.selectedCluster || props.map.selectedClusterInGeoJSON) && props.economicViability.selected_state === ECONOMIC_VIABILITY_STATES.CALCULATED) {
            if (squeezedMapClassIndexContainer === -1) {
                setMapContainerClasses(prevClasses=> {
                    return [...prevClasses, smallScreen ? '' : "inner-map-squeezed"]
                })
            }
            setMapLock(true)
            map.off('click')
            map._handlers.forEach(function(handler) {
                handler.disable()
            })
        }
        else if (map) {
            if (squeezedMapClassIndexContainer !== -1) {
                setMapContainerClasses(prevClasses=> {
                    const newClasses = [...prevClasses]
                    newClasses.splice(squeezedMapClassIndexContainer)
                    return newClasses
                })
            }
            setMapLock(false)
            map._handlers.forEach(function(handler) {
                handler.enable()
            })
            if (!props.map.selectedCluster && clusterStreetsLayer && map.hasLayer(clusterStreetsLayer))
                map.removeLayer(clusterStreetsLayer)
        }
    }, [map, props.map.selectedCluster, props.economicViability.selected_state])

    // refresh map to adjust to changed dimensions
    useEffect(()=> {
        if (map)
            setTimeout(()=> map.invalidateSize(), 400);
    }, [mapContainerClasses])

    useEffect(()=> {
        if (clusterStreetsLayer) {
            const clusterLayerBounds = clusterStreetsLayer.getBounds()
            const clusterLayerCenter = clusterLayerBounds.getCenter()
            setMapView({zoom: map.getBoundsZoom(clusterLayerBounds), lat: clusterLayerCenter.lat, long: clusterLayerCenter.lng})
        }
        else {
            setMapView({lat: 51.0950, long: 4.4478, zoom: 9})
        }
    }, [clusterStreetsLayer, mapContainerClasses])

    useEffect(()=> {
        if (mapCardRef && (zoningLayerCardOpen || simulationPageInfoCardOpen || infoCardOpen))
            window.scrollTo(0, mapCardRef.current.offsetTop)
    }, [zoningLayerCardOpen, simulationPageInfoCardOpen, infoCardOpen])

    useEffect(()=> {
        if (props.map.selectedClusterInGeoJSON && selectedSimDetails.simulationData.features) {
            if (streetsHeatCollectiveFromDecisionAlgo) {
                const filteredFeatures = selectedSimDetails.simulationData.features.filter(item=> item.properties.cluster_id === props.map.selectedClusterInGeoJSON)
                setClusterStreetsLayer(getStreetsMapLayerClustering({...selectedSimDetails.simulationData, features: [...filteredFeatures]}, "clusterSelectedLayer", null))
            }
        }
        else if (!props.map.selectedCluster) {
            if (clusterStreetsLayer && map.hasLayer(clusterStreetsLayer))
                map.removeLayer(clusterStreetsLayer)
            setClusterStreetsLayer(null)
        }
    }, [props.map.selectedClusterInGeoJSON, streetsHeatCollectiveFromDecisionAlgo, selectedSimDetails.simulationData])

    const handleSlugUpdate = (slugLocal)=> {
        if (Object.keys(slugLocal).length) {
            let parsedMapCenter = JSON.parse(slugLocal.mapCenter) || [0, 0]
            dispatch(setSelectedMapLayer(slugLocal.layer || MAP_LAYERS.COLLECTIVE_HEAT_MAPPING))
            dispatch(setSelectedArea(prepareSelectedAreaObjFromSlug(slugLocal)))
            if (slugLocal.mapZoom != 0 && parsedMapCenter !== [0, 0]) {
                setMapView({lat: parsedMapCenter[0], long: parsedMapCenter[1], zoom: slugLocal.mapZoom})
            }
            dispatch(setSearchLevel(slugLocal.level || SEARCH_LEVELS.COMPLETE))
        }
    }

    const handleZoningPlanLoading = async ()=> {
        if (!simulationLoading)
            setMapLoading(false)
        let municipalitiesWithZoning = await props.getAllMunicipalitiesWithZoningPdfs()
        if(!municipalitiesWithZoning.errors.length) {
            setZoningPlansMap(getZoningPlanMunicipalities(props.zoning.municipalities_geometry, municipalitiesWithZoning.result.data, (selectedMuni)=> {setSelectedMunicipalityOnZoning(selectedMuni)}, selectedMunicipalityOnZoning, mapView.zoom))
        }
    }

    const removeMapPin = ()=> {
        if (searchBtnClick && map && addressPin && map.hasLayer(addressPin)){
            map.removeLayer(addressPin)
            console.log("Working here");
        }
    }
    const removeStreetLayers = (excludedLayersFromDeletion=[], removeAllLayers=false)=> {
        // each geojson feature is taken as a layer, does;n't get removed
        // by just removing the layer
        if (map) {
            map.eachLayer(function (layer) {
                if (!removeAllLayers && (!layer.options || !["clusterSelectedLayer", "newHeatDemandMarker", "centerPinMap", "basemap", ...excludedLayersFromDeletion].includes(layer.options.title))) {
                    map.removeLayer(layer)
                }
            });
            if (streetsCollectiveProbMap && map.hasLayer(streetsCollectiveProbMap)) {
                map.removeLayer(streetsCollectiveProbMap)
            } else if (streetsHeatDemandMap && map.hasLayer(streetsHeatDemandMap)) {
                map.removeLayer(streetsHeatDemandMap)
            } else if (streetsHeatDemandMap2050 && map.hasLayer(streetsHeatDemandMap2050))
                map.removeLayer(streetsHeatDemandMap2050)
            else if (zoningPlansMap && map.hasLayer(zoningPlansMap))
                map.removeLayer(zoningPlansMap)
        }
    }

    const removePointsLayer = ()=> {
        if (map && heatSources2020 && map.hasLayer(heatSources2020))
            map.removeLayer(heatSources2020)
    }

    const addMapHandlers = (baseMap)=> {
        baseMap.on("zoomend", (zoomEvent)=> {
            setMapView({lat: baseMap.getCenter().lat, long: baseMap.getCenter().lng, zoom: zoomEvent.target._zoom})
        })
        baseMap.on("moveend", (event)=> {
            let mapCenterLocal
            if (map)
                mapCenterLocal = map.getCenter()
            else
                mapCenterLocal = baseMap.getCenter()
            setMapView({lat: mapCenterLocal.lat, long: mapCenterLocal.lng, zoom: baseMap.getZoom()})
        })
    }

    const handleWMSMapLoading = async (resetBounds=false)=> {
        removeMapPin();
        removeStreetLayers()
        try {
            let heatProbOptions = {
                title: "heatDemand",
                layers: [layersToShowInCollective],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly"
            }
            let heatDemandOptions = {
                title: "heatCollective",
                layers: [layersToShowInHeatDemand],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly"
            }
            let heatSupplyOptions = {
                title: "heatSupply",
                layers: [layersToShowInHeatSupply],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly"
            }
            let zoningGeoDataOptions = {
                title: "zoningGeoData",
                layers: [`${GEOSERVER_STORE}:ZoningGeoData`],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly"
            }
            if ((layerCollectiveCQL.streets.sector_query || layerCollectiveCQL.streets.case_query) || layerCollectiveCQL.mun_boundary) {
                heatProbOptions["CQL_FILTER"] = convertCQLObjToGeoServerCQL([[layerCollectiveCQL.streets.sector_query, layerCollectiveCQL.streets.case_query], layerCollectiveCQL.mun_boundary]);
            }
            if (layerHeatDemandCQL.streets || layerHeatDemandCQL.mun_boundary) {
                heatDemandOptions["CQL_FILTER"] = convertCQLObjToGeoServerCQL([layerHeatDemandCQL.streets, layerHeatDemandCQL.mun_boundary])
            }
            if (layerHeatSupplyCQL.points || layerHeatSupplyCQL.mun_boundary) {
                heatSupplyOptions["CQL_FILTER"] = convertCQLObjToGeoServerCQL([layerHeatSupplyCQL.points, layerHeatSupplyCQL.mun_boundary])
            }
            let heatTypeProbLayer = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                ...heatProbOptions
            })
            setStreetsCollectiveProbMap(heatTypeProbLayer)

            let heatDemandLayer = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                ...heatDemandOptions
            })
            setStreetsHeatDemandMap(heatDemandLayer)

            let heatDemandLayer2050 = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                ...heatDemandOptions,
                layers: [layersToShowInHeatDemand2050]
            })
            setStreetsHeatDemandMap2050(heatDemandLayer2050)

            let heatSupplyLayer = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                ...heatSupplyOptions
            })
            setHeatSources2020(heatSupplyLayer)

            let zoningLayer = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                ...zoningGeoDataOptions
            })
            setZoningGeoDataMap(zoningLayer)
        } catch (err) {
                console.log(err)
        }
    }

    const handleWFSMapLoading = async ()=> {
        try {
            removeStreetLayers(streetsHeatCollectiveFromDecisionAlgo ? ["decisionAlgoLayer"] : [])
            let bounds = map.getBounds()
            // const streetCollectiveHeatingRes = await mapApiService.getRequestedMapArea(bounds, MAP_LAYERS.COLLECTIVE_HEAT_MAPPING, "geom", layerCollectiveCQL.streets && streetsHeatCollectiveFromDecisionAlgo ? convertCQLObjToGeoServerCQL([layerCollectiveCQL.streets]) : null)
            const streetCollectiveHeatingRes = await mapApiService.getRequestedMapArea(bounds, layersToShowInCollective[0], "geom")
            let new_heat_demands = {}
            if (checkAuth && simulationParams.simulation_id) {
                let simDetailsRes = await simulationService.getSimulationDetails(simulationParams.simulation_id, false, props.map.simulationStatus === SIMULATION_STATUS.SAVED)
                new_heat_demands = simDetailsRes.data.heat_demands_on_segments
            }
            const streetHeatDemandRes = await mapApiService.getRequestedMapArea(bounds, layersToShowInHeatDemand[0])
            const streetHeatDemandRes2050 = await mapApiService.getRequestedMapArea(bounds, layersToShowInHeatDemand2050[0])

            //TODO: Improve this error checking mechanism
            if ((typeof streetCollectiveHeatingRes.data === "object" && typeof streetHeatDemandRes2050.data === "object" && typeof streetHeatDemandRes.data === "object") && (!streetCollectiveHeatingRes.errors || !streetCollectiveHeatingRes.errors.length) && (!streetCollectiveHeatingRes.errors || !streetHeatDemandRes.errors.length) && (!streetHeatDemandRes2050.errors || !streetHeatDemandRes2050.errors.length)) {
                setLayerInState(getStreetsMapLayerClustering(streetCollectiveHeatingRes.data, "clusteringLayer", null, {cluster_id: props.map.selectedCluster}, props.economicViability.selected_state === ECONOMIC_VIABILITY_STATES.CLICK_ON_MAP), MAP_LAYERS.COLLECTIVE_HEAT_MAPPING, false)
                setLayerInState(getStreetsMapLayer(streetHeatDemandRes.data, MAP_LAYERS.HEAT_DEMAND, new_heat_demands, "heatDemands", simulationParams), MAP_LAYERS.HEAT_DEMAND, false)
                setLayerInState(getStreetsMapLayer(streetHeatDemandRes2050.data, MAP_LAYERS.HEAT_DEMAND_2050, new_heat_demands), MAP_LAYERS.HEAT_DEMAND_2050, false)
            } else {
                showPopupOnMap(streetCollectiveHeatingRes && streetCollectiveHeatingRes.errors ? streetCollectiveHeatingRes.errors[0] : "Unexpected Error in loading WFS", "error")
            }
        } catch (err) {
            console.log("err in map loading is ", err)
            showPopupOnMap("Kaart kon niet worden geladen", "error")
        }
    }

    const setMunicipalityWithStreetsActive = ()=> {
        setLayersToShowInHeatDemand([`${GEOSERVER_STORE}:${getHeatDemandLayerName(RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.res], RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.nres])}`, `${GEOSERVER_STORE}:Municipality`])
        setLayersToShowInCollective([`${GEOSERVER_STORE}:clusterout_case_${props.map.simulationCase}`, `${GEOSERVER_STORE}:Municipality`])
        setLayersToShowInHeatSupply([`${GEOSERVER_STORE}:heat_source_2020`, `${GEOSERVER_STORE}:Municipality`])
        setLayersToShowInHeatDemand2050([`${GEOSERVER_STORE}:streetsDemandView2050`, `${GEOSERVER_STORE}:Municipality`])
        setLayerHeatDemandCQL({
            streets: "INCLUDE",
            mun_boundary: `naam='${selectedArea.municipalityName}'`
        })
        setLayerCollectiveCQL((prevState)=> {
            return {
                streets: {
                    sector_query: selectedSimDetails.simulation_id ? layerCollectiveCQL.streets.sector_query : 'INCLUDE',
                    case_query: prevState.streets.case_query
                },
                mun_boundary: `naam='${selectedArea.municipalityName}'`
            }
        })
        setLayerHeatSupplyCQL({
            points: 'INCLUDE',
            mun_boundary: `naam='${selectedArea.municipalityName}'`
        })
    }

    const setMunicipalityInActive = ()=> {
        setLayersToShowInHeatDemand([`${GEOSERVER_STORE}:${getHeatDemandLayerName(RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.res], RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.nres])}`])
        setLayersToShowInHeatDemand2050([`${GEOSERVER_STORE}:streetsDemandView2050`])
        setLayersToShowInCollective([`${GEOSERVER_STORE}:clusterout_case_${props.map.simulationCase}`])

        setLayerHeatDemandCQL({
            streets: null,
            mun_boundary: null
        })
        setLayerCollectiveCQL((prevState)=> {
            return {
                streets: {
                    sector_query: selectedSimDetails.simulation_id ? layerCollectiveCQL.streets.sector_query : null,
                    case_query: prevState.streets.case_query
                },
                mun_boundary: null
            }
        })
        setLayerHeatSupplyCQL({
            points: null,
            mun_boundary: null
        })
    }

    const setLayerInState = (streetsLayer, mapLayer=MAP_LAYERS.COLLECTIVE_HEAT_MAPPING, fitToBounds=true, pointsGeometry=null)=> {
        if (mapLayer === MAP_LAYERS.COLLECTIVE_HEAT_MAPPING) {
            setStreetsCollectiveProbMap(streetsLayer)
        }
        else if (mapLayer === MAP_LAYERS.HEAT_DEMAND_2050) {
            setStreetsHeatDemandMap2050(streetsLayer)
        }
        else {
            setStreetsHeatDemandMap(streetsLayer)
        }
        if (fitToBounds)
            map.fitBounds(streetsLayer.getBounds())
    }

    const resetAdditionOnMap = ()=> {
        setAddHeatDemandLoading(false);
        setAddHeatSupplyLoading(false);
        L.DomUtil.removeClass(map._container,"add-point-active");
        map.off("click")

    }
    const addHeatDemandHandler = (newDemandInfo)=> {
        showPopupOnMap("U kunt nu de locatie van de nieuwe warmtevraag op de kaart toevoegen", "info")
        setAddHeatDemandLoading(true)
        L.DomUtil.addClass(map._container,"add-point-active");
        map.on('click', (event)=> {
            dispatch(setSimulationHeatDemand([...simulationHeatDemands, {
                connected_segment: null,
                addition_status: HEAT_STATES.ADDED,
                type: newDemandInfo.new_dev_type,
                power: newDemandInfo.MW,
                heat_demand: newDemandInfo.kWh,
                year: newDemandInfo.Jaar,
                length: newDemandInfo.L,
                location: {lat: event.latlng.lat, lng: event.latlng.lng}
            }]))
            dispatch(setSimulationStatus(SIMULATION_STATUS.UPDATED))
            resetAdditionOnMap()
        })
    }

    const addHeatSupplyHandler = (newSupplyInfo)=> {
        showPopupOnMap("U kunt nu de locatie van de nieuwe Warmtevoorziening op de kaart toevoegen", "info")
        setAddHeatSupplyLoading(true)
        L.DomUtil.addClass(map._container,"add-point-active");
        map.on('click', (event)=> {
            L.DomUtil.removeClass(map._container,"add-point-active");
            setAddHeatSupplyLoading(false)
            dispatch(setAddedHeatSupply([...props.map.simulationHeatSupplies, {
                ...newSupplyInfo,
                addition_status: HEAT_STATES.ADDED,
                location: {lat: event.latlng.lat, lng: event.latlng.lng}
            }]))
            dispatch(setSimulationStatus(SIMULATION_STATUS.UPDATED))
            map.off("click")
        })
    }

    const updateSimulationHandler = async (save=false, simName=null, simNameUpdated=false)=> {
        setSimulationNameFormOpen(false)
        if (save && props.map.simulationStatus === SIMULATION_STATUS.SAVED && !simNameUpdated) {
            showPopupOnMap("Werk de simulatieparameters bij of voeg nieuwe warmtevragen toe om opnieuw op te slaan")
            return
        }
        if (!save && [SIMULATION_STATUS.CALCULATED, SIMULATION_STATUS.SAVED].includes(props.map.simulationStatus)) {
            showPopupOnMap("Werk de simulatieparameters bij of voeg nieuwe warmtevragen toe om opnieuw te berekenen")
            return
        }
        // if there is a new heat demand basically or simulation params updated
        if ((simulationHeatDemands.length && (props.map.simulationStatus === SIMULATION_STATUS.IDLE || simulationHeatDemands.filter(item=> save ? [HEAT_STATES.ADDED, HEAT_STATES.CALCULATED].includes(item.addition_status) : item.addition_status === HEAT_STATES.ADDED).length)) || simNameUpdated || [SIMULATION_STATUS.TOUCHED_AFTER_CALCULATION, SIMULATION_STATUS.TOUCHED_AFTER_SAVING].includes(props.map.simulationStatus)) {
            try {
                setMapLoading(false)
                setMapLoadingMsgs(["Er wordt gezocht naar het dichtstbijzijnde straat segment voor de nieuw toegevoegde warmtevraag"])
                setMapLoading(true)
                let result
                const currentView = map.getCenter()
                let dataForSimulation = {
                    ...prepareDataForSimulationReq(simulationParams, simulationHeatDemands, save, simName),
                    map_view: {lat: currentView.lat, lng: currentView.lng, zoom: map.getZoom()}
                }

                if (save && simulationParams.simulation_saved_id && !simNameUpdated) {
                    result = await simulationService.updateSimulation(dataForSimulation)
                }
                else {
                    result = await simulationService.addNewSimulation(dataForSimulation)
                }

                if (result.errors.length) {
                    handleFailureFullSimulationAddition(result.errors[0])
                }
                else {
                    handleSuccessFullSimulationAddition(dataForSimulation, result.data, !save)
                }
            } catch (err) {
                handleFailureFullSimulationAddition(err || "Request Failed")
            }
        }
        // update simulation case for first map layer
        setLayersToShowInCollective((prevState=> {
            const newState = [...prevState]
            newState[0] = `${GEOSERVER_STORE}:clusterout_case_${props.map.simulationCase}`
            return newState
        }))
        // update 2nd map layer according to params
        const updatedDemandLayer = `${GEOSERVER_STORE}:${getHeatDemandLayerName(RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.res], RENOVATION_LEVEL_TO_YEAR[simulationParams.renovation_level.nres])}`
        if (layersToShowInHeatDemand.length > 1) {
            let localLayers = [...layersToShowInHeatDemand]
            localLayers[0] = updatedDemandLayer
            setLayersToShowInHeatDemand(localLayers)
        }
        else
            setLayersToShowInHeatDemand([updatedDemandLayer])
    }

    const handleSuccessFullSimulationAddition = (data, response, calculateMode=false)=> {
        setMapLoading(false)
        setMapLoadingMsgs(["De dichtstbijzijnde straat segmenten zijn gevonden en succesvol aangepast...."])
        setMapLoading(true)
        dispatch(setSimulationHeatDemand([]))
        dispatch(setSimulationParams({simulation_id: response.new_simulation_result ? response.new_simulation_result.id : data.id,
                 simulation_saved_id: calculateMode ? simulationParams.simulation_saved_id : response.new_simulation_result ? response.new_simulation_result.id : data.id,
                 simulation_state: calculateMode ? "unsaved" : "saved"
                 }))
        setMapLoading(false)
        setMapLoadingMsgs(["Het clustering algoritme wordt uitgevoerd met de geselecteerd simulatie parameters...."])
        setMapLoading(true)
        setMapUpdated(mapUpdated+1)
    }

    const handleFailureFullSimulationAddition = (err)=> {
        showPopupOnMap(err ? typeof err === "object" && err.length ? err[0] : err : "Nieuwe simulatie kon niet succesvol worden toegevoegd", "error")
    }

    const infoCardToggler = ()=> {
        if (infoCardOpen) {
            setInfoCardOpen(false)
            if (checkAuth && props.auth.active_view === 1 && selectedMapLayer !== MAP_LAYERS.ZONING_PLANS)
                setSimulationPageInfoCardOpen(true)
            else if (selectedMapLayer === MAP_LAYERS.ZONING_PLANS)
                setZoningLayerCardOpen(true)
        }
        else if (zoningLayerCardOpen) {
            setZoningLayerCardOpen(false)
            setInfoCardOpen(true)
        }
        else {
            setInfoCardOpen(true)
            setSimulationPageInfoCardOpen(false)
            setZoningLayerCardOpen(false)
        }
    }
    const showPopupOnMap = (text, type="info")=> {
        setMapPagePopupMessage(text)
        setMapPagePopupType(type)
        setShowMapPagePopup(true)
        setTimeout(() => {
            setShowMapPagePopup(false)
        }, 2500)
    }

    const handleMapServiceToggle = ()=> {
        if (wmsInAction) {
            setMapView({lat: map.getCenter().lat, long: map.getCenter().lng, zoom: 14})
        }
        else {
            setMapView({lat: map.getCenter().lat, long: map.getCenter().lng, zoom: 9})
        }
    }

    const mapDownloadHandler = async()=> {
        const bounds = map.getBounds()
        let shapefileUrl = await mapApiService.getRequestedMapArea(bounds, `shapefile_view_case_${props.map.simulationCase}`, "geom", null, "shapefile", true, 31370)
        window.open(shapefileUrl, '_blank')
        // var element = document.createElement('a');
        // element.setAttribute('href','data:text/plain;charset=utf-8, ' + encodeURIComponent(textInput));
        // element.setAttribute('download', filename);
        // document.body.appendChild(element);
        // element.click();
    }

    const reloadMapLayers = async ()=> {
        if (map && streetsHeatDemandMap && streetsCollectiveProbMap) {
            removeStreetLayers()
            removePointsLayer()
            if (!wmsInAction && selectedAreaLevel === SEARCH_LEVELS.MUNICIPALITY) {
                if (municipalityMap && !map.hasLayer(municipalityMap)) {
                    municipalityMap.addTo(map)
                }
            }
            if (selectedMapLayer === MAP_LAYERS.COLLECTIVE_HEAT_MAPPING) {
                streetsCollectiveProbMap.addTo(map)
                if (streetsHeatCollectiveFromDecisionAlgo && !map.hasLayer(streetsHeatCollectiveFromDecisionAlgo))
                    streetsHeatCollectiveFromDecisionAlgo.addTo(map)
                if (clusterStreetsLayer) {
                    if (map.hasLayer(clusterStreetsLayer))
                        map.removeLayer(clusterStreetsLayer)
                    clusterStreetsLayer.addTo(map)
                }
            } else if (selectedMapLayer === MAP_LAYERS.HEAT_DEMAND) {
                streetsHeatDemandMap.addTo(map)
                newHeatDemandsLayer.addTo(map)
            }
            else if (selectedMapLayer === MAP_LAYERS.HEAT_DEMAND_2050) {
                streetsHeatDemandMap2050.addTo(map)
            }
            else if (selectedMapLayer === MAP_LAYERS.HEAT_SUPPLY) {
                heatSources2020.addTo(map)
                newHeatSuppliesLayer.addTo(map)
            }
            else if (selectedMapLayer === MAP_LAYERS.ZONING_PLANS) {
                if (props.zoning.municipalities_loading) {
                    setMapLoadingMsgs(["De warmtezoneringsplannen worden geladen....."])
                    setMapLoading(true)
                }
                else {
                    if (zoningPlansMap && !props.zoning.municipalities_loading) {
                        if(map.hasLayer(zoningPlansMap))
                            map.removeLayer(zoningPlansMap)
                        zoningPlansMap.addTo(map)
                    }
                    else
                        await handleZoningPlanLoading()
                }
                zoningGeoDataMap.addTo(map)
            }
        }
    }
    return (
        <>
            {mapLoading &&
                <ReactLoader
                    loaderType="ball-clip-rotate-multiple"
                    sentences={mapLoadingMsgs}
                    width="100%"
                    height="100%"
                />
            }
            <Prompt
                when={[SIMULATION_STATUS.UPDATED, SIMULATION_STATUS.CALCULATED].includes(props.map.simulationStatus)}
                message={(location)=> {return location.pathname === ROUTES.MAP_PAGE ? true : "Ben je zeker dat je de pagina wil verlaten? Alle aanpassingen worden verwijderd!"}}
            />
            <SimulationController
                map={map}
                activeCollectiveLayers={layersToShowInCollective}
                openNameForm={simulationNameFormOpen}
                closeNameFormHandler={()=> setSimulationNameFormOpen(false)}
                clusterMapLayer={clusterStreetsLayer}
                simulationCollectiveLayer={streetsHeatCollectiveFromDecisionAlgo}
                submitSimulationName={(simName, nameUpdated)=> updateSimulationHandler(true, simName, nameUpdated)}
                openSimulationDiscardForm={simulationDiscardFormOpen}
                showPopupMessageOnMap={showPopupOnMap}
            />

            <div className={`map-page-container ${mapLoading ? "hidden" : ""}cyan-bg`}>
                <div className={`${mapContainerClasses.join(" ")}`}>
                    <div id="map" className={`inner-map `}/>
                </div>
                {props.economicViability.selected_state === ECONOMIC_VIABILITY_STATES.CALCULATED &&
                    <div className={smallScreen ? 'ev-card-position' : 'float-right w-55percent'}>
                        <MainCard
                            clusterLayer={clusterStreetsLayer}
                        />
                    </div>
                }
                {/*<div className="content-wrapper">*/}
                {/*    <div className="content-wrapper-inside">*/}
                        <div className="content-above">
                            <div className="map-controls-container">
                                <FillButton
                                    tooltipTitle={toggleMapServiceTooltipText}
                                    extra_classes={"toggle-service-button"}
                                    onClickHandler={handleMapServiceToggle}
                                    name={parse(toggleMapServiceText)}
                                />
                                <div className="question-mark-map">
                                    <button className="p-0 border-0 bg-transparent">
                                        <img
                                            src={question}
                                            alt="img"
                                            width="40px"
                                            height="40px"
                                            onClick={() => infoCardToggler()}
                                        />
                                    </button>
                                </div>
                                {/* {!props.economicViability.show_cards &&           */}

                                {!mapLock &&

                                <SidePanel
                                    smallScreen={smallScreen}
                                    wmsInAction={wmsInAction}
                                    handleToggleMapService={handleMapServiceToggle}
                                    setSearchBtnClick={setSearchBtnClick}
                                    sidePanelContent={sidePanelContent}
                                    mapCenter={mapView}
                                    mapZoom={mapView.zoom}
                                    searchAreaUpdateHandler={() => handleWMSMapLoading(true)}
                                    handleNewHeatDemandAddition={(newDemandInfo) => addHeatDemandHandler(newDemandInfo)}
                                    handleCancelHeatDemandAddition={resetAdditionOnMap}
                                    handleCancelHeatSupplyAddition={resetAdditionOnMap}
                                    handleNewHeatSupplyAddition={(newSupplyInfo) => addHeatSupplyHandler(newSupplyInfo)}
                                    addHeatDemandLoadingState={addHeatDemandLoading}
                                    addHeatSupplyLoadingState={addHeatSupplyLoading}
                                    handleDownload={mapDownloadHandler}
                                    submitCalculateState={simulationCalculating}
                                    submitSimulationState={simulationUpdating}
                                    handleCalculateSimulation={() => updateSimulationHandler(false)}
                                    handleSubmitSimulation={() => {
                                        ![SIMULATION_STATUS.IDLE, SIMULATION_STATUS.SAVED].includes(props.map.simulationStatus) && setSimulationNameFormOpen(true)
                                    }}
                                />
                                }
                            </div>
                        </div>
                {/*    </div>*/}
                {/*</div>*/}
                <div
                    ref={mapCardRef}
                    className="content-below"
                >
                    <div
                        className="map-page-content"
                    >
                        {!mapLock && zoningLayerCardOpen ?
                            <ZoningLayerInfoCard
                                selectedMunicipality={selectedMunicipalityOnZoning}
                                mapZoom={mapView.zoom}
                            /> :
                            !mapLock && infoCardOpen ?
                                <div className='legend-div mb-3'>
                                    <AnalysisMapInfoCard
                                        bannerContent={bannerContent}
                                        className="info-card-map"
                                        width={100}
                                        height={50}
                                        onCloseClicked={() => setInfoCardOpen(false)}
                                    />
                                </div>
                                :
                                (!mapLock && simulationPageInfoCardOpen) &&
                                <SimulationMapPageCard
                                    smallScreen={smallScreen}
                                    content={reportContent}
                                />
                        }
                    </div>
                </div>

            </div>
            <AlertMessage
                show={showMapPagePopup}
                message={mapPagePopupMessage}
                type={mapPagePopupType}
            />
        </>
    )
}

const mapStateToProps = ({auth, map, main,loader,content, zoning, simulation, economicViability}) => ({
auth,
map,
main,
loader,
content, 
zoning, 
simulation,
economicViability
})
export default connect(mapStateToProps, {
getContentByModuleLocation,
updateSimDetailsInStore,
removeHeatDemand,
cleanUpSimulation,
addMunicipalitiesInStore,
getAllMunicipalitiesWithZoningPdfs,
updateSimulationCase,
removeHeatSupply
}) ( withRouter(LeafletMap) );