import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Select, MenuItem, Button} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker , MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { getAllMunicipalities, uploadDocuments } from '../../redux/actions/zoning';
import {activeView} from '../../redux/actions/auth';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import AlertMessage from "../../helper/otherMessages";
import {FillButton} from "../../helper/buttons";
import {getContentByModuleLocation} from '../../redux/actions/content'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import downloadIcon from '../../assets/download.svg';


function ZoningPlan(props) {

const [selectedMunicipality, setSelectMunicipality] = useState();
const [selectedDate, setSelectedDate] = useState();
const [momentDate, setMomentDate] = useState();
const [authorName, setAuthorName] = useState();
const [openPDF, setOpenPDF] = useState(false);
const [openExcel, setOpenExcel] = useState(false);
const [pdfFile, setPDFFile] = useState(false);
const [excelFile, setExcelFile] = useState(false);
const [allMunicipalities, setAllMunicipalities] = useState([]);
const [showPagePopup, setShowPagePopup] = useState(false);
const [pagePopupType, setPagePopupType] = useState("success");
const [pagePopupMessage, setPagePopupMessage] = useState("");
const [addingZoningPlan, setAddingZoningPlan] = useState(false);

const [PDFName, setPDFName] = useState();
const [excelName, setExcelName] = useState();
const [municipalityError, setMunicipalityError] = useState(false);
const [dateError, setDateError] = useState(false);
const [fileError, setFileError] = useState(false);


const [content, setContent] = useState(undefined);

useEffect(() => {
    props.getAllMunicipalities()
    props.activeView(6)

    async function getContent(){
        const data = await props.getContentByModuleLocation('zoningPage'); 
        setContent(data?.zoning?.attributes)
      }
  
      getContent();
  
},[]);


useEffect(() => {
    setAllMunicipalities(props.zoning.all_municipalities)
}, [props.zoning.all_municipalities])

const handleDateChange = (date) => {
    let momentDate = moment(date).format("MM/YYYY");
    setSelectedDate(date);
    setMomentDate(momentDate)
  }

const handleTextFields = (event) => {
let value = event.target.value;
setAuthorName(value)
};

const handleOpenPDF = () => {
    if(pdfFile) {
        setPDFFile(false)
    }
    else setOpenPDF(true)
  };
  const handleOpenExcel = () => {
    if(excelFile) {
        setExcelFile(false)
    }
    else setOpenExcel(true)
  };

const handleClose = () => {
    setOpenPDF(false)
    setOpenExcel(false)
};

const handlePDF = (event) => {
    const files = event.target.files;
    let names = '';

    Object.keys(files).map(key => {
        names = `${files[key].name}, ${names}`
    });

    setPDFFile(files)
    setPDFName(names)
    handleClose()
}

const handleExcel = (event) => {
    let files = event.target.files;
    let names = '';

    Object.keys(files).map(key => {
        names = `${files[key].name}, ${names}`
    });

    setExcelFile(files);
    setExcelName(names)
    handleClose()
}

const formValidation = () => {
    console.log('muniii',selectedMunicipality)
    if(!selectedMunicipality) setMunicipalityError(true)
    else setMunicipalityError(false)
    if(!momentDate) setDateError(true)
    else setDateError(false)
    if(pdfFile || excelFile)  setFileError(false)
    else {
        setFileError(true)
        setTimeout(() => {
            setFileError(false) 
        }, 2000);
    }
    if(selectedMunicipality && momentDate && pdfFile || excelFile ) handleSubmit()
    else console.log('submitError')

}

const handleSubmit = () => {
    let data = {
        author: authorName?authorName:'',
        mun_name: selectedMunicipality?selectedMunicipality:'',
        date_created: momentDate?momentDate:'',
        pdf_file: pdfFile?pdfFile:null,
        excel_file: excelFile?excelFile:null,
    }

 ;(async ()=> {
     setAddingZoningPlan(true)
     const uploadZoningPlanRes = await props.uploadDocuments(data)
        if (uploadZoningPlanRes.errors && uploadZoningPlanRes.errors.length)
            showPopupOnPage(uploadZoningPlanRes.errors[0], "error")
        else
            showPopupOnPage(uploadZoningPlanRes.message || "Bestemmingsplan succesvol toegevoegd", "success")
        setAddingZoningPlan(false)
    })()
}
    const showPopupOnPage = (text, type = "info") => {
        setPagePopupMessage(text)
        setPagePopupType(type)
        setShowPagePopup(true)
        setTimeout(() => {
            setShowPagePopup(false)
        }, 2500)
    }

return (
    <React.Fragment>
        <AlertMessage
            show={showPagePopup}
            message={pagePopupMessage}
            type={pagePopupType}
        />
        <Grid 
         container 
         xs={12} sm={12} md={12} lg={12} 
         className='pt-5 zoning-fields' 
         justifyContent='space-around'
        >
            {/* <Grid item md={1} lg={2} /> */}
            <Grid item xs={12} sm={6} md={2} lg={2} className='zoning-select px-2'>
                <Autocomplete
                    required
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    options={allMunicipalities}
                    onChange={(event, newValue) => {
                        setSelectMunicipality(newValue);
                    }}
                    value={selectedMunicipality}
                    renderInput={(params) => (
                    <TextField 
                      required={true}
                      {...params} 
                      variant="standard" 
                      error={municipalityError}
                      placeholder={`${content?.selectMunicipilaty}*`}
                    
                    />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} className='zoning-dGap px-2' >
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                >
                    <KeyboardDatePicker
                        views={['year', 'month']}
                        margin="normal"
                        id="date-picker-dialog"
                        label={`${content?.formattingDate} *`}
                        format="MM/yyyy"
                        value={selectedDate || ""}
                        defaultValue={' '}
                        onChange={handleDateChange}
                        disableFuture={true}
                        KeyboardButtonProps={{
                        "aria-label": "change date",
                        }}
                        InputProps={{ readOnly: true }}
                        inputVariant="standard"
                        inpu
                        error={dateError}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} className='pt-2 px-2'>
                <TextField
                    placeholder={content?.authorOftheStudy}
                    name='author'
                    defaultValue=""
                    type='text'
                    onChange={handleTextFields}
                    value={authorName}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={10}>
                <div className='zoning-upload-msg'>
                    { pdfFile &&
                    <span className='mr-5 zoning-msg-text'>
                        Geüpload bestand 
                        <span className='zoning-msg-text2'> {PDFName}</span>
                    </span>}
                    { excelFile &&
                    <span className='zoning-msg-text'>
                        Geüpload bestand 
                        <span className='zoning-msg-text2'> {excelName}</span>
                    </span>}
                </div>
            </Grid>

            <Grid item xs={10}>
                <div className='zoning-upload-div'>
                    <button 
                     className={pdfFile?'zoning-remove-btn mr-5':'zoning-upload-btns mr-5'}
                     onClick={handleOpenPDF}
                    >
                        {pdfFile?'Remove my document':content?.uploadPDFFile}
                    </button>
                    <button 
                     className={excelFile?'zoning-remove-btn':'zoning-upload-btns'}
                     onClick={handleOpenExcel}
                    >
                       {excelFile?'Remove my document':content?.uploadExcelFile}
                    </button>
                </div>
            </Grid>

            <Grid item xs={10}>
                <Modal open={openPDF} onClose={handleClose} center>
                    <div className='pt-5 text-center p-5'>
                        <h3>{content?.uploadPDFFile}</h3>
                        <div className='text-center pt-5 '>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            {content?.uploadMyFile}
                            <input
                                type="file" 
                                accept="application/pdf" 
                                onChange={handlePDF}
                                name="files[]"
                                multiple
                                hidden
                            />
                        </Button>
                        </div>
                    </div>
                </Modal>
                <Modal open={openExcel} onClose={handleClose} center>
                    <div className='pt-5 text-center pb-3'>
                        <h3>{content?.uploadExcelFile}</h3>
                        <span> 
                            {content?.availableSoon}
                        </span>
                        <div className='text-center pt-5'>
                            <Button
                                variant="contained"
                                component="label"
                            >
                                {content?.uploadMyFile}  
                                <input 
                                type="file" 
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                                onChange={handleExcel}
                                name="files[]"
                                multiple
                                hidden
                                />
                             </Button>
                        </div>
                        <br/>
                    </div>
                    <h5>
                        Sample file Format
                        <span>
                            <a
                                className="pointer"
                                href={'https://vvsg-ww.s3.fr-par.scw.cloud/testexcel_1664981864143.xlsx'}>
                                <img
                                    height={'30px'}
                                    width={'25px'}
                                    src={downloadIcon}
                                    alt={'Download'}
                                />
                            </a>
                        </span>
                    </h5>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead className='grey-table-bg'>
                                <TableRow>
                                    <TableCell>
                                        geometry (GeoJSON)
                                    </TableCell>
                                    <TableCell>
                                        score (1-5)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key={Math.random()}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell align="center">{'{"type":"LineString","crs":{"type":"name","properties":{"name":"EPSG:31370"}},"coordinates":[[195502.182586861,200168.194189384],[195510.691723022,200173.447245809],[195516.759530716,200177.193164971],[195519.184562407,200178.726386647],[195521.068522714,200179.917516969],[195527.308394723,200183.862988975],[195527.636742283,200184.070603644],[195536.088872747,200189.414898792],[195544.541003211,200194.759193939],[195552.993133675,200200.103489086],[195557.962730743,200203.245772988],[195561.43019587,200205.471436478],[195569.845755549,200210.873135774],[195578.261315228,200216.274835071],[195586.676874907,200221.676534367],[195595.092434586,200227.078233663],[195603.507994266,200232.479932959],[195605.777386777,200233.93658901],[195612.017061114,200237.732007462]]}'}</TableCell>
                                    <TableCell align="center">3</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Modal>
            </Grid>
            <Grid item xs={10}>
                <div className='text-center'>
                    <FillButton
                        name="Upload mijn document(en) ter validatie"
                        classes='zoning-submit'
                        onClickHandler={formValidation}
                        loading={addingZoningPlan}
                    />
                </div>
            </Grid>
            {
            fileError &&
            <Grid item xs={12} sm={12} md={12} lg={12} className='center-fixed'>
                <Alert severity="error">
                    {content?.uploadExcelOrPDFFile}
                </Alert>
            </Grid>
            }
            {/*<ErrorMessage error={props.loader.showErrorMsg} msg={props.loader.errorMsg} />*/}
        </Grid> 
    </React.Fragment>
)
}

const mapStateToProps = ({auth,content,loader,zoning}) => ({auth,content,loader,zoning})
export default connect(mapStateToProps,{ getAllMunicipalities, uploadDocuments, activeView, getContentByModuleLocation }) ( ZoningPlan );
