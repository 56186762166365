import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { 
Grid,
Container, 
Accordion, 
AccordionSummary, 
AccordionDetails, 
makeStyles, 
Typography, 
} 
from '@material-ui/core';
import parse from 'html-react-parser';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function Faq({faqContent,title}) {

  const [faqLimit, setFaqLimit] = useState(5)

  useEffect(() => {
    console.log('limit',faqLimit)
  }, [faqLimit])

  const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        float:'right',
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        color:'#525252'
      },
    }));
  const classes = useStyles();

  if (faqContent){
    let questions = JSON.parse(`${faqContent}`)
    let items = [];

    if(questions) {
      let output = questions.length/5;
      let result = Math.ceil(output) + 1;
      for( let i = 1; i < result; i++ ) {
        items.push(i*5)
      }
    }
    
    return (
      <>
      <Grid container className='mt-5 pb-5' id="faq" >
        <Container className='pb-5'>
            <Grid container >
                <Grid item xs={12} sm={12} md={4} lg={4} >
                <h5 className='faq-heading'>{title}</h5>
                </Grid>
                <Grid item sm={12} md={7} lg={7} >
                    <div className={classes.root} >{
                      questions.slice(0,faqLimit).map((obj,index)=>(
                        <Accordion className='mt-2' key={index} >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{color:'#E03C31'}} />}
                            aria-controls="panel1a-content"
                            id={`panel1a-header-${index}`}
                          >
                          <Typography className={classes.heading}>{parse(`${obj[`question${index+1}`]}`)}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                          <Typography>{parse(`${obj[`answer${index+1}`]}`)}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))} 
                    </div>
                    <Grid 
                     container 
                     item xs={12} sm={12} md={12} lg={12} 
                     justifyContent='flex-end'
                     className='pt-3'
                    >
                      <select
                      className="form-select"
                      aria-label="Default select example"
                      onClick={(e)=>setFaqLimit(e.target.value)}
                      >
                        {items.map((obj, index)=>(
                          <option key={`${obj}_${index}`} value={obj}>Toon {obj}</option>
                        ))}
                        
                      </select>
                    </Grid>
                </Grid>
                <Grid item sm={1} md={1} lg={1} />
            </Grid>
        </Container>
      </Grid>
      </>
    )
  }
  return <></>
}

const mapStateToProps = ({content,loader}) => ({content,loader})
export default connect(mapStateToProps,null) ( Faq );
