import {Grid} from "@material-ui/core";
import {HEAT_DEMAND_COLORS} from "../../constants/map";


export const GradientBar = ({
    radius,
    // leftColor=HEAT_DEMAND_COLORS.left,
    // rightColor=HEAT_DEMAND_COLORS.right,
    leftColor,
    rightColor,
    height=25,
    labels=["Laag", "Hoog"]
                            })=> {
    return (
        <>
            <div
                style={{
                    backgroundImage: `linear-gradient(to right, ${leftColor}, ${rightColor})`,
                    height: `${height}px`,
                    width: "100%",
                    borderRadius:radius?radius+'px':''
                }}
            />
            <Grid
              className=""
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
                {
                    labels.map((label, index)=> {
                        return (
                            <span key={index} className="small text-grey">{label}</span>
                        )
                    })
                }
            </Grid>
        </>
    )
}