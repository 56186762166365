export const ROUTES = {
    HOME_PAGE: "/",
    MAP_PAGE: "/map",
    LOGIN_PAGE: "/login",
    REGISTRATION: "/registration",
    CHANGE_PASS: "/change-password",
    FORGOT_PASS: "/forgot-password",
    FORGOT_PASS_CHANGE: "/forgot-password-change",
    SIMULATION_PAGE: "/simulations",
    ZONING_PLAN: "/zoning-plan",
    INFO_PAGE: "/info-page",
    AGIFLY:"https://agifly.be/",
    ABOUT_PAGE:"https://www.vvsg.be/privacystatement/over-ons",
    CONTACT_PAGE:"https://www.vvsg.be/Contacteer-ons",
}