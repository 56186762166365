import { Grid } from '@material-ui/core';


export const MultiColorBar = ({
    name,
    colors=[{
        hex: "#000000",
        percentage: 100,
        label:colors.label
    }],
    labels=["low", "high"]
                              })=> {
    return (
        <div>
            <div className="rounded-corners d-inline-flex w-full">
                {
                    colors.map((color, index)=> {
                        return (
                            <div
                                className={index===0 ? "rounded-corners-left" : index===colors.length-1 ? "rounded-corners-right" : ""}
                                key={index}
                                style={{
                                    height: "20px",
                                    width: `${color.percentage}%`,
                                    background: color.hex
                                }}
                            >
                            {name=='renovation' && color.label && color.active?
                            <div className='sidepanel-multi-label'>
                              <span className='sidepanel-multi-label-span'/>
                              {/* <span className='sidepanel-multi-label-span' style={{border:`1px solid ${color.hex}`}}>{color.label}</span> */}
                            </div>
                            :''  }
                              
                            </div>
                        )
                    })
                }
            </div>
            <br/>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
                {
                    labels.map((label, index)=> {
                        return (
                            <span key={index} className="small text-grey">{label}</span>
                        )
                    })
                }
            </Grid>
        </div>
    )
}