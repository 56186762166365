import React, {useState, useEffect} from "react"
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Row, Col, CardBody, Card, Container} from "reactstrap";
import {useHistory} from "react-router-dom"
import '../../scss/warmte/login.scss';
import {AvForm, AvField} from "availity-reactstrap-validation";
import profile from "../../assets/profile-img.png";
import {userRegistration, checEmail} from '../../redux/actions/auth';
import {showErrorMsg, hideMessage, showSuccessMsg} from '../../redux/actions/loader';
import Validation_Icon from '../../icons/bootstrap/validation';
import SuccessMessage from '../../helper/successMessage';
import {activeView} from '../../redux/actions/auth';
import ErrorMessage from '../../helper/errorMessage';
import {getContentByModuleLocation} from '../../redux/actions/content';
import {getAuthenticationErrors} from "../../helper/requestHelpers";


const Registration = (props) => {

    const [isEmailNotValid, setIsEmailNotValid] = useState(false);
    const [isPasswordNotValid, setIsPasswordNotValid] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [show, setShow] = useState(false);
    const [updates, setUpdates] = useState(false);
    const [passwordValidateText, setPasswordValidateText] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    const [content, setContent] = useState(undefined);

    // AFTER API RESPONSE
    const [errorMsg, setErrorMsg] = useState();
    const [emailExists, setEmailExists] = useState(false);


    useEffect(() => {
        async function getContent() {
            const data = await props.getContentByModuleLocation('registerPage');
            setContent(data?.register?.attributes)
        }

        getContent();

        return () => {
            props.showErrorMsg('')
        }
    }, [])


    // useEffect(() => {
    //     setErrorMsg(props.loader.errorMsg)
    //     if (props.loader.errorMsg.includes('email')) {
    //         setEmailExists(true)
    //     } else {
    //         setEmailExists(false)
    //         setErrorMsg()
    //     }
    //
    // }, [props.loader.errorMsg])


    const handleRegistration = ({target}) => {
        let {value, name} = target;
        if (name == 'email') setEmail(value)
        else if (name == 'password') setPassword(value)
        else if (name == 'updates') setUpdates(!updates)
    }

    const handleSubmit = async (event, errors, values) => {
        setIsPasswordNotValid(false)
        setIsEmailNotValid(false)
        setErrorMsg('')
        const emailExist = (await props.checEmail(email)).message.includes('Email Exists');

        if (emailExist) {
            setEmailExists(true)
            setErrorMsg(content?.alreadyRegisteredError)
            return
        }
        setEmailExists(false)
        // setShowSuccess(true)

        try {
            const registrationResult = await props.userRegistration(email, password)

            setTimeout(function () {
                if (registrationResult && registrationResult.status === 201) {
                    if (registrationResult.data.result.data.domainWhiteListed) {
                        setSuccessMessage(content?.successMessageWithWhitelistedDomain)
                    } else {
                        setSuccessMessage(content?.successMessage)
                    }
                    setShowSuccess(true)
                    setTimeout(() => {
                        setShowSuccess(false)
                        props.hideMessage()
                    }, 4000)
                    // setShowSuccess(false)
                    // setErrorMsg('');
                    setEmail('');
                    setPassword('');
                } else {
                    takeActionsOnErrors(registrationResult.data.errors)
                }
            }, 200);
        }
        catch (err) {
            const errorResponse = err.response
            if (errorResponse) {
                takeActionsOnErrors(errorResponse.data.errors)
            }
            console.log("err is ", err.response)
        }
    }

    const takeActionsOnErrors = (errors)=> {
        const {emailErrors, passwordErrors} = getAuthenticationErrors(errors)
        if (emailErrors.length) setIsEmailNotValid(true)
        if (passwordErrors.length) {
            setIsPasswordNotValid(true)
            setPasswordValidateText(content?.errorPasswordText)
        }
    }
    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2"/>
                </Link>
            </div>
            <div className="login-height my-5 pt-sm-5 bggg-bg">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="login-bg bg-soft">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="login-text p-4">
                                                <h5 className="login-text">{content?.registerTitle}</h5>
                                                {/* <p>Sign in to continue to Skote.</p> */}
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid"/>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="auth-logo">
                                    </div>
                                    <div className="p-2 pt-4">
                                        <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                                            <label
                                                style={{color: isEmailNotValid || emailExists ? '#dc3545' : '#212529'}}
                                            >
                                                E-mail
                                            </label>
                                            <div className="mb-3 position-relative">
                                                <input
                                                    name="email"
                                                    label="E-mail"
                                                    value={email}
                                                    onChange={handleRegistration}
                                                    className="form-control shadow-none"
                                                    placeholder={`E-mail`}
                                                    style={{borderColor: isEmailNotValid || emailExists ? '#dc3545' : 'rgb(206, 212, 218)'}}
                                                    type="email"
                                                    required
                                                />
                                                {isEmailNotValid && !emailExists &&
                                                <div>
                                                    <Validation_Icon/>
                                                    <div className="invalid-field-label">U hebt een ongeldige waarde
                                                        ingevoerd voor dit veld
                                                    </div>
                                                </div>
                                                }
                                                {emailExists &&
                                                <div>
                                                    <Validation_Icon/>
                                                    <div className="invalid-field-label">{errorMsg}</div>
                                                </div>
                                                }
                                            </div>
                                            <div className="mb-3 position-relative">
                                                <label
                                                    style={{color: isPasswordNotValid ? '#dc3545' : '#212529'}}
                                                >
                                                    Wachtwoord
                                                </label>
                                                <input
                                                    name="password"
                                                    label="Wachtwoord"
                                                    value={password}
                                                    className='form-control shadow-none'
                                                    onChange={handleRegistration}
                                                    type={show ? 'text' : 'password'}
                                                    style={{borderColor: isPasswordNotValid ? '#dc3545' : 'rgb(206, 212, 218)'}}
                                                    required
                                                    placeholder="Wachtwoord"
                                                />
                                                <i
                                                    className={`far ${show ? 'fa-eye-slash' : 'fa-eye'} icon-auth password-icon-register`}
                                                    onClick={() => setShow(!show)}
                                                    style={{color: isPasswordNotValid ? "#dc3545" : "#43B02A"}}
                                                />

                                                {isPasswordNotValid &&
                                                <div>
                                                    {/* <Validation_Icon /> */}
                                                    <div className="invalid-field-label">
                                                        <span>{content?.invalidValueText}</span>
                                                        <div className='pt-2'>
                                                            {passwordValidateText}
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            <div className="mt-3 d-grid">
                                                <button
                                                    className="btn btn-block login-btn shadow-none"
                                                    type="submit"
                                                >
                                                    {content?.registerButtonText} {props.loader.loader ?
                                                    <i className="fa fa-spinner fa-spin"></i> : ''}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {showSuccess &&
                    <SuccessMessage success={true} msg={successMessage}/>}
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({auth, loader}) => ({auth, loader})
export default connect(mapStateToProps, {
    userRegistration,
    activeView,
    checEmail,
    hideMessage,
    showSuccessMsg,
    showErrorMsg,
    getContentByModuleLocation
})(Registration);
