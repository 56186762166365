import {
    SELECTED_AREA,
    SEARCH_LEVEL,
    SELECTED_MAP_LAYER,
    SIMULATION_PARAMS,
    SIMULATION_HEAT_DEMAND,
    SIMULATION_STATUS_TOGGLE,
    SELECTED_SIMULATION_DETAILS,
    SIMULATION_CASE,
    SIMULATION_HEAT_SUPPLY,
    SELECTED_CLUSTER,
    SELECTED_CLUSTER_TYPE, SELECTED_CLUSTER_GEOJSON
} from '../constants/constants';
import {
    DEFAULT_SELECTED_AREA,
    DEFAULT_SELECTED_SIMULATION,
    DEFAULT_SIMULATION_STATE,
    MAP_LAYERS,
    SIMULATION_STATUS
} from "../../constants/map";


const INIT_STATE = {
    selectedArea: {
        ...DEFAULT_SELECTED_AREA
    },
    searchLevel: "complete",
    selectedMapLayer: MAP_LAYERS.COLLECTIVE_HEAT_MAPPING,
    simulationParams: DEFAULT_SIMULATION_STATE,
    simulationStatus: SIMULATION_STATUS.IDLE,
    simulationHeatDemands: [],
    simulationHeatSupplies: [],
    selectedSimulationDetails: DEFAULT_SELECTED_SIMULATION,
    selectedCluster: null,
    selectedClusterInGeoJSON: null,
    selectedClusterType: null,
    simulationCase: 5
};

const map = (state = INIT_STATE, action) => {
    switch(action.type) {
        case SELECTED_AREA:
            return {
                ...state,
                selectedArea: action.payload
            }
        case SEARCH_LEVEL:
            return {
                ...state,
                searchLevel: action.payload
            }
        case SELECTED_MAP_LAYER:
            return {
                ...state,
                selectedMapLayer: action.payload
            }
        case SIMULATION_PARAMS:
            return {
                ...state,
                simulationParams: {...state.simulationParams, ...action.payload}
            }
        case SIMULATION_STATUS_TOGGLE:
            return {
                ...state,
                simulationStatus: action.payload
            }
        case SIMULATION_HEAT_DEMAND:
            return {
                ...state,
                simulationHeatDemands: [...action.payload]
            }
        case SELECTED_SIMULATION_DETAILS:
            return {
                ...state,
                selectedSimulationDetails: {...state.selectedSimulationDetails, ...action.payload}
            }
        case SIMULATION_CASE:
            return {
                ...state,
                simulationCase: action.payload
            }
        case SIMULATION_HEAT_SUPPLY:
            return {
                ...state,
                simulationHeatSupplies: [...action.payload]
            }
        case SELECTED_CLUSTER:
            return {
                ...state,
                selectedCluster: action.payload
            }
        case SELECTED_CLUSTER_GEOJSON:
            return {
                ...state,
                selectedClusterInGeoJSON: action.payload
            }
        case SELECTED_CLUSTER_TYPE:
            return {
                ...state,
                selectedClusterType: action.payload
            }
        default:
                return state;
    }
}

export default map;

