import React from 'react'
import { Alert } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
export default function SuccessMessage(props) {
    return (
        <>
            {props.success? (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="center-fixed"
            >
                <Alert severity="success">
                    {props.msg}
                </Alert>
            </Grid>
            ) : (
                ""
            )}
        </>
    )
}