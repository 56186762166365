import axios from '../axios/axios';
import { createBrowserHistory } from 'history';
import {
    GEOSERVER_STORE,
    municipalitiesWithZoningFiles,
    municipalityZoningInfo,
    uploadZoningPlan,
    zoningMunicipalities
} from '../constants/ApiEndPoints';
import {
    ZONING_MUNICIPALITY,
    ZONING_MUNICIPALITY_GEOMETRY,
    ZONING_MUNICIPALITY_GEOMETRY_LOADING
} from '../constants/constants';
import simulationService from "../../services/simulationService";
import mapApiService from "../../services/mapApiService";
import {MAP_LAYERS} from "../../constants/map";
const customHistory = createBrowserHistory();

export const getAllMunicipalities = () => {
  return async dispatch => {
    const { data } = await axios.get(zoningMunicipalities);
    dispatch({ type:ZONING_MUNICIPALITY, payload:data.result.data })
    return data.result.data;
  }
}

export const uploadDocuments = (data) => {
    let formData = new FormData();
    const {pdf_file,excel_file,  ...data1} = data;
    Object.entries(data1).map(([key,value])=> formData.append(`${key}`,value));

    if (pdf_file)
        Object.values(pdf_file).forEach((each, index) => {formData.append(`pdf_file`,each)})
    else
        formData.append(`pdf_file`,null)
    if (excel_file)
        Object.values(excel_file).forEach((each, index) => {formData.append(`excel_file`,each)})
    else
        formData.append(`excel_file`,null)

    return async dispatch => {
        try {
            const {data} = await axios.post(uploadZoningPlan, formData);
            return data;
        }
        catch (err) {
            let errors = []
            if (err.response && err.response.data.errors)
                errors = [...err.response.data.errors]
            else
                errors = [err.toString() || "Unexpected Error"]
            return {data: {}, errors: errors}
        }
    }
}

export const addMunicipalitiesInStore = ()=> {
    return async dispatch=> {
        dispatch({
            type: ZONING_MUNICIPALITY_GEOMETRY_LOADING,
            payload: true
        })
        try {
            const municipalitiesGeom = await mapApiService.getRequestedMapArea(null,
                `${GEOSERVER_STORE}:Municipality`, "geom")
            dispatch({
                type: ZONING_MUNICIPALITY_GEOMETRY,
                payload: municipalitiesGeom?.data
            })
            dispatch({
                type: ZONING_MUNICIPALITY_GEOMETRY_LOADING,
                payload: false
            })
        }
        catch (err){
            return err
        }
    }
}

export const getAllMunicipalitiesWithZoningPdfs = ()=> {
    return async dispatch=> {
        try {
            const {data} = await axios.get(municipalitiesWithZoningFiles);
            return data;
        } catch (err) {
            return {data: {}, errors: err.response && err.response.data.errors ? [...err.response && err.response.data.errors] : [err.toString()]}
        }

    }
}

export const getMunicipalityZoningInfo = (munName)=> {
    return async dispatch => {
        try {
            const {data} = await axios.post(municipalityZoningInfo, {mun_name: munName});
            return data;
        } catch (err) {
            return {data: {}, errors: err.response && err.response.data.errors ? [...err.response && err.response.data.errors] : [err.toString()]}
        }
    }
}
