import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { ROUTES } from "../../constants/routes";
import { HashLink } from 'react-router-hash-link';
import { activeView } from '../../redux/actions/auth';
import { getContentByModuleLocation } from '../../redux/actions/content'

function Footer(props) {
    const [footerContent, setFooterContent] = React.useState(undefined);

    React.useEffect(() => {
        //Load data in local state
        async function fetchContent() {
            const data = await props.getContentByModuleLocation('bottom');
            setFooterContent(data?.footer?.attributes || undefined);
        }

      fetchContent();
    }, [])

    return (
        <>
            <Grid container className="footer-container footer">
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='pl-3 footer-company-div'>
                            <span className='fs-14'>{footerContent?.agiflySign} 
                                <a href={ROUTES.AGIFLY} target='_blank' className='footer-company'> Agifly</a>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                            <footer className="">
                                <div className="d-flex justify-content-end">
                                    <ul className="list-inline">
                                        <li className="list-inline-item d-inline">
                                            <a href={ROUTES.ABOUT_PAGE} target="_blank">
                                                {footerContent?.aboutUs}
                                            </a>
                                        </li>
                                        <span className='footer-vl pr-2'></span>
                                        <li className="list-inline-item d-inline">
                                            <HashLink to="/#faq">{footerContent?.faq}</HashLink>
                                        </li>
                                        <span className='footer-vl pr-2'></span>
                                        <li className="list-inline-item d-inline"><a
                                            href={ROUTES.CONTACT_PAGE} target="_blank">{footerContent?.contact}</a></li>
                                    </ul>
                                    <span className="footer-copyright pr-3">{footerContent?.name} © {new Date().getFullYear()}</span>
                                </div>
                                <span className="footer-copyright footer-copyright-responsive">{footerContent?.name} © {new Date().getFullYear()}</span>
                            </footer>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = ({content, loader}) => ({content, loader})
export default connect(mapStateToProps, { activeView, getContentByModuleLocation })(Footer);