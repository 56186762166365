import React,{useEffect} from 'react'
import { Alert } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
export default function ErrorMessage(props) {
    useEffect(() => {
        console.log('errorAlert')
    }, [props.loader])
    return (
        <>
            {props.error? (
            <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                justifyContent='center'
            >
                <Alert severity="error">
                    {props.msg?props.msg:"The email or password you entered is incorrect!"}
                </Alert>
            </Grid>
            ) : (
                ""
            )}
        </>
    )
}