import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InfoCard } from "../../sharedComponents/infoCard";
import { withRouter } from "react-router-dom";
import { getMunicipalityZoningInfo } from "../../../redux/actions/zoning";
import Grid from "@material-ui/core/Grid";

const ZoningLayerInfoCard = (props) => {
    const [muniZoningInfo, setMuniZoningInfo] = useState({})

    useEffect(()=> {
        console.log("selected municipality is ", props.selectedMunicipality)
        if (props.selectedMunicipality) {
            ;(async () => {
                const zoningInfoRes = await props.getMunicipalityZoningInfo(props.selectedMunicipality)
                if (zoningInfoRes.result)
                    setMuniZoningInfo(zoningInfoRes.result.data)
                else
                    setMuniZoningInfo(null)
            })()
        }
    }, [props.selectedMunicipality])

    return (
        <div
            className={`simulation-card bg-white m-4 zoning-card`}
        >
            <div className="d-flex justify-content-center align-items-center simulation-card-header pt-1">
                <label> Warmtezoneringsplan</label>
            </div>
            { props.selectedMunicipality ? <>
                {muniZoningInfo && (muniZoningInfo?.has_pdf_data || muniZoningInfo.has_geo_data) ?
                    <>
                        {
                            muniZoningInfo?.has_pdf_data &&
                            <div className="px-4 pt-4">
                                <h6 className="primary-clr bold">
                                    Warmtezoneringsplan Informatie
                                </h6>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                        <InfoCard
                                            classes={`card-title ${muniZoningInfo.pdf_files.length > 1 ? 'list-card' : ''}`}
                                            cardSize="none" title="Gemeente"
                                            innerText={muniZoningInfo.mun_name}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InfoCard
                                            classes={`text-green card-title ${muniZoningInfo.pdf_files.length > 1 ? 'list-card' : ''}`}
                                            cardSize="none" title="Warmtezoneringsplan"
                                            background="green"
                                            innerText={muniZoningInfo.pdf_files.map((file)=> {
                                                if (file.file_url.includes('https'))
                                                    return file
                                                else
                                                    return {...file, file_url: ['https', file.file_url.split('http')[1]].join('')}
                                            })}
                                            innerTextClasses="small"
                                        />
                                    </Grid>

                                </Grid>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <InfoCard
                                            classes={`card-title ${muniZoningInfo.pdf_files.length > 1 ? 'list-card' : ''}`}
                                            cardSize="none" title="Auteur"
                                            innerText={muniZoningInfo.author}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <InfoCard
                                            classes={`card-title ${muniZoningInfo.pdf_files.length > 1 ? 'list-card' : ''}`}
                                            cardSize="none" title="Creatiedatum"
                                            innerTextClasses="small"
                                            innerText={`${muniZoningInfo.date_created.month}/${muniZoningInfo.date_created.year}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <InfoCard
                                            classes={`card-title ${muniZoningInfo.pdf_files.length > 1 ? 'list-card' : ''}`}
                                            cardSize="none" title="Datum van upload"
                                            innerText={muniZoningInfo.created_at.map(item=> {return {date: item}})}
                                            innerTextListItemIdentifier="date"
                                            innerTextClasses="small"
                                        />
                                    </Grid>
                                </Grid>
                                <br/>
                                <div className="mt-4 d-inline-flex">
                                </div>
                            </div>
                        }
                        {muniZoningInfo?.has_geo_data &&
                        <div className="bg-orange rounded d-flex justify-content-center align-content-center">
                            <span className="text-primary text-small">
                                Er zijn geogegevens opgeladen voor deze gemeente, beschikbaar vanaf zoomniveau 10.
                            </span>
                        </div>}
                        </>
                        :
                    <p className="text-small m-2">
                        De geselecteerde gemeente heeft geen opgeladen warmtezoneringsplan
                    </p>}
                </>
                :
                <p className="text-small m-2">
                    Er is op dit moment geen gemeente geselecteerd
                </p>}

        </div>
    )
}
const mapStateToProps = ({zoning}) => ({zoning})
export default connect(mapStateToProps, {getMunicipalityZoningInfo}) ( withRouter(ZoningLayerInfoCard) );