import React from 'react'
import { Grid  } from '@material-ui/core';
export default function CheckboxesList({ setLabel, label }) {
    return (
        <Grid container direction="row">
        <Grid item xs={12} sm={10} md={12} lg={12} xl={12} className='sidepanel-H-center'>
                            <ul className="sidepanel-slider">
                                <li className="sidepanel-li">
                                    <input  key={1} onClick={()=>setLabel('F')} checked={label === "F"}  defaultChecked={true} type="radio" id="f-option" />
                                    <label className='sidepanel-label'>Nu</label>
                                </li>
                                <li className="sidepanel-li">
                                    <input  key={2} onClick={()=>setLabel('D')} checked={label === "D"} type="radio" id="g-option" />
                                    <label className='sidepanel-label'>2030</label>
                                </li>
                                <li className="sidepanel-li">
                                    <input  key={3} onClick={()=>setLabel('B')} checked={label === "B"}  type="radio" id="k-option" />
                                    <label className='sidepanel-label'>2040</label>
                                </li>
                                <li className="sidepanel-li">
                                    <input  key={4} onClick={()=>setLabel('A')} checked={label === "A"}  type="radio" id="t-option" />
                                    <label className='sidepanel-label'>2050</label>
                                </li>
                            </ul>
                        {/*</div>*/}
                    </Grid>
        </Grid>
    )
}
